// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  InputGroup,
  Input,
  InputRightElement,
  Icon,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { userPublicRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight2";
import CenteredAuth from "layouts/auth/types/CenteredNew";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "components/footer/FooterAdmin.js";
import CustomNavLink from "langHoc/CustomNavLink";
import { ConfigContext } from "contexts/ConfigContext";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const lang = window.location.pathname.split("/")[1] || "en";
function PasswordVerificationCentered() {
  // Chakra color mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const history = useCustomHistory();
  let query = useQuery();
  const [resendLoading, setResendLoading] = useState(false);
  const { t } = useTranslation();

  let configs = useContext(ConfigContext);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const payload = {
        otp,
        password: formData.password,
        lang,
      };
      const result = await userPublicRequest.post(
        "/auth/reset-password/verify",
        payload
      );
      // // console.log()
      toast.success("Password updated successfully.", {
        onClose: () => {
          setLoading(false);
          history.push(`/auth/sign-in`);
        },
      });
    } catch (err) {
      // // console.log(err);
      setLoading(false);
      const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      // toast.error(err.response.data.message);
    }
  };
  const changeForm = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleClick = () => setShow(!show);
  // // console.log(otp);
  const vh = useViewportHeight();
  const breakpoint = useBreakpointValue({ base: "base", lg: "lg" });

  const handleResendCode = async () => {
    setResendLoading(true);
    const payload = {
      email: query.get("email"),
      lang,
    };

    userPublicRequest
      .post("/auth/reset-password/request", payload)
      .then((res) => {
        setResendLoading(false);
        toast.success(t('AnOTPSentToYourEmail'));
      })
      .catch((error) => {
        // // console.log(error.response.data);
        // toast.error(error.message);
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      });
  };
  const minH = {
    base: `calc(${vh}px - 40px)`,
    lg: `calc(${vh}px - 50px)`,
  };
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: "10px", lg: "0px" }}
      pb={{ base: "0", lg: "0px" }}
    >
      <Box
        minH={minH[breakpoint]}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        py="50px"
        flexDirection="column"
      >
        <CustomNavLink to="/">
          <Image
            src={configs?.logo}
            alt="logo"
            width={{ base: "300px", lg: "300px" }}
            marginBottom="40px"
            marginX="auto"
          />
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w="100%"
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p={{ base: "20px", md: "20px" }}
            pt={{ base: "20px", md: "20px" }}
          >

            <Box>
              <Flex
                w="100%"
                maxW="max-content"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                justifyContent="center"
                px={{ base: "0px", md: "0px" }}
                flexDirection="column"
              >
                <Box mb="20px">
                  <Heading
                    color={textColor}
                    fontSize="30px"
                    mb="20px"
                    mx={{ base: "auto", lg: "unset" }}
                    textAlign={{ base: "center", lg: "left" }}
                    lineHeight={1}
                  >
                    {t("PasswordReset")}
                  </Heading>
                  <Text
                    color={textColorSecondary}
                    fontSize="md"
                    maxW={{ base: "95%", md: "100%" }}
                    mx={{ base: "auto", lg: "unset" }}
                    textAlign={{ base: "center", lg: "left" }}
                    lineHeight={1.5}
                  >
                    {t("EnterPasswordResetOTP")}
                  </Text>
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", md: "425px" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="15px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0", md: "auto" }}
                >
                  <form onSubmit={submitHandler}>
                    <FormControl>
                      <Flex justify="center">
                        <PinInput
                          mx="auto"
                          otp
                          value={otp}
                          onChange={(e) => setOtp(e)}
                        >
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                          />
                        </PinInput>
                      </Flex>

                      <InputGroup size="md" mt="20px">
                        <Input
                          isRequired={true}
                          fontSize="sm"
                          ms={{ base: "0px", md: "4px" }}
                          placeholder={t("PasswordPattern")}
                          mb="24px"
                          size="lg"
                          type={show ? "text" : "password"}
                          variant="auth"
                          onChange={changeForm}
                          name="password"
                          value={formData.password}
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          mt="4px"
                        >
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <Button
                        fontSize="14px"
                        variant="brand"
                        borderRadius="16px"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        // mb="24px"
                        // mt="12px"
                        type="submit"
                        bg="rgb(0,121,107)"
                        _hover={{
                          bg: "rgb(0,121,107)",
                        }}
                        _active={{
                          bg: "rgb(0,121,107)",
                        }}
                        _focus={{
                          bg: "rgb(0,121,107)",
                        }}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("ResetPassword")
                        )}
                      </Button>
                    </FormControl>
                  </form>

                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="start"
                    maxW="100%"
                    mt="20px"
                  >
                    <Text
                      color={textColorDetails}
                      fontWeight="400"
                      fontSize="14px"
                      mx={{ base: "auto", lg: "unset" }}
                      textAlign={{ base: "center", lg: "left" }}
                    >
                      {t("DidntReceiveOTP")}
                      <Text
                        color={textColorBrand}
                        cursor={"pointer"}
                        as="span"
                        ms="5px"
                        fontWeight="500"
                        onClick={handleResendCode}
                        disabled={resendLoading}
                      >
                        {resendLoading ? "Resending ..." : t("newOtpCode")}
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>

      <Footer />
    </Flex>
  );
}

export default PasswordVerificationCentered;
