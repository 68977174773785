import { Box, useDisclosure } from '@chakra-ui/react'
import { SidebarContext } from 'contexts/SidebarContext'

import { useContext, useState } from 'react'
import { Route } from 'react-router-dom'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useLanguage } from 'useLanguage'
import { AuthContext } from 'contexts/AuthContext'
import { useViewportHeight } from 'layouts/HomeNew/useViewportHeightChat'
import Messages from 'views/admin/main/others/NewMessage'
import Rewards from 'views/admin/rewards'
import Settings from 'views/admin/profile/settings'
import NewMessage2 from 'views/admin/main/others/NewMessage/NewMessage2'


export default function Dashboard(props) {
  const { ...rest } = props

  const [fixed] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps'
  }

  document.documentElement.dir = 'ltr'
  useLanguage()


  const vh = useViewportHeight()
  return (
    <>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          minHeight={`calc(${vh}px - 0px)`}
          height={'100%'}
          background={'#f8f9fa'}
          className="admin_layout"
          id="Admin_Layout"
        >
          {getRoute() ? (
            <>
              {window.location.pathname.includes('/profile') ? (
                <Settings />
              ) : window.location.pathname.includes('/chat-2') ? (
                <Messages />
              ) : window.location.pathname.includes('rewards') ? (
                <Rewards />
              ) : (
                <>
                  <NewMessage2 />
                </>
              )}
            </>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </>
  )
}
