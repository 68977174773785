import React from 'react'

function ParsedContent({ data }) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(data, 'text/html')

  Array.from(doc.getElementsByTagName('img')).forEach(img => {
    const newImg = document.createElement('img');
    newImg.src = img.src;
    newImg.width = 250;
    img.parentNode.replaceChild(newImg, img);
  });

  Array.from(doc.getElementsByTagName('a')).forEach(a => {
    let href = a.getAttribute('href');
    if (href) {
      if (!href.startsWith('http://') && !href.startsWith('https://')) {
        href = `http://${href}`;
      }
      a.setAttribute('href', href);
      a.style.color = '#0000EE';
      a.target = '_blank';
    }
  });

  const sanitizedHTML = doc.body.innerHTML

  return <h3 dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
}

export default ParsedContent
