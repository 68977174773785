import {
  Box,
  Button,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import { toast } from "react-toastify";
import { userPublicRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { FiMic } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Buffer } from "buffer";
import useCustomHistory from "langHoc/useCustomHistory";
import { Spinner } from "@chakra-ui/react";
import "./micstyles.css";
import { PagiContext } from "contexts/PagiContext";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Lottie from "react-lottie";
import animationData from "./siri.json";
import { v4 } from "uuid";
import {
  MdCheck,
  MdCheckCircle,
  MdOutlineCheckCircle,
  MdFrontLoader,
  MdPending,
} from "react-icons/md";
import { Check, Mic, StopCircle, List as ListA, Loader } from "react-feather";
import { JellyfishSpinner, FlapperSpinner } from "react-spinners-kit";
import { RecordButton } from "./RecordButton";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ModalContext } from "contexts/ModalContext";
import { userPrivateRequest } from "config/axios.config";
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const lottieOptions = {
  animationData: animationData,
  loop: true,
  autoplay: true,
};

const containerStyle = {
  width: "150px",
  height: "40px",
  margin: "0 auto",
  // padding: '10px',
  borderRadius: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const MicV2 = ({
  audioTextBack,
  ml,
  recording,
  setRecording,
  auto,
  setAuto,
  setMicModal,
  micModal,
  hideIcon,
  question,
  setQuestion,
  isSetQuestion,
}) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const formData = new FormData();
  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  let timeLimit = config[membership]?.voicePromptLimitInSec || 300;
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioURL, setAudioURL] = useState(null);
  const [audioData, setAudioData] = useState();
  const [transcribeText, setTranscribeText] = useState("");
  const [translateText, setTranslateText] = useState("");
  const [englishAnswer, setEnglishAnswer] = useState(true);

  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modulesResponse, setModulesResponse] = useState([]);
  const [ended, setEnded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [messageText, setMessaageText] = useState("");
  const [startRecord, setStartRecord] = useState(false);
  let pagiContextHandler = useContext(PagiContext);
  const { modal, setModal } = pagiContextHandler;
  const modalData = useContext(ModalContext);
  // const history = useHistory();
  const history = useCustomHistory();

  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  const [buttonColor, setButtonColor] = useState("#4FDABC");
  const [buttonHoverColor, setButtonHoverColor] = useState("rgba(79, 218, 188, .8)");
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("#4FDABC");
      setButtonHoverColor("rgba(79, 218, 188, .8)");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }

    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#0060ff");
    }
  }, []);
  // let newTranscript;
  /**
   * TESTING FUNCTION FOR START AND STOP RECORDING
   * TODO: Remove berore push
   */
  useEffect(() => {
    if (auto && Object.keys(config).length > 0 && startRecord) {
      startRecording();
    }
  }, [config, auto, startRecord]);

  useEffect(() => {
    if (micModal) {
      setModal(true);
      setMicModal(true);
    }
  }, [micModal]);

  useEffect(() => {
    // if (micModal && !question) {
    //   startRecording();
    // } else
    if (question && micModal) {
      // pagiContextHandler.setModulesResponse([])
      // setEnded(false)
      makeStateFalseForResult();
      setStartRecord(true)
      pagiContextHandler.handle__pagi__call(question);
      setMessaageText(question);
      // setQuestion("");

      setProcessing(true);
    }
  }, [micModal, question]);
  // useEffect(() => {
  //   if (question && micModal) {
  //     pagiContextHandler.handle__pagi__call(question);
  //   }
  // }, [question, micModal]);

  const handleRecording = () => {
    setRecording((prev) => !prev);
  };
  const fetchModules = async () => {
    const results = await userPublicRequest.get(
      `/get-prompts?limit=-1&source=admin`
    );

    setModules(results?.data?.data?.docs);
  };

  useEffect(() => {
    let timeoutId;
    fetchModules();
    if (mediaRecorder && mediaRecorder.state == "recording") {
      // mediaRecorder.addEventListener("stop", () => {
      //   setRecording(false);
      //   const tracks = mediaRecorder.stream.getTracks();
      //   tracks.forEach((track) => track.stop());
      // });

      if (recording) {
        timeoutId = setTimeout(() => {
          stopRecording();
        }, timeLimit * 1000);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [mediaRecorder, recording]);

  let silenceTimer = useRef(null);

  // const handleSilenceDetection = () => {
  //   clearTimeout(silenceTimer.current)
  //   silenceTimer.current = setTimeout(() => {
  //     clearTimeout(silenceTimer.current)
  //     stopRecording()
  //   }, 3000)
  // }

  // useEffect(() => {
  //   if (mediaRecorder && mediaRecorder.state === 'recording') {
  //     handleSilenceDetection()
  //   }
  // }, [transcript])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && pagiContextHandler?.modules?.length > 0) {
      setEnded(
        pagiContextHandler?.modules?.every((md) => md.status === "confirmed")
      );
    }
    return () => {
      isMounted = false;
    };
  }, [pagiContextHandler]);

  // useEffect(() => {
  //   if (newTranscript !== '') {
  //     setTimeout(() => {
  //       if (newTranscript === transcript) {
  //         stopRecording();
  //       }
  //     }, 3000);
  //   }
  // }, [newTranscript, transcript]);

  // function handleDataAvailable(event) {
  //   setAudioChunks([...audioChunks, event.data])
  // }

  function startRecording() {
    setModal(true);
    setMicModal(true);
    makeStateFalseForResult();
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      recorder.addEventListener("dataavailable", handleDataAvailable);
      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
      SpeechRecognition.startListening();
    });
  }

  const makeStateFalseForResult = () => {
    const agiRef = pagiContextHandler.agiRef;
    setEnded(false);
    pagiContextHandler.setModulesResponse([]);
    setProcessing(false);
    setQuestion("");
    setAuto(false);
    pagiContextHandler.makeEmpty();
  };

  function stopRecording() {
    setLoading(true);
    if (mediaRecorder && recording) {
      setLoading(true);
      mediaRecorder.addEventListener("stop", () => {
        setRecording(false);
        requestAnimationFrame(() => {
          const tracks = mediaRecorder.stream.getTracks();
          tracks.forEach((track) => track.stop());
        });
      });
      if (mediaRecorder.state == "recording") {
        mediaRecorder.stop();
        SpeechRecognition.stopListening();
      }
    }
  }

  const translateFunc = () => {
    const url = "https://api.openai.com/v1/audio/translations";
    const accessToken = process.env.REACT_APP_OPENAI_API_KEY;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(url, formData, { headers })
      .then((response) => {
        setTranscribeText(response.data);

        // history.push({
        //   pathname: "/chat",
        //   search: new URLSearchParams({
        //     query: response.data,
        //     newSession: "yes",
        //   }).toString(),
        // });
      })
      .catch((error) => { });
  };

  const transcribeFunc = () => {
    const headers = {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "multipart/form-data",
    };

    userPrivateRequest
      .post("/openai/transcription", formData, { headers })
      .then((response) => {

        let transcribeText = response.data?.data
        setLoading(false);
        setTranslateText(transcribeText);
        // audioTextBack(response.data);

        // history.push({
        //   pathname: "/chat",
        //   search: new URLSearchParams({
        //     query: response.data,
        //     newSession: "yes",
        //   }).toString(),
        // });
        // handle__pagi__call(transcribeText);
        // // console.log(isSetQuestion, transcribeText)
        if (isSetQuestion) {
          audioTextBack(transcribeText);
          closeModal();
        } else {

          pagiContextHandler.handle__pagi__call(transcribeText);
          setMessaageText(transcribeText);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleDataAvailable(event) {
    if (!event.data) {
      return;
    }
    setProcessing(true);

    const audioBlob = new Blob([event.data], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioURL(audioUrl);
    formData.append("file", audioBlob, "recorded_audio.mp3");
    formData.append("model", "whisper-1");
    formData.append("response_format", "text");
    // formData.append("target_language", "en");
    setAudioData(audioBlob);
    transcribeFunc();
    // if (!englishAnswer) {
    //   translateFunc();
    // } else {
    // }
  }

  const closeModal = () => {
    setModal(false);
    setMicModal(false);
    resetTranscript();
    setLoading(false);
    stopRecording();
    // setEnded(false)
    // pagiContextHandler.setModulesResponse([])
    makeStateFalseForResult();
    setMessaageText("");
    setStartRecord(false)
  };

  const retryHanlder = () => {
    setEnded(false);
    pagiContextHandler.setModulesResponse([]);
    setQuestion("");
    setAuto(false);
    setProcessing(true);
    pagiContextHandler.handle__pagi__call(messageText);
  };

  let exucation = false;

  if (
    pagiContextHandler.modules.length > 0 &&
    !pagiContextHandler.modules.find((mdl) => mdl.status === "confirmed")
  ) {
    exucation = true;
  }

  const loadingMessageStep1 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep1Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep1;
  const loadingMessageStep2 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep2Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep2;

  const loadingMessageStep3 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep3Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep3;

  const loadingMessageStep4 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep4Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep4;

  return (
    <div>
      {modal && (
        <Modal
          isOpen={modal}
          onClose={closeModal}
          // closeOnEsc={false}
          // closeOnOverlayClick={false}
          isCentered
          className="first-modal-area"
        >
          <ModalOverlay
            onClick={closeModal}
            backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
          />
          <ModalContent
            className="first-modal"
            width={{
              md: "900px",
              base: "calc(100% - 20px)",
            }}
            maxW={"100%"}
            borderRadius={"20px"}
            boxShadow={
              "0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f"
            }
            my={{
              base: "auto",
              lg: "auto",
            }}
          >
            <ModalBody pt={0} pb={0} position={"relative"} id="MIC_2">
              <Box p={"50px 16px 50px 16px"} textAlign={"center"}>
                <Box>
                  <Box
                    d="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    h="100%"
                    flexWrap={"wrap"}
                    position={"relative"}
                  >
                    {/* {loading ? (
                      <Spinner size="xl" />
                    ) : ( */}
                    <Box w={"100%"}>
                      {/* <div
                        onClick={() =>
                          recording ? stopRecording() : startRecording()
                        }
                        style={containerStyle}
                      >
                        <Mic size={100} />
                        <StopCircle size={100} />
                        <Lottie options={lottieOptions} />
                      </div> */}

                      {recording ? (
                        <div
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          // onClick={() =>
                          //   recording ? stopRecording() : startRecording()
                          // }
                          style={containerStyle}
                          className="cursor-pointer"
                        // className={recording ? 'animation-time' : ''}
                        >
                          <RecordButton />
                          {/* <StopCircle size={40} /> */}
                          {/* {recording && (
                            <div className='animated-bg'> </div>
                          )}
                          {!isHovered && <Mic size={40} color={recording ? 'white' : '#000000'} />}
                          {isHovered && <StopCircle size={40} color={recording ? 'white' : '#000000'} />} */}
                        </div>
                      ) : (
                        processing &&
                        !ended && (
                          <div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={containerStyle}
                          >
                            {pagiContextHandler?.modules?.length < 1 && (
                              <>
                                <Loader className="rotate-icon" size={40} />
                                {/* <JellyfishSpinner color={'#000'} loading={true} size={40} /> */}
                              </>
                            )}
                            {pagiContextHandler?.modules?.length > 0 && (
                              <>
                                <ListA size={40} />
                                {/* <FlapperSpinner color={'#000'} loading={true} size={40} /> */}
                              </>
                            )}
                          </div>
                        )
                      )}

                      {ended && (
                        <div
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          style={containerStyle}
                        >
                          <Check size={40} />
                        </div>
                      )}

                      <Box id="MIC_W">
                        <Heading
                          id="Transcript"
                          color={"#27272a"}
                          fontSize={"1.125rem"}
                          fontWeight={500}
                          paddingBottom={"20px"}
                          mt="25px"
                        ></Heading>

                        {recording || !startRecord ? (
                          <Heading
                            id="Transcript"
                            color={"#27272a"}
                            fontSize={"1.125rem"}
                            fontWeight={500}
                            paddingBottom={"20px"}
                          >
                            {loadingMessageStep1}
                          </Heading>
                        ) : (
                          processing &&
                          !ended && (
                            <Heading
                              id="Transcript"
                              color={"#27272a"}
                              fontSize={"1.125rem"}
                              fontWeight={500}
                              paddingBottom={"20px"}
                            >
                              {pagiContextHandler?.modules?.length < 1 &&
                                loadingMessageStep2}
                              {pagiContextHandler?.modules?.length > 0 &&
                                loadingMessageStep3}
                            </Heading>
                          )
                        )}

                        {ended && (
                          <Heading
                            id="Transcript"
                            color={"#27272a"}
                            fontSize={"1.125rem"}
                            fontWeight={500}
                            paddingBottom={"20px"}
                          >
                            {loadingMessageStep4}
                          </Heading>
                        )}
                        {/* <Heading
                          color={'#5f6368'}
                          fontSize={'32px'}
                          fontWeight={700}
                          paddingBottom={'20px'}
                        >
                          {transcript && transcript}
                        </Heading> */}
                        <List spacing={3} mb="20px">
                          {pagiContextHandler?.modules &&
                            pagiContextHandler?.modules.map((item) => (
                              <ListItem
                                key={item.id}
                                d="flex"
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                {/* {item.status === 'pending' && (
                                  <ListIcon
                                    as={MdCheckCircle}
                                    color="#cccccc"
                                  />
                                )}
                                {item.status === 'inProgress' && (
                                  <ListIcon as={MdPending} color="blue.500" />
                                )} */}
                                {/* {item.status === 'confirmed' && (
                                  <ListIcon
                                    as={MdCheckCircle}
                                    color="green.500"
                                  />
                                )} */}

                                {item.taskPlan}
                              </ListItem>
                            ))}
                        </List>
                        {ended && (
                          <Button
                            fontWeight="700"
                            color={"#fff"}
                            fontFamily={"Nunito Sans, sans-serif"}
                            bg={buttonColor}
                            py="5px"
                            px="25px"
                            borderRadius={"10px"}
                            width="110px"
                            textAlign={"center"}
                            _hover={{
                              bg: buttonColor,
                            }}
                            _active={{
                              bg: buttonColor,
                            }}
                            _focus={{
                              bg: buttonColor,
                            }}
                            onClick={() => {
                              retryHanlder();
                            }}
                            mr="20px"
                          >
                            Retry
                          </Button>
                        )}



                        <Button
                          fontWeight="700"
                          color={"#fff"}
                          fontFamily={"Nunito Sans, sans-serif"}
                          bg={buttonColor}
                          py="5px"
                          px="25px"
                          borderRadius={"10px"}
                          width="110px"
                          textAlign={"center"}
                          _hover={{
                            bg: buttonColor,
                          }}
                          _active={{
                            bg: buttonColor,
                          }}
                          _focus={{
                            bg: buttonColor,
                          }}
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          {ended ? "Done" : "Cancel"}
                        </Button>
                        {!startRecord && (
                          <Button
                            fontWeight="700"
                            color={"#fff"}
                            fontFamily={"Nunito Sans, sans-serif"}
                            bg={buttonColor}
                            py="5px"
                            px="25px"
                            borderRadius={"10px"}
                            width="110px"
                            textAlign={"center"}
                            _hover={{
                              bg: buttonColor,
                            }}
                            _active={{
                              bg: buttonColor,
                            }}
                            _focus={{
                              bg: buttonColor,
                            }}
                            onClick={() => {
                              setStartRecord(true);
                            }}
                            ml='20px'
                          >
                            {'Start'}
                          </Button>
                        )}

                        {startRecord && recording && (
                          <Button
                            fontWeight="700"
                            color={"#fff"}
                            fontFamily={"Nunito Sans, sans-serif"}
                            bg={buttonColor}
                            py="5px"
                            px="25px"
                            borderRadius={"10px"}
                            width="110px"
                            textAlign={"center"}
                            _hover={{
                              bg: buttonColor,
                            }}
                            _active={{
                              bg: buttonColor,
                            }}
                            _focus={{
                              bg: buttonColor,
                            }}
                            onClick={() =>
                              recording ? stopRecording() : startRecording()
                            }
                            ml='20px'
                          >
                            {'Stop'}
                          </Button>
                        )}

                      </Box>
                    </Box>
                    {/* )} */}
                  </Box>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* {!hideIcon && ( */}
      <Tooltip
        label="Dictate"
        borderRadius="8px"
        fontSize="md"
        isOpen={isTooltipOpen}
        onClose={() => setIsTooltipOpen(false)}
      >
        <Button
          bg="transparent"
          _hover={{
            bg: "transparent",
            backgroundColor: "rgb(230, 230, 230)",
            borderRadius: "5px",
            // height: "38px",
          }}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
          onClick={() => {
            if (!isSetQuestion && membership === "free") {


              // let exceptionMessage = isKor
              //   ? config?.global?.MODULE_EXCEPTION?.value
              //     ?.proModuleEligibilityMessageKo
              //   : config?.global?.MODULE_EXCEPTION?.value
              //     ?.proModuleEligibilityMessageEn;
              // toast(exceptionMessage, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              // });

              modalData.setCurrentModalName("moduleEligibilityException");
              return;
            } else {
              if (recording) {
                stopRecording();
              } else {
                startRecording();
              }
            }
          }}
          p="0px"
          w={"40px"}
          minW={"16px"}
          ml={ml}
          display={hideIcon ? 'none' : 'block'}
        >
          <Icon as={FiMic} color={recording && "red"} w={"20px"} h={"20px"} />
        </Button>
      </Tooltip>

      {/* )} */}
    </div>
  );
};

export default MicV2;
