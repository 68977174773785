// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";
import { useContext, useEffect, useState } from "react";

// Assets
import { ChevronRightIcon } from "@chakra-ui/icons";
import uLogoImage from "assets/img/u-logo-a.png";
import axios from "axios";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import { BsFillMicFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { WithController } from "./Count";
import PerPrompt from "components/new/PerPrompt";
import { FiMic } from "react-icons/fi";
import Dock from "react-osx-dock";
import Storiees from "views/admin/main/profile/newsfeed/components/Stories";
import MicV2 from "components/audio/MicV2";
import { userPrivateRequest } from "config/axios.config";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function NewProduct() {
  const [num, setNum] = useState(10000000);
  const inputColor = useColorModeValue(
    "secondaryGray.700",
    "secondaryGray.700"
  );

  const [question, setQuestion] = useState("");
  const [pagiQuestion, setPagiQuestion] = useState("");
  const history = useCustomHistory();
  const { t } = useTranslation();

  const formData = new FormData();
  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  let timeLimit = config[membership]?.voicePromptLimitInSec || 30;
  const maxQuestionChar = config[membership]?.maxQuestionChar || 100;
  const [scrolled, setScrolled] = useState(false);
  const [isSearchInput, setIsSearchInput] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioURL, setAudioURL] = useState(null);
  const [audioData, setAudioData] = useState();
  const [transcribeText, setTranscribeText] = useState("");
  const [translateText, setTranslateText] = useState("");
  const [englishAnswer, setEnglishAnswer] = useState(true);

  const [micModal, setMicModal] = useState(false);
  const [auto, setAuto] = useState(true);

  const [buttonColor, setButtonColor] = useState("#000");
  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')

  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("#000");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
    }

    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
    }
  }, []);

  useEffect(() => {
    // history.push('/chat')
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const CGSV = config?.global?.SEARCH_PAGE?.value
  const subtitle = isKor ? CGSV?.searchSubTitleKr : CGSV?.searchSubTitle
  const submitHandler = (e) => {
    e.preventDefault();
    const toastId = "search-error-toast";
    console.log(config?.global?.SEARCH_PAGE?.value);
    if (config?.global?.PLAY_GROUND?.value === "NO" && !question) {
      if (!toast.isActive(toastId)) {
        toast(config?.global?.SEARCH_PAGE?.value?.generateLoadingMessage, {
          toastId: toastId,
        });
      }
      return;
    }

    if (
      config?.global?.PLAY_GROUND?.value === "NO" &&
      !authData?.authState?.isAuthenticated
    ) {
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast(config?.global?.SEARCH_PAGE?.value?.nonSigninMessage, {
          toastId: toastId,
        });
      }

      return;
    }
    if (
      config?.global?.PLAY_GROUND?.value === "NO" &&
      authData?.authState?.membership !== "pro"
    ) {
      authData.setDepositModal(true);
      return;
    }
    if (
      config?.global?.WEB3_AUTHENTICATION?.value === "YES" &&
      !authData?.authState?.isAuthenticated
    ) {
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast(config?.global?.SEARCH_PAGE?.value?.nonSigninMessage, {
          toastId: toastId,
        });
      }

      return;
    }
    if (
      config?.global?.WEB3_AUTHENTICATION?.value === "YES" &&
      authData?.authState?.membership !== "pro"
    ) {
      // authData.setDepositModal(true);
      // return;
    }
    console.log(config?.global?.SEARCH_PAGE?.value);

    history.push(`/chat?query=${question}`);
  };

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  /**
   * TESTING FUNCTION FOR START AND STOP RECORDING
   * TODO: Remove berore push
   */

  const handleRecording = () => {
    setRecording((prev) => !prev);
  };

  useEffect(() => {
    let timeoutId;

    if (mediaRecorder) {
      mediaRecorder.addEventListener("stop", () => {
        setRecording(false);
        // // console.logmediaRecorder);
        const tracks = mediaRecorder.stream.getTracks();
        tracks.forEach((track) => track.stop());
      });

      if (recording) {
        timeoutId = setTimeout(() => {
          stopRecording();
        }, timeLimit * 1000);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [mediaRecorder, recording]);

  function handleDataAvailable(event) {
    setAudioChunks([...audioChunks, event.data]);
  }

  function startRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      recorder.addEventListener("dataavailable", handleDataAvailable);
      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    });
  }

  function stopRecording() {
    mediaRecorder.addEventListener("stop", () => {
      setRecording(false);
      // // console.logmediaRecorder)
      requestAnimationFrame(() => {
        const tracks = mediaRecorder.stream.getTracks();
        tracks.forEach((track) => track.stop());
      });
    });
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
    }
  }

  const translateFunc = () => {
    const url = "https://api.openai.com/v1/audio/translations";
    const accessToken = process.env.REACT_APP_OPENAI_API_KEY;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(url, formData, { headers })
      .then((response) => {
        // // console.log'SUCCESS', response.data)
        setTranscribeText(response.data);
        // history.push({
        //   pathname: '/chat',
        //   search: new URLSearchParams({
        //     query: response.data,
        //     newSession: 'yes',
        //   }).toString(),
        // })
        history.push(`/chat?query=${response.data}`);
      })
      .catch((error) => {
        // // console.log'ERROR', error.response.data)
      });
  };

  const transcribeFunc = () => {
    userPrivateRequest
      .post("/openai/transcription", formData)
      .then((response) => {
        let transcribeText = response.data?.data;
        setTranslateText(transcribeText);
        // history.push({
        //   pathname: '/chat',
        //   search: new URLSearchParams({
        //     query: transcribeText,
        //     newSession: 'yes',
        //   }).toString(),
        // })
        history.push(`/chat?query=${transcribeText}`);
      })
      .catch((error) => {
        // // console.log'ERROR', error.transcribeText)
      });
  };

  function handleDataAvailable(event) {
    const audioBlob = new Blob([event.data], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioURL(audioUrl);
    formData.append("file", audioBlob, "recorded_audio.mp3");
    formData.append("model", "whisper-1");
    formData.append("response_format", "text");
    // formData.append('target_language', 'en');
    setAudioData(audioBlob);
    if (!englishAnswer) {
      translateFunc();
    } else {
      transcribeFunc();
    }
  }

  console.log(authData?.authState?.isAuthenticated, "authData");



  const commonForm = () => {
    return (
      <form onSubmit={submitHandler}>
        <Flex bg="transparent" alignItems={"center"} position="relative">
          <Box position={"absolute"} right={0} top={"4.5px"} zIndex={9}>
            <Flex me="10px" alignItems={"center"}>
              <Box
                marginRight={"10px"}
                position={"absolute"}
                marginTop={"5px"}
                top="100%"
                right={"5px"}
              >
                {question && (
                  <Text mt="2px" fontSize={"11px"} color={"#707eae"}>
                    {question.length}/{maxQuestionChar}
                  </Text>
                )}
              </Box>
              <IconButton
                h="100%"
                w="17px"
                minW={"17px"}
                // mt={{ base: "5px", lg: "5px" }}
                bg="inherit"
                borderRadius="inherit"
                // mr={{ base: "100px", lg: "0px" }}
                onClick={() => setQuestion("")}
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={
                  <Icon
                    as={MdClose}
                    color={inputColor}
                    w={question ? "17px" : "0"}
                    h={question ? "17px" : "0"}
                  />
                }
              />

              <Button
                onClick={() => (recording ? stopRecording() : startRecording())}
                bg="transparent"
                // position={"absolute"}
                // right="40px"
                _hover={{
                  bg: "transparent",
                }}
                _active={{
                  bg: "transparent",
                }}
                w={{ base: "35px", lg: "35px" }}
                h={{ base: "35px", lg: "35px" }}
                minW={{ base: "35px", lg: "35px" }}
                minH={{ base: "35px", lg: "35px" }}
              >
                <Icon
                  as={FiMic}
                  // color={"#495057"}
                  // color="#007a6d"
                  color={recording ? "red" : "#5f6368"}
                  w={"20px"}
                  h={"20px"}
                />
              </Button>
              <Button
                borderRadius="50%"
                ms={{ base: "auto", lg: "auto" }}
                bg={buttonColor}
                w={{ base: "35px", lg: "35px" }}
                h={{ base: "35px", lg: "35px" }}
                minW={{ base: "35px", lg: "35px" }}
                minH={{ base: "35px", lg: "35px" }}
                variant="no-hover"
                type="submit"
                boxShadow="14px 17px 40px 4px rgb(112 144 176 / 18%)"
              >
                <Icon
                  as={ChevronRightIcon}
                  className="animated-icon"
                  color="white"
                  w={{ base: "15px", lg: "25px" }}
                  h={{ base: "15px", lg: "25px" }}
                />
              </Button>
            </Flex>
          </Box>
          <Input
            variant="search"
            fontSize="md"
            pl={{ base: "25px !important", lg: "25px !important" }}
            pr={{
              base: "105px",
              lg: "105px !important",
              lg: "105px !important",
            }}
            h={{ base: "44px", lg: "44px" }}
            bg="transparent"
            color={"#707eae"}
            fontWeight="500"
            _placeholder={{
              color: "#707eae",
              fontSize: { base: "14px", lg: "16px" },
            }}
            borderRadius={"50px"}
            placeholder={recording ? t("Recording") : t("SearchOrAskHere")}
            value={question}
            onChange={(e) => {
              if (e?.target?.value?.length > maxQuestionChar) {
                return;
              }
              setQuestion(e.target.value);
            }}
            background="#fff"
            boxShadow="0 0 20px 0px rgba(112, 144, 176, 0.2)"
            w="100%"
            fontFamily={'DM Sans'}
          // disabled
          />

          {/* <Box
            display="flex"
            alignItems="center"
            flex="0 0 auto"
            gap="0px 5px"
            pt="20px"
            position={'absolute'}
            top="50px"
            width="100%"
            justifyContent={'center'}
            marginTop={{ base: '20px', xl: '50px' }}
          >
            <Text
              fontWeight="500"
              color={'#5f6368'}
              fontFamily={'Nunito Sans, sans-serif'}
              display="block"
              lineHeight={'1'}
              fontSize="17px"
              marginBottom={'12px'}
            >
              {t('TotalChinguUsers')}:
            </Text>
            <WithController />
          </Box> */}
        </Flex>
      </form>
    );
  };

  return (
    <Flex
      // direction='column'
      h="100%"
      // align="center"
      pt={{ base: "100px", md: "180px", lg: "100px" }}
      pb={{ base: "24px", md: "24px", lg: "0px" }}
      position="relative"
      justifyContent="center"
      className="searchbar-wrapper"
    >
      <Tabs
        variant="unstyled"
        // mt={{ base: "60px", md: "165px" }}
        zIndex="0"
        display="flex"
        flexDirection="column"
        position={"relative"}
        width={"100%"}
      >
        <Box
          position="absolute"
          w={{ base: "300px", lg: "100%" }}
          left="50%"
          maxW={{
            base: "100%",
            lg: "400px",
          }}
          marginLeft={{ base: "-0px", lg: "-0px" }}
          // bottom={'100%'}
          textAlign="center"
          transform="translateX(-50%)"
          bottom={{ base: "99.5%", lg: "100%" }}
        >
          <img
            src={configData.logo}
            alt="logo"
            style={{
              width: '100%',
              // marginBottom: "20px",
              margin: "0 auto 10px",
            }}
          />

          <Text mb="30px" color={'#707eae'} fontWeight={500} fontSize={'16px'}> {subtitle} </Text>

          {/* {t('RECOMMENDED')} */}
        </Box>

        <TabPanels maxW={{ md: "90%", lg: "100%" }} mx="auto">
          <TabPanel p="0px">
            <Card
              p={{ base: "0px", lg: "10px" }}
              borderRadius="80px"
              boxShadow="none"
              bg="transparent"
            >
              <Box w={{ base: "100%", md: "604px", lg: "604px" }} mx="auto">
                {commonForm()}
              </Box>


              <Box
                w={{ base: "100%", md: "100%", lg: "100%" }}
                mx="auto"
              // textAlign={"center"}
              >
                {config?.global?.SHORTCUT?.value === "YES" && (
                  <Box ml="auto" textAlign={"center"}>
                    <Box
                      mt="40px"
                      display={{ base: "block", md: "inline-block" }}
                      w="auto"
                    >
                      <Storiees setMicModal={setMicModal} setAuto={setAuto} />
                    </Box>
                  </Box>
                )}

                {config?.global?.POPULARNOW?.value === "YES" && (
                  <PerPrompt />
                )}
                <MicV2
                  audioTextBack={() => {
                    // console.log("first");
                  }}
                  recording={recording}
                  setRecording={setRecording}
                  setMicModal={setMicModal}
                  micModal={micModal}
                  hideIcon={true}
                  question={pagiQuestion}
                  setQuestion={setPagiQuestion}
                  auto={auto}
                  setAuto={setAuto}
                  isSetQuestion={false}
                />
              </Box>

            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
