// Chakra imports
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import Messages2 from "views/admin/main/others/messages/components/Messages2WithMultiline";
import InitialModal from "./components/InitialModal";
import Joyride, { STATUS } from "react-joyride";
import { useEffect } from "react";
import { useMount, useSetState } from "react-use";
import a11yChecker from "a11y-checker";
import { useContext } from "react";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import TutorialModal from "layouts/HomeNew/Search/TutorialModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function Section(props) {
  return <Box color="#fff" height="100vh" px="md" py="xl" {...props} />;
}
function logGroup(type, data) {
  console.groupCollapsed(type);
  console.groupEnd();
}
export default function NewMessage2() {
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const authData = useContext(AuthContext);
  const isAuthenticated = authData?.authState?.isAuthenticated;
  let tutorials = config?.global?.TUTORIAL_MESSAGE?.value;

  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')
  const [buttonColor, setButtonColor] = useState("#834af4");
  const [buttonHoverColor, setButtonHoverColor] = useState("#834af4");
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("rgb(22, 164, 128)");
      setButtonHoverColor("#834af4");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }
    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#02A3EA");
    }
  }, []);

  // // console.log(tutorials)
  const [isTutorial, seIsTutorial] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ run, steps }, setState] = useSetState({
    run: false,
  });
  // const [isVideoTutorialDone, setIsVideoTutorialDone] = useState(
  //   Boolean(localStorage.getItem("tutorialModal"))
  // );

  const [isVideoTutorialDone, setIsVideoTutorialDone] = useState(
    true
  );
  useMount(() => {
    a11yChecker();
  });

  const accessAbleUser = authData?.authState?.user?.membership === "pro" || authData?.authState?.user?.usedFreeModeCredit === "yes"
  const first_step_message = isKor ? tutorials?.first_step_messageKr : tutorials?.first_step_message

  const second_step_message = isKor ? tutorials?.second_step_messageKr : tutorials?.second_step_message

  const third_step_message = isKor ? tutorials?.third_step_messageKr : tutorials?.third_step_message

  const fourth_step_message = isKor ? tutorials?.fourth_step_messageKr : tutorials?.fourth_step_message

  useEffect(() => {
    console.log(!localStorage.getItem("TUTORIAL_TOUR"),
    tutorials,
    isAuthenticated,
    accessAbleUser, isVideoTutorialDone, 'PLAY_GROUND')
    if (
      !localStorage.getItem("TUTORIAL_TOUR") &&
      tutorials &&
      isAuthenticated &&
      accessAbleUser && isVideoTutorialDone
    ) {
      const newSteps = [
        {
          content: <h2>{first_step_message}</h2>,
          // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
          placement: "center",
          target: "body",
        },
        {
          content: <h2>{second_step_message}</h2>,
          floaterProps: {
            disableAnimation: true,
          },
          // spotlightPadding: 40,
          target: ".single.agi.p-64b5fe29e9afc60fcc79c4be",
        },
        {
          content: third_step_message,
          placement: "bottom",
          styles: {
            options: {
              width: 300,
            },
          },
          target: ".search-input-message",
          // title: 'Our projects',
        },
        {
          content: <div>{fourth_step_message}</div>,
          placement: "right-start",
          target: "#SIDEBAR",
          // title: 'Our Mission',
        },
      ];
      setState({
        run: true,
        steps: newSteps,
      });
      seIsTutorial(true);
    } else if (isVideoTutorialDone) {
      if (accessAbleUser) {
        seIsTutorial(false);
      } else {
        seIsTutorial(true);
      }
    }
  }, [tutorials, isAuthenticated, accessAbleUser,  isVideoTutorialDone]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      localStorage.setItem("TUTORIAL_TOUR", true);
      seIsTutorial(false);
    }

    logGroup(type, data);
  };

  console.log(config?.global?.PLAY_GROUND?.value, 'PLAY_GROUND')

  return (
    <>
      <Grid display={{ base: "block" }} className="h-100" id="chat__section">
        <InitialModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        {!isVideoTutorialDone && isAuthenticated && (authData?.authState?.user?.membership === "pro" || authData?.authState?.user?.usedFreeModeCredit === "yes") && (

          <>

            {config?.global?.PLAY_GROUND?.value === "YES" && (
              <TutorialModal
                modalClose={() => {
                  setIsVideoTutorialDone(true);
                }}
              />
            )}
          </>

        )}
        <Messages2
          status="active"
          name="Roberto Michael"
          onOpen={onOpen}
          isOpen={isOpen}
          isTutorial={isTutorial}
        />
      </Grid>
      {config?.global?.PLAY_GROUND?.value === "YES" && (
        <>
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton
            run={run}
            scrollToFirstStep
            showProgress
            // showSkipButton
            steps={steps}
            styles={{
              options: {
                primaryColor: buttonColor,
                zIndex: 10000,
              },
            }}
            bg="red"
          />
        </>
      )}

    </>
  );
}
