// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import CenteredAuth from "layouts/auth/types/CenteredNewSignUp";
import Footer from "components/footer/FooterAdmin.js";
// Assets

import ReCAPTCHA from "react-google-recaptcha";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine, RiKakaoTalkFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { userPublicRequest } from "config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomNavLink from "langHoc/CustomNavLink";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import { googleProvider } from "../signIn/SocialAuth/authMethods";
import socialMediaAuth from "../signIn/SocialAuth/auth";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
import { ConfigContext } from "contexts/ConfigContext";
function SignUp() {
  const { dispatch, profileFetch } = useContext(AuthContext);
  const { t } = useTranslation();
  const [accessToken, setAccessToken] = useState("");
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const kgoogleHover = useColorModeValue({ bg: "#fada2f" }, { bg: "#fada2f" });
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState(localStorage.getItem("country"));
  const [loading, setLoading] = useState(false);
  const [ipDetailsFetched, setIpDetailsFetched] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const history = useCustomHistory();
  const handleClick = () => setShow(!show);
  let configs = useContext(ConfigContext);
  const handleShow = (type) => {
    if (type === "password") {
      setShow(!show);
    } else if (type === "repeatPassword") {
      setShowRepeatPassword(!showRepeatPassword);
    }
  };

  const lang = window.location.pathname.split("/")[1] || "en";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!repeatPassword || repeatPassword !== password) {
      const toastId = "signin-error-toast";
      const message = "Password and Confirm Password do not match.";
      return toast.error(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: toastId,
      });
    }
    setLoading(true);
    const payload = {
      firstName,
      lastName,
      email,
      password,
      lang,
      username: userName,
      recaptcha,
      phone,
      country,
    };
    const result = await userPublicRequest
      .post("/auth/register", payload)
      .then((response) => {
        let statusCode = response.status;
        if (statusCode == 205) {
          history.push(`/auth/verify?email=${email}`);
          return;
        }
        history.push(`/auth/verify?email=${email}`);
      })
      .catch((error) => {
        // // console.log(error.message);
        setLoading(false);
        let message = error.response.data.message;
        if (message === "Email already exists!") {
          message = t("EmailAlreadyExists");
        }
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      });
  };
  const kakaoSubmit = (accessToken) => {
    setLoading(true);

    userPublicRequest
      .post("/auth/kakao", { token: accessToken })
      .then((res) => {
        // let payload = res.data.results;

        const login = {
          data: {
            ...res.data.data.user,
          },
          token: res.data.data.token,
          remember_me: false,
          expires_at: "2023-01-11T10:54:32.795Z", //TODO: update static value
        };

        dispatch({
          type: "LOGIN",
          payload: login,
        });
        profileFetch();
        history.push("/chat");
      })
      .catch((error) => {
        // // console.log(error.response.data);

        toast.error(error.response.data.message);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const kakaoLogin = () => {
    const { Kakao } = window;
    Kakao.Auth.login({
      scope: "profile_nickname, account_email",
      success: (response) => {
        setAccessToken(response.access_token);
        kakaoSubmit(response.access_token);
      },
      fail: (error) => {
        // // console.log("My error", error);
        alert(error.error);
      },
    });
  };
  const handleGoogleLogin = async () => {
    try {
      googleProvider.setCustomParameters({
        login_hint: "",
      });
      const res = await socialMediaAuth(googleProvider.addScope("email"));

      userPublicRequest
        .post("/auth/google", { token: res?.credential?.idToken })
        .then((res) => {
          // let payload = res.data.results;

          const login = {
            data: {
              ...res.data.data.user,
            },
            token: res.data.data.token,
            remember_me: false,
            expires_at: "2023-01-11T10:54:32.795Z", //TODO: update static value
          };

          dispatch({
            type: "LOGIN",
            payload: login,
          });
          profileFetch();
          history.push("/chat");
        })
        .catch((error) => {
          // // console.log(error.response.data);
        })
        .finally((e) => {
          setLoading(false);
        });
    } catch (err) {
      // // console.log(err);
    }

    // googleLogin(token)
  };

  const [recaptcha, setCaptchaValue] = useState(null);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  // const getMyIp = async () => {
  //   let ip = await axios
  //     .get(
  //       "https://api.ipdata.co/?api-key=9e273957e96877a13fee745fee7b7ab3c35306e95e332d08c3a8dc36"
  //     )
  //     .then((response) => {
  //       let countryCode = response?.data?.country_code || "PH";
  //       setCountry(countryCode);
  //       localStorage.setItem("country", countryCode);

  //       setIpDetailsFetched(true);
  //       return response?.data?.countryCode || "";
  //     })
  //     .catch((err) => {
  //       console.error("Problem fetching my IP", err);

  //       setIpDetailsFetched(true);
  //       return null;
  //     });
  //   return ip;
  // };

  // useEffect(() => {
  //   if (!country) {
  //     getMyIp();
  //   } else {
  //     setIpDetailsFetched(true);
  //   }
  // }, [country]);

  // // console.log("Country now", country);
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: "10px", lg: "0px" }}
      pb={{ base: "0", lg: "0px" }}
    >
      <Box
        minH={"calc(100vh - 50px)"}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        py="50px"
        flexDirection="column"
      >
        <CustomNavLink to="/">
          <Image
            src={configs?.logo}
            alt="logo"
            width={{ base: "300px", lg: "300px" }}
            marginBottom="40px"
            marginX="auto"
          />
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w="100%"
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p={{ base: "20px", md: "20px" }}
            pt={{ base: "20px", md: "20px" }}
          >
            <Box>
              <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                justifyContent="center"
                px={{ base: "0px", md: "0px" }}
                flexDirection="column"
              >
                <Box me="auto">
                  <Heading
                    color={textColor}
                    fontSize={{ base: "30px", lg: "30px" }}
                    mb="20px"
                    lineHeight={1}
                  >
                    {t("Signup")}
                  </Heading>
                  {/* <Text
                    mb="20px"
                    ms="4px"
                    color={textColorSecondary}
                    fontWeight="400"
                    fontSize="md"
                    lineHeight={1}
                  >
                    {t("SignupContent")}
                  </Text> */}
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", md: "420px" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="15px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0", md: "auto" }}
                >
                  <Button
                    fontSize="sm"
                    me="0px"
                    mb="20px"
                    py="15px"
                    h="50px"
                    borderRadius="16px"
                    bg={googleBg}
                    color={googleText}
                    fontWeight="500"
                    _hover={googleHover}
                    _active={googleActive}
                    _focus={googleActive}
                    onClick={handleGoogleLogin}
                  >
                    <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
                    {t("GoogleSignupBtn")}
                  </Button>

                  <Button
                    fontSize="sm"
                    me="0px"
                    mb="15px"
                    py="15px"
                    h="50px"
                    borderRadius="16px"
                    bg={"#FFEB00"}
                    color={googleText}
                    fontWeight="500"
                    _hover={kgoogleHover}
                    _active={kgoogleHover}
                    _focus={kgoogleHover}
                    onClick={kakaoLogin}
                  >
                    <Icon as={RiKakaoTalkFill} w="20px" h="20px" me="10px" />
                    {t("SignUpKakao")}
                  </Button>
                  <Flex align="center" mb="16px">
                    <HSeparator />
                    <Text
                      color={textColorSecondary}
                      mx="14px"
                      fontSize={"16px"}
                      height={"16px"}
                      lineHeight={1}
                    >
                      or
                    </Text>
                    <HSeparator />
                  </Flex>

                  <form onSubmit={submitHandler}>
                    <FormControl>
                      <SimpleGrid
                        columns={{ base: "1", md: "2" }}
                        gap={{ sm: "0px", md: "20px" }}
                      >
                        <Flex direction="column">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="20px"
                            lineHeight={1}
                          >
                            {t("FirstName")}
                            <Text color={brandStars}>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            fontSize="sm"
                            ms={{ base: "0px", md: "4px" }}
                            placeholder={t("FirstName")}
                            variant="auth"
                            mb="20px"
                            size="lg"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Flex>
                        <Flex direction="column">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="20px"
                            lineHeight={1}
                          >
                            {t("LastName")}
                            <Text color={brandStars}>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            placeholder={t("LastName")}
                            mb="20px"
                            size="lg"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Flex>
                      </SimpleGrid>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="20px"
                        lineHeight={1}
                      >
                        {t("Username")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        type="text"
                        placeholder="chiwaa"
                        mb="20px"
                        size="lg"
                        value={userName}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="20px"
                        lineHeight={1}
                      >
                        {t("EmailAddress")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        type="email"
                        placeholder="email@example.com"
                        mb="20px"
                        size="lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {/* <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="20px"
                        lineHeight={1}
                      >
                        {t("MobileNumber")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      {ipDetailsFetched && (
                        <Box>
                          <PhoneInput
                            defaultCountry={country.toLowerCase()}
                            value={phone}
                            onChange={(updated, country) => {
                              setPhone(updated);
                              setCountry(country.toUpperCase());
                            }}
                            style={{
                              width: "100%",
                            }}
                          />
                        </Box>
                      )} */}
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        isRequired={true}
                        color={textColor}
                        display="flex"
                        mb="20px"
                        lineHeight={1}
                      >
                        {t("Password")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: "0px", md: "4px" }}
                          placeholder={t("PasswordPattern")}
                          mb="20px"
                          size="lg"
                          type={show ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          mt="4px"
                        >
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => handleShow("password")}
                          />
                        </InputRightElement>
                      </InputGroup>
                      <InputGroup size="md">
                        <Input
                          isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: "0px", md: "4px" }}
                          placeholder={t("ConfirmPassword")}
                          mb="20px"
                          size="lg"
                          type={showRepeatPassword ? "text" : "password"}
                          value={repeatPassword}
                          onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          mt="4px"
                        >
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={
                              showRepeatPassword
                                ? RiEyeCloseLine
                                : MdOutlineRemoveRedEye
                            }
                            onClick={() => handleShow("repeatPassword")}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <Flex justifyContent="center" align="center" mb="20px">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={handleCaptchaChange}
                        />
                      </Flex>

                      <Button
                        variant="brand"
                        fontSize="14px"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        mb="20px"
                        type="submit"
                        disabled={loading}
                        bg="rgb(0,121,107)"
                        _hover={{
                          bg: "#00877a",
                        }}
                        _active={{
                          bg: "#00877a",
                        }}
                        _focus={{
                          bg: "#00877a",
                        }}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("CreateMyAccount")
                        )}
                      </Button>
                    </FormControl>
                  </form>
                  <Text
                    color={textColorDetails}
                    fontWeight="400"
                    fontSize="13px"
                    mb="20px"
                    textAlign={"center"}
                  >
                    {t("CreateMyAccountTerms")}
                  </Text>
                  <Flex
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="start"
                    maxW="100%"
                    mt="0px"
                  >
                    <Text
                      color={textColorDetails}
                      fontWeight="400"
                      fontSize="sm"
                    >
                      {/* {t("AlreadyMember")} */}
                      <CustomNavLink to="/auth/sign-in">
                        <Text
                          color={textColorBrand}
                          as="span"
                          ms="5px"
                          fontWeight="500"
                        >
                          {t("Signin")}
                        </Text>
                      </CustomNavLink>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>

      <Footer />
    </Flex>
  );
}

export default SignUp;
