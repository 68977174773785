import React from 'react'
import { BsArrowRightShort } from 'react-icons/bs'

const NativeMeClone = ({config}) => {
  // // console.log(config?.global?.CHAT_PAGE_CONFIG?.value?.header, 'configconfig 555')
  return (
    <div className="Native">
       <div dangerouslySetInnerHTML={{ __html: config?.global?.CHAT_PAGE_CONFIG?.value?.header }} />
      {/* <div>
        <div className="title">
          한글로 만나는 AI, <br />
          Native 입니다.
        </div>
        <div className="native__content">
          <div className="native__content--left">
            <h2>🔥 Native</h2>
            <ul>
              {[1, 2, 3]?.map((sm, i) => (
                <li key={sm}>
                  <p>회사 업무 리서치 시키기</p>
                  <p class="last">
                    Fix <BsArrowRightShort color="#64748b" />{' '}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="native__content--left">
            <h2>👨‍🔧 한계</h2>
            <ul>
              {[1, 2, 3]?.map((sm, i) => (
                <li key={sm}>
                  <p>때때로 틀린 정보를 제공할 수 있어요.</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <style jsx>{`
        .Native {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .title {
          font-size: 36px;
          line-height: 50px;
          color: #000;
          font-weight: 700;
          margin-bottom: 30px;
          font-family: 'Noto Sans', sans-serif;
          text-align: center;
        }
        .native__content--left {
          background: #fff;
          // background: #f8fafc;
          border: 1px solid #f1f5f9;
          border-radius: 12px;
          padding: 20px 24px;
          width: 362px;
        }
        .native__content--left h2 {
          font-size: 24px;
          line-height: 32px;
          color: #000;
          margin-bottom: 16px;
          font-weight: 700;
          font-family: 'Noto Sans', sans-serif;
        }
        .native__content--left ul {
          list-style: disc !important;
          list-style-position: inside !important;
        }

        .native__content--left ul li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          padding-left: 10px;
          margin-bottom: 5px;
          font-family: 'Noto Sans', sans-serif;
        }
        .native__content--left ul li::after {
          position: absolute;
          left: 0;
          width: 5px;
          height: 5px;
          line-height: 5px;
          content: '';
          background: #3f3f46;
          border-radius: 5px;
        }
        .native__content--left ul li p {
          font-size: 14px;
          line-height: 22px;
          color: #3f3f46;
          font-weight: 600;
          font-family: 'Noto Sans', sans-serif;
        }
        .native__content--left ul li p.last {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 22px;
          color: #64748b;
          font-weight: 600;
          font-family: 'Noto Sans', sans-serif;
        }
        .native__content {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          gap: 0px 20px;
        }
      `}</style> */}
    </div>
  )
}

export default NativeMeClone
