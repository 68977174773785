import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "contexts/AuthContext";
import { SessionListContext } from "contexts/SessionListContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  FiCheck,
  FiEdit3,
  FiHome,
  FiLogOut,
  FiLogIn,
  FiMessageSquare,
  FiPlus,
  FiSearch,
  FiTrash2,
  FiUser,
  FiX,
  FiShare,
  FiShare2,
  FiPhoneCall,
  FiPhone,
  FiMessageCircle,
} from "react-icons/fi";
import { TbCircleLetterC } from "react-icons/tb";
import { sidebar_width } from "variables/charts";
import { Link, useHistory } from "react-router-dom";
import { ConfigContext } from "contexts/ConfigContext";
import { userPublicRequest } from "config/axios.config";
import ShareModal from "components/new/ShareModal";
import CustomNavLink from "langHoc/CustomNavLink";
import { IoIosCall } from "react-icons/io";
import { walletAddressFormat } from "utils/utils";
import DepositButton from "views/components/DepositButton";
import { ModalContext } from "contexts/ModalContext";

const LinkColor = (str, yes = false) => {
  if (yes) {
    return {
      color: "#fff",
      letterSpacing: "-0.5px",
      display: "inline-block",
      fontWeight: 700,
      fontFamily: "'Noto Sans', sans-serif",
      fontSize: "14px",
      lineHeight: "44px",
      position: "absolute",
      left: "52px",
      top: "-2px",
      width: "calc(100% - 52px)",
      height: "100%",
      zIndex: 2,
    };
  } else {
    return {
      color: "#fff",
      display: "inline-block",
      fontWeight: 700,
      fontFamily: "'Noto Sans', sans-serif",
      fontSize: "14px",
      lineHeight: "16px",
    };
  }
};

const MemoryList_Two = ({ sessionChanged, sessionId, config, handleClick }) => {
  let currentLanguage = window.location.pathname.split("/")[1] || "en";
  const { authState } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const [items, setItems] = useState({});
  const authData = useContext(AuthContext);
  let membership = authData?.authState?.membership;

  const sessionListProvider = useContext(SessionListContext);
  const { selectedPrompt, setSelectedPrompt, setCurrentSessionInfo } =
    sessionListProvider;
  let configs = useContext(ConfigContext);
  let records = sessionListProvider.records;

  // // console.log(configs?.config?.global?.CHAT_MEMORY_ICON?.value, 'emran_')

  const [loading, SetLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation();
  let memoryData = localStorage.getItem("memoryHistory") || "{}";
  const {
    isOpen: isShareModal,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const customHistory = useCustomHistory();
  const modalContextData = useContext(ModalContext);
  useEffect(() => {
    let limit = config[membership]?.maxMemoryLimit ?? 0;
    let memoryHistory = JSON.parse(memoryData);
    let finalHistory = {};
    Object.keys(memoryHistory).map((key, index) => {
      if (index < limit) {
        finalHistory[key] = memoryHistory[key];
      }
    });
    SetLoading(false);
  }, [records, config]);

  const handleEdit = (id) => {
    let info = records[id];
    info = { ...info, edit: true };
    sessionListProvider.editOnChange(id, info);
    setEditId(id);
  };

  const handleTitleChange = (e, id) => {
    let title = e.target.value;
    let info = records[id];
    info = { ...info, title };
    sessionListProvider.editOnChange(id, info);
  };

  const saveChangedTitle = (id) => {
    sessionListProvider.editSessionTitleSubmit(id);
    setEditId(null);
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
  };

  const handleConfirmDelete = (id) => {
    setIsDeleting(true);
    sessionListProvider.deleteSession(id);
    setIsDeleting(false);
    setDeleteItemId(null);
    if (sessionId == id) {
      sessionChanged(null);
    }
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    } else {
      return str.slice(0, num) + "...";
    }
  }
  const [agiSettins, setAgiSettings] = useState({});
  const fetchAgiIcon = async () => {
    try {
      const result = await userPublicRequest.get("/admin/agi-settings");
      // // console.log(result?.data?.data[0], 'agiResults')
      setAgiSettings({ ...result?.data?.data[0] });
    } catch (err) {
      // console.log(err, 'agiIocnError')
    }
  };
  const [memoryId, setMemoryId] = useState("");
  const [memoryType, setMemoryType] = useState("");
  useEffect(() => {
    fetchAgiIcon();
  }, []);

  const { chatList, setChatListe } = useContext(AuthContext);

  const homePageRedirect = () => {
    window.location.href = `${process.env.REACT_APP_APP_LINK}${i18n.language}/chat`;
  };

  function parseContent(content) {
    try {
      // Try to parse the content as JSON
      const parsed = JSON.parse(content);

      return parsed?.message;
    } catch (e) {
      // If parsing fails, it's likely a normal string
      // console.log(content, 'Content is a normal string');
      return content;
    }
  }
  let displayText;
  if (authData?.authState?.user?.source === "metamask") {
    displayText = walletAddressFormat(authData?.authState?.user?.metamaskAddress);
  } else {
    const username = authData?.authState?.user?.username;
    displayText = username?.length > 12 ? username?.substring(0, 12) + "..." : username;
  }
  return (
    <Box
      id="SIDEBAR"
      transition="left .3s linear"
      className="history-list"
      h="100vh"
      w={{ base: sidebar_width }}
      position="fixed"
      left={{ base: chatList ? "0px" : `-${sidebar_width}`, md: "0px" }}
      top={{ base: "56px", md: "0px" }}
      zIndex={{ base: 99, md: 9 }}
      bg={"#f9fafb"}
      paddingLeft={"20px"}
    >
      <DepositButton buttonHide={true} />
      {isShareModal && (
        <ShareModal
          lan={currentLanguage}
          sessionId={memoryId}
          onShareModalClose={onShareModalClose}
          memoryType={memoryType === "chat" ? "chat" : "agi"}
        />
      )}
      <Box h="63px">
        <img
          onClick={homePageRedirect}
          src={configs?.logo}
          alt=""
          // width={170}
          // height={37}
          style={{
            margin: "1px auto 1px auto",
            paddingTop: "15px",
            paddingBottom: "10px",
            cursor: "pointer",
            maxWidth: "75%",
            maxHeight: "63px",
            // marginRight: "0px",
          }}
        />
      </Box>

      <Box
        bg={"#121212"}
        m="0px auto"
        h="calc(100% - 91px)"
        borderRadius={"16px"}
        boxShadow={"3px 3px 10px 3px #ddd"}
      >
        <Box
          padding={"5px 9px"}
          bg={"#121212"}
          borderRadius={"16px"}
          margin={"8px 0px 8px 0px"}
          display="flex"
          justifyContent={"center"}
          gap="10%"
          // borderBottom="1px solid #e2e8f0"
        >
          {configs?.config?.global?.HOME_BUTTON?.value === "NO" &&
            configs?.config?.global?.SEARCH_BUTTON?.value === "NO" &&
            configs?.config?.global?.VOICE_MODE_SWITCH?.value === "NO" && (
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"20px"}
                height={"40px"}
                padding={"4px 12px"}
                position={"relative"}
                _hover={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "5px",
                }}
              ></Box>
            )}

          {configs?.config?.global?.HOME_BUTTON?.value !== "NO" && (
            <Tooltip
              label="Home"
              fontSize="md"
              placement="top"
              borderRadius="16px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"20px"}
                height={"40px"}
                padding={"4px 12px"}
                position={"relative"}
                _hover={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "5px",
                }}
              >
                <Link
                  to="/"
                  // style={LinkColor("", true)}
                  onClick={(e) => {
                    e.preventDefault();
                    customHistory.push("/chat");
                    window.location.reload();
                    // handleClick()
                    // setSelectedPrompt({})
                    // setCurrentSessionInfo({})
                  }}
                >
                  <FiHome color="#fff" size={20} />
                </Link>
              </Box>
            </Tooltip>
          )}
          {configs?.config?.global?.SEARCH_BUTTON?.value !== "NO" && (
            <Tooltip
              label="Search"
              fontSize="md"
              placement="top"
              borderRadius="16px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"20px"}
                height={"40px"}
                padding={"4px 12px"}
                position={"relative"}
                _hover={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "5px",
                }}
              >
                <Link
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    // window.location.reload()
                    customHistory.push("/");
                    // handleClick()
                    setSelectedPrompt({});
                    setCurrentSessionInfo({});
                  }}
                  // style={LinkColor("", true)}
                >
                  <FiSearch color="#fff" size={20} />
                  {/* Search */}
                </Link>
              </Box>
            </Tooltip>
          )}

          {configs?.config?.global?.VOICE_MODE_SWITCH?.value === "YES" && (
            <Tooltip
              label="Voice"
              fontSize="md"
              placement="top"
              borderRadius="16px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"20px"}
                height={"40px"}
                padding={"4px 12px"}
                position={"relative"}
                _hover={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "5px",
                }}
                cursor={"pointer"}
                onClick={() => {
                  if (membership === "pro") {
                    customHistory.push("/chat?mode=voice");
                  } else {
                    modalContextData.setCurrentModalName(
                      "moduleEligibilityException"
                    );
                  }
                }}
              >
                {/* <FiPhone color="#fff" size={20} /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#fff"
                  viewBox="0 0 256 256"
                >
                  <path d="M251.76,88.94l-120-64a8,8,0,0,0-7.52,0l-120,64a8,8,0,0,0,0,14.12L32,117.87v48.42a15.91,15.91,0,0,0,4.06,10.65C49.16,191.53,78.51,216,128,216a130,130,0,0,0,48-8.76V240a8,8,0,0,0,16,0V199.51a115.63,115.63,0,0,0,27.94-22.57A15.91,15.91,0,0,0,224,166.29V117.87l27.76-14.81a8,8,0,0,0,0-14.12ZM128,200c-43.27,0-68.72-21.14-80-33.71V126.4l76.24,40.66a8,8,0,0,0,7.52,0L176,143.47v46.34C163.4,195.69,147.52,200,128,200Zm80-33.75a97.83,97.83,0,0,1-16,14.25V134.93l16-8.53ZM188,118.94l-.22-.13-56-29.87a8,8,0,0,0-7.52,14.12L171,128l-43,22.93L25,96,128,41.07,231,96Z"></path>
                </svg>
              </Box>
            </Tooltip>
          )}
        </Box>

        {/* TOP CARD FINISH */}

        <Box
          padding={"0px 12px 8px 9px"}
          bg={"#121212"}
          borderRadius={"16px 16px 0px 0px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          margin={"0px 0px 0px 0px"}
          transform="translate(0px, 4px)"
          className="SIDEBAR-text"
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            padding={"2px 12px"}
          >
            <FiMessageCircle color="#fff" size={20} />
            <p style={LinkColor("")}>{t("Chat")}</p>
          </Box>
          {/* <Box
          display={'flex'}
          alignItems={'center'}
          gap={'20px'}
          cursor="pointer"
          padding={'2px 12px'}
          onClick={handleClick}
        >
          <FiPlus color="#fff" size={20} />
        </Box> */}
        </Box>

        <Box
          height={{ base: "calc(100% - 160px)", lg: "calc(100% - 185px)" }}
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
          padding={"0px 12px 8px 9px"}
          bg={"#121212"}
          margin={"0px 0px 0px 0px"}
          borderRadius={"0px 0px 16px 16px"}
          transform="translate(0px, 3px)"
        >
          <Box
            overflowX={"hidden"}
            className="history-list-container"
            overflowY={"scroll"}
            minH={"calc(100% - 4.5rem)"}
            // mt='12px'
            // borderBottom={"1px solid rgba(255, 255, 255, 0.2)"}
            // maxH={{ md: '90%', base: 'calc(100% - 120px)' }}
          >
            {Object.keys(records).length > 0 &&
              Object.keys(records).map((_id, i) => {
                // // console.log(records[_id]?.agiId, 'emran_2')
                let mmoryType = records[_id]?.source || "chat";
                return (
                  <Flex
                    key={_id}
                    alignItems="center"
                    // justifyContent="space-between"
                    w="98%"
                    py="12px"
                    className="memory-item updated-memory-item"
                    paddingX={"4px"}
                    _hover={{
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                    transition="background-color 0.2s, box-shadow 0.2s"
                    borderRadius={"5px"}
                    gap="20px"
                  >
                    {records[_id] && records[_id].edit ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          saveChangedTitle(_id);
                        }}
                        style={{
                          display: "flex",
                          gap: "0.75rem",
                        }}
                      >
                        <Box width={"20px"} height="auto" ps={0} pe="0">
                          <img
                            src={
                              mmoryType == "chat"
                                ? configs?.config?.global?.CHAT_MEMORY_ICON
                                    ?.value
                                : agiSettins?.icon_url
                            }
                            alt=""
                          />
                        </Box>
                        <Input
                          autoFocus
                          value={parseContent(records[_id].title)}
                          onChange={(e) => handleTitleChange(e, _id)}
                          p="0"
                          border="none"
                          focusBorderColor="none"
                          height={"20px"}
                          className="memory-input"
                          color="#f2f2f2!important"
                          fontSize="14px!important"
                          lineHeight={"20px!important"}
                          fontWeight={500}
                          fontFamily={"'Noto Sans', sans-serif"}
                          id="__eta"
                        />
                      </form>
                    ) : (
                      <>
                        {records[_id]?.isDiscovered == "no" ? (
                          <Box
                            width={"28px"}
                            height="auto"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              width={"5px"}
                              height="5px"
                              ps={0}
                              pe="0"
                              backgroundColor={"#fff"}
                              borderRadius={"50%"}
                              marginLeft={"auto"}
                              marginRight={"auto"}
                              className="animated-icon"
                            >
                              s
                            </Box>
                            <img
                              width={"20px"}
                              src={
                                mmoryType == "chat"
                                  ? configs?.config?.global?.CHAT_MEMORY_ICON
                                      ?.value
                                  : agiSettins?.icon_url
                              }
                              alt=""
                            />
                          </Box>
                        ) : (
                          <Box
                            width={"20px"}
                            height="auto"
                            ps={0}
                            pe="0"
                            ml={"8px"}
                          >
                            <img
                              src={
                                mmoryType == "chat"
                                  ? configs?.config?.global?.CHAT_MEMORY_ICON
                                      ?.value
                                  : agiSettins?.icon_url
                              }
                              alt=""
                            />
                          </Box>
                        )}
                        <Text
                          cursor="pointer"
                          // onClick={() => }
                          color="#fff"
                          fontSize="14px!important"
                          lineHeight={"20px"}
                          fontWeight={
                            records[_id]?.isDiscovered == "yes" ? 300 : 800
                          }
                          fontFamily={"'Noto Sans', sans-serif"}
                          // fontFamily={'Sans-serif'}
                          onClick={() => {
                            if (mmoryType === "chat") {
                              sessionChanged(_id);
                              setChatListe(false);
                            } else {
                              window.open(
                                `${process.env.REACT_APP_AGI_APP}/${i18n.language}/?memoryId=${records[_id]?.agiId}`
                              );
                            }
                          }}
                        >
                          {truncateString(parseContent(records[_id].title), 10)}{" "}
                          {/* {records[_id].isDiscovered} */}
                        </Text>
                      </>
                    )}
                    <Flex className="memory-buttons" gap={"10px"}>
                      {deleteItemId === _id ? (
                        <>
                          <IconButton
                            bg="transparent"
                            icon={<FiX />}
                            aria-label="Cancel"
                            onClick={() => setDeleteItemId(null)}
                            height="auto"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                          <IconButton
                            bg="transparent"
                            icon={<FiCheck />}
                            aria-label="Confirm"
                            onClick={() => handleConfirmDelete(_id)}
                            height="auto"
                            isLoading={isDeleting}
                            loadingText="Deleting"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                        </>
                      ) : editId === _id ? (
                        <>
                          <IconButton
                            bg="transparent"
                            icon={<FiX />}
                            aria-label="Cancel"
                            onClick={() => {
                              setEditId(null);
                              let memoryHistory = JSON.parse(memoryData);
                              setItems(memoryHistory);
                              SetLoading(false);
                              saveChangedTitle(_id);
                            }}
                            height="auto"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                          <IconButton
                            bg="transparent"
                            icon={<FiCheck />}
                            aria-label="Confirm"
                            onClick={() => saveChangedTitle(_id)}
                            height="auto"
                            isLoading={isDeleting}
                            loadingText="Deleting"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                        </>
                      ) : (
                        <>
                          <IconButton
                            bg="transparent"
                            icon={<FiEdit3 />}
                            aria-label="Edit"
                            onClick={() => handleEdit(_id)}
                            // mr={2}
                            height="auto"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                          <IconButton
                            bg="transparent"
                            icon={<FiShare2 />}
                            aria-label="Confirm"
                            onClick={() => {
                              setMemoryId(_id);
                              onShareModalOpen();
                              setMemoryType(mmoryType);
                            }}
                            height="auto"
                            isLoading={isDeleting}
                            loadingText=""
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                          <IconButton
                            bg="transparent"
                            icon={<FiTrash2 />}
                            aria-label="Delete"
                            onClick={() => handleDelete(_id)}
                            // mr={2}
                            height="auto"
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            style={{
                              minWidth: "16px",
                            }}
                            color="#fff"
                          />
                        </>
                      )}
                    </Flex>
                  </Flex>
                );
              })}
          </Box>

          {/* <>
          <Flex
            w={{ sm: "100%", md: "auto" }}
            alignItems="center"
            flexDirection="row"
            justifyContent={"center"}
            minH="4.5rem"
            pl="12px"
            pr="12px"
          >
            {membership === "pro" && (
              <Text
                fontSize="14px"
                color={"#f2f2f2"}
                mb="0px"
                fontWeight="700"
                lineHeight={"16px"}
                // fontFamily={'sans-serif'}
                fontFamily={"'Noto Sans', sans-serif"}
                whiteSpace={"nowrap"}
                textAlign={"center"}
              >
                {authData?.authState?.user?.creditBalance?.toLocaleString()}{" "}
                {config[membership]?.creditLabel}
              </Text>
            )}
          </Flex>
        </> */}
        </Box>

        <Box
          padding={"5px 9px"}
          bg={"#121212"}
          borderRadius={"16px"}
          margin={"0px 0px 8px 0px"}
          // borderTop="1px solid #e2e8f0"
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            height={"40px"}
            padding={"4px 12px"}
            position={"relative"}
            // _hover={{
            //   background: 'rgba(255, 255, 255, 0.1)',
            //   borderRadius: '5px',
            // }}
            color="#fff"
          >
            {/* <p style={{
               color: '#fff',
               display: 'inline-block',
               fontWeight: 700,
               fontFamily: "'Noto Sans', sans-serif",
               fontSize: '14px',
               lineHeight: '16px',
            }}> CHI</p> */}

            <TbCircleLetterC color="#fff" size={20} />
            <p
              style={{
                color: "#fff",
                display: "inline-block",
                fontWeight: 700,
                fontFamily: "'Noto Sans', sans-serif",
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              {authData?.authState?.user?.creditBalance?.toLocaleString()}{" "}
            </p>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            height={"40px"}
            padding={"4px 12px"}
            position={"relative"}
            // _hover={{
            //   background: 'rgba(255, 255, 255, 0.1)',
            //   borderRadius: '5px',
            // }}
          >
            {authState?.isAuthenticated ? (
              <>
                <CustomNavLink to="/profile">
                  <FiUser color="#fff" size={20} />
                </CustomNavLink>
                {/* <FiLogOut color="#fff" size={20} /> */}

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w="100%"
                >
                  <CustomNavLink
                    style={LinkColor("", false)}
                    to="/profile"
                    // onClick={() => {
                    //   dispatch({
                    //     type: 'LOGOUT',
                    //   })
                    //   history.push('/auth/sign-in')
                    // }}
                  >
                    {/* {t('Logout')} */}
                    {/* {authData?.authState?.user?.username} */}

                    {displayText}
                  </CustomNavLink>

                  <CustomNavLink to="/logout">
                    <FiLogOut color="#fff" size={20} />
                  </CustomNavLink>
                </Box>
              </>
            ) : (
              <>
                <FiLogIn color="#fff" size={20} />
                <Link style={LinkColor("")} href="/auth/sign-in">
                  {t("Signin")}
                </Link>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MemoryList_Two;
