import "assets/css/App.css";
import HomeNewLayout from "layouts/HomeNew";
import AdminLayout from "layouts/admin";
import AuthLayoutNew from "layouts/authNew";
import RTLLayout from "layouts/rtl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { Suspense, useContext, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Pricing from "./views/admin/main/others/pricing";
import PaymentSuccess from "./views/admin/main/others/paymentSuccess";
import Privacy from "./views/admin/main/others/privacy";
import Terms from "./views/admin/main/others/terms";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

// Chakra imports
import { Box, ChakraProvider, Heading, Spinner } from "@chakra-ui/react";
import AuthProvider from "contexts/AuthContext";
import ConfigProvider from "contexts/ConfigContext";
import SessionListProvider from "contexts/SessionListContext";
import PagiProvider from "contexts/PagiContext";
import CustomRoute from "langHoc/CustomRoute";
import Blog from "layouts/blog";
import Demo from "layouts/demo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "theme/theme";
import "./i18n";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { lazy } from "react";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight";
import LandingPage from "views/landing";
import LogoutPage from "views/logout";
import { AuthContext } from "contexts/AuthContext";
import { userPrivateRequest } from "config/axios.config";
import { useHistory } from "react-router-dom";
import useCustomHistory from "langHoc/useCustomHistory";
import Recoder from "views/admin/main/others/messages/Recorder";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "components/StripeForm";
import { FiCheck } from "react-icons/fi";
import Maintaince from "views/maintaince";
import { ConfigContext } from "contexts/ConfigContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import ModalProvider from "contexts/ModalContext";
import { ModalContext } from "contexts/ModalContext";
import { t } from "i18next";

// const Home2 = lazy(() => import("./views/home-2"), {});
const AskPdf = lazy(() => import("./views/ask-pdf"), {});
const MauerGrid = lazy(() => import("components/new/MauerGrid"), {});
// Get search params
const params = true;
const queryClient = new QueryClient();
const helmetContext = {};

function FontFamilyHandler() {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const updateBodyClass = () => {
      if (i18n.language === "ko") {
        document.body.classList.add("ko");
      } else {
        document.body.classList.remove("ko");
      }
    };

    updateBodyClass();

    // Listen for language change and update the body class
    i18n.on("languageChanged", updateBodyClass);

    // Clean up the listener on unmount
    return () => {
      i18n.off("languageChanged", updateBodyClass);
    };
  }, [i18n]);
  const vh = useViewportHeight();
  useEffect(() => {
    localStorage.setItem("vh", vh * 100);
  }, []);

  return null;
}

function ModalHandler() {
  const configData = useContext(ConfigContext);
  const PLAN_STATIC_CONFIGURATION =
    configData?.config?.global?.PLAN_STATIC_CONFIGURATION?.value || {};
  const authData = useContext(AuthContext);
  const modalData = useContext(ModalContext);
  const { dispatch } = useContext(AuthContext);
  // const [modal, setModal] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [plan, setPlan] = useState();
  const [stripePlan, setStripePlan] = useState();
  const [stripePlanYear, setStripePlanYear] = useState();
  const [earlyAssessPlan, setEarlyAccessPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const [paymentStep, setPaymentStep] = useState("package");
  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')
  const { modal, setModal } = authData


  useEffect(() => {
    if (
      authData?.authState?.membership !== "pro" &&
      authData?.authState?.user?.creditBalance <= 0 &&
      authData?.authState?.user?.subscriptionStatus === "none" &&
      !window.location.href.includes('landing')
    ) {
      setModalPreview(true);
    } else {
      setModalPreview(false);
    }
  }, [authData]);

  useEffect(() => {
    if (modalData.currentModalName == "membershipPackageModal") {
      setModal(true);
    }
  }, [modalData]);



  const fetchData = async () => {
    try {
      userPrivateRequest
        .get("/get-available-early-access-package")
        .then((response) => {
          setEarlyAccessPlan(response?.data?.data ?? {});
        });
      const result = await userPrivateRequest.get("/get-plans");
      const result2 = await userPrivateRequest.get("/get-stripe-plans");
      setPlan(result?.data?.data[0]);
      setStripePlan(result2?.data?.data[0]);
      setStripePlanYear(result2?.data?.data[1]);
    } catch (err) { }
  };

  useEffect(() => {
    fetchData();
    localStorage.setItem('theme', 'light')

    console.log(150)
  }, []);

  const billingAgreement = async () => {
    setLoading(true);
    try {
      const result = await userPrivateRequest.post("/subscriptions", {
        planId: plan?.paypalPlanId,
      });
      window.location.href = result.data.data.approval_url;
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const stripeBillingAgreement = async () => {
    setLoading(true);
    try {
      const result = await userPrivateRequest.post(
        "/create-stripe-subscription",
        {
          priceId: stripePlan?.stripePriceId,
        }
      );
      window.location.href = result?.data?.url;
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const stripeBillingAgreementYear = async () => {
    setLoading(true);
    try {
      const result = await userPrivateRequest.post(
        "/create-stripe-subscription",
        {
          priceId: stripePlanYear?.stripePriceId,
        }
      );
      window.location.href = result?.data?.url;
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleEarlyAccessSubscription = async () => {
    setLoading(true);
    let priceId = earlyAssessPlan?.stripeMonthlyPriceId;
    if (interval == "year") {
      priceId = earlyAssessPlan?.stripeHalfYearlyPriceId;
    }

    try {
      const result = await userPrivateRequest.post(
        "/create-stripe-subscription",
        {
          priceId,
        }
      );
      window.location.href = result?.data?.url;
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const [isFreeModeSubscribing, setIsFreeModeSubscribing] = useState(false)

  const handleFreeModeRequest = async () => {
    setIsFreeModeSubscribing(true)
    const result = await userPrivateRequest.get(
      "/profile/requestForFreeMode"
    ).then(response => {
      setModal(false);
      setIsFreeModeSubscribing(false)

      authData.profileFetch();
      return response.data
    }).catch(err => {
      setIsFreeModeSubscribing(true)
      console.error(err.message);

      authData.profileFetch();
    });

  }

  const choosePaymentHandler = () => {
    setPaymentStep("choose");
  };

  const [interval, setInterval] = useState("year");

  const freePlanTitle = isKor ? PLAN_STATIC_CONFIGURATION?.freePlanTitleKr : PLAN_STATIC_CONFIGURATION?.freePlanTitle
  const freePlanSubtitle = isKor ? PLAN_STATIC_CONFIGURATION?.freePlanSubtitleKr : PLAN_STATIC_CONFIGURATION?.freePlanSubtitle
  const freePlanPrice = isKor ? PLAN_STATIC_CONFIGURATION?.freePlanPriceKr : PLAN_STATIC_CONFIGURATION?.freePlanPrice
  const freePlanDescription = isKor ? PLAN_STATIC_CONFIGURATION?.freePlanDescriptionKr : PLAN_STATIC_CONFIGURATION?.freePlanDescription


  const customPlanTitle = isKor ? PLAN_STATIC_CONFIGURATION?.customPlanTitleKr : PLAN_STATIC_CONFIGURATION?.customPlanTitle
  const customPlanSubtitle = isKor ? PLAN_STATIC_CONFIGURATION?.customPlanSubtitleKr : PLAN_STATIC_CONFIGURATION?.customPlanSubtitle
  const customPlanPrice = isKor ? PLAN_STATIC_CONFIGURATION?.customPlanPriceKr : PLAN_STATIC_CONFIGURATION?.customPlanPrice
  const customPlanDescription = isKor ? PLAN_STATIC_CONFIGURATION?.customPlanDescriptionKr : PLAN_STATIC_CONFIGURATION?.customPlanDescription

  const freePlanExpired = isKor ? PLAN_STATIC_CONFIGURATION?.freePlanExpiredMessage : PLAN_STATIC_CONFIGURATION?.freePlanExpiredMessageKr
  const freeplanCheck = authData?.authState?.user?.membership && authData?.authState?.user?.membership !== "pro" && authData?.authState?.user?.usedFreeModeCredit !== 'yes'


  const firstPlanLabel = isKor ? PLAN_STATIC_CONFIGURATION?.monthlyPackageLabelKr : PLAN_STATIC_CONFIGURATION?.monthlyPackageLabelEn
  const secondPlanLabel = isKor ? PLAN_STATIC_CONFIGURATION?.sixMonthlyPackageLabelKr : PLAN_STATIC_CONFIGURATION?.sixMonthlyPackageLabelEn

  return (
    <>
      {modalPreview && configData?.config?.global?.WEB3_AUTHENTICATION?.value !==
        "YES" && (
          <>
            <div
              className="modal-review-preview"
              onClick={() => setModal(true)}
            ></div>
          </>
        )}

      {modal && (
        <div className={`${modal ? "js--hubspot-popup-overlay lg" : "nai"}`}>
          <div
            className={`${modal ? "hubspot-popup-form lg" : "hubspot-popup-form-close"
              }`}
          >
            <div className="modalBody">
              <div className="modalHeader">
                <div className="titles">
                  <h1>
                    {isKor
                      ? PLAN_STATIC_CONFIGURATION?.modalTitleKo
                      : PLAN_STATIC_CONFIGURATION?.modalTitleEn}
                  </h1>
                  <p>
                    {/* You are joining a limited seat early access program for an
                    innovative and highly experimental software service for
                    commercial grade artificial general intelligence. You
                    understand that Chingu AI is undergoing constant
                    enhancements, new feature updates and bug fixes. In the
                    event of running out of tokens, additional tokens may be
                    purchased and replenished. Statistics show that 82% of early
                    adopters of AI reported extremely positive financial returns
                    on their investments.{" "} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isKor
                          ? PLAN_STATIC_CONFIGURATION?.modalDescriptionKo
                          : PLAN_STATIC_CONFIGURATION?.modalDescriptionEn,
                      }}
                    />
                  </p>
                </div>

                <div className="btn">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({ type: "LOGOUT" });

                      setTimeout(() => {
                        setModal(false);
                      }, 300);
                      window.location.href = `${process.env.REACT_APP_APP_LINK}auth/sign-in`;
                    }}
                  >
                    Sign out
                  </button>
                </div>
                <button
                  onClick={() => {
                    setModal(false);
                    modalData.setCurrentModalName(null);
                  }}

                  className="close-sign-close"
                >
                  <AiOutlineClose size={15} color="rgb(107, 114, 128)" />
                </button>

              </div>

              <div className="border"></div>

              {paymentStep === "stripe" && (
                <div className="">
                  <StripeForm />
                </div>
              )}
              {paymentStep === "choose" && (
                <div className="modalMain">
                  <Box
                    border="1px solid #ddd"
                    p="20px"
                    cursor={"pointer"}
                    onClick={() => stripeBillingAgreement()}
                  >
                    <Heading fontSize={"20px"}> Pay Using Card </Heading>
                  </Box>

                  <Box
                    border="1px solid #ddd"
                    p="20px"
                    cursor={"pointer"}
                    onClick={billingAgreement}
                  >
                    <Heading fontSize={"20px"}> Pay Using PayPal </Heading>
                  </Box>
                </div>
              )}

              {paymentStep === "package" && (
                <div className="modalMain">
                  <>
                    <div
                      className={`${!freeplanCheck ? "disabled-plan" : ""
                        } singlePlan`}
                      style={{
                        position: "relative",
                        margin: "0px",
                        border: "1px solid grey",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {!freeplanCheck && (
                        <div className="disabled-overlay"></div>
                      )}
                      {!freeplanCheck && (
                        <div className="message-overlay">
                          {" "}
                          {freePlanExpired}{" "}
                        </div>
                      )}
                      <div>
                        <h1
                          style={{
                            marginTop: "0px",
                            fontSize: "30px",
                            lineHeight: "35px",
                          }}
                        >
                          {freePlanTitle}
                        </h1>
                        <p style={{ fontSize: "18px" }}> {freePlanSubtitle} </p>
                        <h2 className="" style={{ marginTop: "15px" }}>
                          {" "}
                          {freePlanPrice}
                        </h2>

                        <div className="freeBtn">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handleFreeModeRequest}
                          >
                            {isFreeModeSubscribing ? (
                              <>
                                <Spinner />
                              </>
                            ) : (
                              // "Use Chingu AI's basic services now"
                              <>
                                {isKor
                                  ? PLAN_STATIC_CONFIGURATION?.freeButtonTextKo
                                  : PLAN_STATIC_CONFIGURATION?.freeButtonTextEn}
                              </>
                            )}
                          </a>
                        </div>

                        <div className="options">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: freePlanDescription,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="singlePlan"
                      style={{
                        margin: "0px",
                        border: "1px solid grey",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                      }}
                    >
                      <div className="switch-wrapper">
                        <label
                          for="monthly"
                          className={interval == "month" && "activated"}
                          onClick={() => {
                            setInterval("month");
                          }}
                        >
                          {firstPlanLabel}
                        </label>
                        <label
                          for="Half year"
                          className={interval == "year" && "activated"}
                          onClick={() => {
                            setInterval("year");
                          }}
                        >
                          {secondPlanLabel}
                        </label>
                      </div>

                      <div>
                        <h1
                          style={{
                            marginTop: "0px",
                            fontSize: "30px",
                            lineHeight: "35px",
                          }}
                        >
                          {isKor
                            ? earlyAssessPlan?.nameKo
                            : earlyAssessPlan?.name}
                        </h1>
                        <p style={{ fontSize: "18px", textAlign: "center" }}>
                          {isKor
                            ? earlyAssessPlan?.subTitleKo
                            : earlyAssessPlan?.subTitle}
                        </p>
                        <h2 className="" style={{ marginTop: "15px" }}>
                          {interval == "month" ? (
                            "$" + earlyAssessPlan?.monthlyPrice + "/mo"
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                $ {earlyAssessPlan?.monthlyPrice}{" "}
                              </p>
                              <span
                                style={{
                                  fontSize: "20px",
                                  marginLeft: "10px",
                                }}
                              >
                                $
                                {(earlyAssessPlan?.halfYearlyPrice / 6).toFixed(2)}
                                /mo
                              </span>
                            </div>
                          )}
                          {/* /{interval == "month" ? "mo" : "mo"} */}
                        </h2>

                        <div className="freeBtn" pb={"5px"}>
                          <a href="#" onClick={handleEarlyAccessSubscription}>
                            {isKor
                              ? earlyAssessPlan?.buttonTextKo
                              : earlyAssessPlan?.buttonTextEn}
                          </a>
                        </div>

                        <div className="options">
                          {/* {earlyAssessPlan?.description} */}

                          <div
                            dangerouslySetInnerHTML={{
                              __html: isKor
                                ? earlyAssessPlan?.descriptionKo
                                : earlyAssessPlan?.description,
                            }}
                          />
                          {/* <p style={{ fontWeight: "bold" }}>
                            Things you'll love with the CHINGU Basic Plan:
                          </p> */}
                          {/* <ul style={{ listStyleType: "none" }}>
                            <li>
                              ✅ Access to all AI content creation tools (text,
                              image, video, and music generation, 1-click blog
                              posting, article ideas, etc)
                            </li>
                            <li>
                              ✅ Access to all AI marketing tools (copywriting,
                              SEO keyword research, product description, image
                              and video editing, etc.)
                            </li>
                            <li>
                              ✅ Automation of multiple tasks through the
                              personal AGI assistant
                            </li>
                            <li>✅ Access to an AI researcher and analyst</li>
                            <li>
                              ✅ Access to highly specialized AI modules such as
                              coding, master teacher, business consultant,
                              marketing, etc.
                            </li>
                            <li>
                              ✅ Expert text editor (edits sentences,
                              atmosphere, tonality, emotion, grammar fix, etc.)
                            </li>
                            <li>✅ Quick photo and video editing module</li>
                            <li>✅ Deep research and analysis by AGI</li>
                            <li>
                              ✅ Expert video script generator with AI narrator
                            </li>
                            <li>
                              ✅ Enhanced conversational tool equivalent to
                              ChatGPT (with voice command feature)
                            </li>
                            <li>
                              ✅ Fine-tuned premium AI modules with periodic
                              updates
                            </li>
                            <li>✅ More Private Search Memory</li>
                            <li>✅ Unlimited Answers Length</li>
                            <li>
                              ✅ Shareable CHINGU results to Chingu website and
                              other platforms
                            </li>
                          </ul> */}
                        </div>
                      </div>
                      {/* <div className="freeBtn" style={{ marginTop: "10px" }}>
                        <a onClick={handleEarlyAccessSubscription}>
                          Try Chingu AI's free features now
                        </a>
                      </div> */}
                    </div>

                    <div
                      className="singlePlan"
                      style={{
                        margin: "0px",
                        border: "1px solid grey",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h1
                          style={{
                            marginTop: "0px",
                            fontSize: "30px",
                            lineHeight: "35px",
                          }}
                        >
                          {customPlanTitle}
                        </h1>
                        <p style={{ fontSize: "18px" }}>{customPlanSubtitle}</p>
                        <h2 className="" style={{ marginTop: "15px" }}>
                          {customPlanPrice}
                        </h2>
                        <div className="freeBtn">
                          <a
                            href="https://form.typeform.com/to/igEXSxTF"
                            target="_blank"
                          >
                            {isKor
                              ? PLAN_STATIC_CONFIGURATION?.businessButtonTextKo
                              : PLAN_STATIC_CONFIGURATION?.businessButtonTextEn}
                          </a>
                        </div>

                        <div className="options">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: customPlanDescription,
                            }}
                          />
                          {/* <p style={{fontWeight: 'bold'}}>
                          Things you'll love with the CHINGU Business Plan:
                        </p>
                          <ul style={{ listStyleType: 'none' }}>
                            <li>
                              ✅ Access to all Chingu features
                            </li>
                            <li>
                              ✅ More Private Search Memory
                            </li>
                            <li>
                              ✅ More CHI credits
                            </li>
                            <li>
                              ✅ API access
                            </li>
                            <li>
                              ✅ Account Manager Support
                            </li>
                          </ul> */}
                        </div>
                      </div>
                      {/* <div className="freeBtn">
                          <a
                            href='https://form.typeform.com/to/igEXSxTF'
                            target="_blank"
                          >
                            Contact us here.
                          </a>
                        </div> */}
                    </div>
                  </>
                </div>
              )}
            </div>
          </div>

          <style jsx>{`
            .js--hubspot-popup-overlay.lg {
              z-index: 99999;
            }
            .liStyle {
              display: flex;
              align-items: center;
              gap: 5px;
            }
            .modalMain {
              display: flex;
              gap: 32px 70px;
              width: 90%;
              margin: 9% auto auto auto;
              padding: 32px 0px;
              justify-content: center;
            }
            .singlePlan {
              width: 100%;
              max-width: 370px;
              box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
                rgb(229, 231, 235) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0.3) 0px 15px 40px -17px;
            }
            .js--hubspot-popup-overlay {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              left: 0;
              top: 0;
              width: 100vw;
              height: 100vh;
              right: 0;
              bottom: 0;
              overflow: hidden;
              z-index: 99;
              background: rgba(0, 0, 0, 0.5);
            }
            .hubspot-popup-form.lg {
              background-image: none;
              background: #fff;
              width: 100%;
              max-width: 1440px;
              border-radius: 0.375rem;
              padding: 0px;
              position: relative;
            }
            .hubspot-popup-form {
              opacity: 1;
              transform: none;
              height: auto;
              margin: 0px auto;
              overflow: auto;
              max-height: 100%;
              overflow-x: hidden;
              padding: 20px;
              background-image: linear-gradient(#fff 50%, #fff) !important;
            }
            .hubspot-popup-form-close {
              opacity: 0;
            }
            .modalHeader {
              display: flex;
              margin: 0 auto;
              padding: 24px;
              align-items: flex-start;
              position: fixed;
              background-color: #fff;
              width: 90%;
              border-radius: 8px;
              position: absolute;
              flex-wrap: wrap;
              justify-content: space-between;
              margin: auto;
              left: 5%;
            }
            button.close-sign-close {
              position: absolute;
    right: -62px;
    top: 10px;
          }
            .modalHeader .titles {
              width: calc(100% - 100px);
            }
            .border {
              width: 100%;
              height: 1px;
              border-bottom: 1px solid #ddd;
            }
            .titles {
              text-align: left;
            }
            .titles h1 {
              font-weight: 700;
              font-size: 30px;
              line-height: 36px;
              color: #111928;
            }
            .titles p {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #6b7280;
            }

            .btn {
              margin-left: auto;
            }

            .btn button {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #4b5563;
              border-radius: 0.375rem;
              padding: 5px 15px;
              border: 1px solid #ddd;
            }

            .singlePlan {
              text-align: left;
              border: 1px solid rgb(229, 231, 235);
              padding: 20px;
              border-radius: 4px;
            }

            .singlePlan .para {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #6b7280;
              margin: 24px 0px;
            }
            .singlePlan h1 span,
            .singlePlan h1 {
              font-weight: 700;
              font-size: 48px;
              line-height: 48px;
              text-align: center;
              color: #111928;
              margin-bottom: 15px;
              margin-top: 30px;
            }

            .options h2,
            .singlePlan h2 {
              font-weight: 500;
              font-size: 32px;
              line-height: 40px;
              text-align: center;
              color: #111928;
              margin-bottom: 15px;
              margin-top: 30px;
            }
            .mo {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #6b7280;
            }
            .options h2 {
              padding: 120px 30px;
              text-align: left;
            }
            .options {
              border-top: 1px solid #ddd;
              padding-top: 15px;
              border-radius: 4px;
            }
            .singleOption {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #374151;
              display: flex;
              align-items: center;
              gap: 0px 10px;
              margin-bottom: 12px;
              text-decoration: underline;
            }
            .singleOption p {
              width: calc(100% - 20px);
            }
            .singleOption svg {
              width: 20px;
              height: 20px;
            }

            .freeBtn {
              text-align: center;
            }

            .freeBtn a {
              border-radius: 8px;
              border: 1px solid #ddd;
              padding: 5px 15px;
              font-size: 14px;
              font-weight: 600;
              width: 184px !important;
              display: flex;
              height: 56px;
              justify-content: center;
              align-items: center;
              border-radius: 50px;
              margin: auto;
              // background-color: rgb(96, 165, 250);
              background-image: linear-gradient(
                90deg,
                rgb(0, 205, 250) -20.67%,
                rgb(144, 88, 255) 42.73%,
                rgb(255, 118, 223) 105.77%
              );
              color: #fff;
              margin-bottom: 20px;
            }

            .freeBtn button,
            .btn button {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: #4b5563;
              border-radius: 0.375rem;
              padding: 5px 15px;
              border: 1px solid #ddd;
            }

            @media (max-width: 1366px) {
              // .modal-review-preview,
              // .js--hubspot-popup-overlay.lg {
              //   width: 80%;
              //   margin-left: auto;
              //   margin-right: auto;
              // }
            }
            @media (max-width: 767px) {
              .hubspot-popup-form.lg {
                max-width: 370px;
                width: 100%;
              }
              .hubspot-popup-form.lg .modalBody {
                height: auto;
                max-height: 100%;
              }
              .titles h1 {
                font-size: 24px;
              }
              .btn button {
                width: 90px;
              }
            }
          `}</style>
        </div>
      )}
    </>
  );
}

function App() {
  const configData = useContext(ConfigContext);
  const authData = useContext(AuthContext);
  const configLoading = configData?.configLoading
  useEffect(() => { }, [authData]);
  useEffect(() => {

    const linkForFavicon = document.querySelector("link[rel='icon']");
    const metaDescription = document.querySelector("meta[name='description']");
    const ogTitle = document.querySelector("meta[property='og:title']");
    const ogImage = document.querySelector("meta[property='og:image']");
    const ogSiteName = document.querySelector("meta[property='og:site_name']");
    const ogDescription = document.querySelector("meta[property='og:description']");

    const description = "Still working with multiple AI tools spread across different products? With the world's first fully automated AGI research tool, you can write high-quality blogs or scripts from start to finish using live web data. Plus, the ability to create presentation video content from AI and AGI-generated content with just a simple click."
    const title = process.env.REACT_APP_PLATHFORM
    const settings = {
      'DappStoreAi': {
        title: 'DAPPX AI',
        favicon: '/dappxfavicon/favicon.ico',
        description: description,
        ogTitle: 'DAPPX AI',
        ogImage: '/meta.png',
        ogSiteName: 'DAPPX AI',
        ogDescription: description
      },
      'Questron': {
        title: title,
        favicon: '/questronfavicon/favicon.ico',
        description: description,
        ogTitle: title,
        ogImage: '/meta-questron.png',
        ogSiteName: title,
        ogDescription: description
      },
      'Chingu': {
        title: title,
        favicon: '/favicon.ico',
        description: description,
        ogTitle: title,
        ogImage: '/meta.png',
        ogSiteName: title,
        ogDescription: description
      },
      // Add more platforms as needed
    };

    const currentSetting = settings[process.env.REACT_APP_PLATHFORM];

    if (currentSetting) {
      document.title = currentSetting.title;

      if (linkForFavicon) {
        linkForFavicon.href = currentSetting.favicon;
      }

      if (metaDescription) {
        metaDescription.content = currentSetting.description;
      }

      if (ogTitle) {
        ogTitle.content = currentSetting.ogTitle;
      }

      if (ogImage) {
        ogImage.content = currentSetting.ogImage;
      }

      if (ogSiteName) {
        ogSiteName.content = currentSetting.ogSiteName;
      }

      if (ogDescription) {
        ogDescription.content = currentSetting.ogDescription;
      }
    }

  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <ConfigProvider>
          <AuthProvider>
            <ModalProvider>
              <SessionListProvider>
                <PagiProvider>

                  <React.StrictMode>
                    <FontFamilyHandler />
                    <ModalHandler />
                    <QueryClientProvider client={queryClient}>
                      <Suspense fallback="">
                        {/* <HelmetMetaData currentUrl="https://uata76bb54.chingu.fun/en/chat?reference=644b7e47b3508ae958ca1f7a"></HelmetMetaData> */}
                        <Switch>
                          <CustomRoute
                            exact
                            path={["/", "/:lang(en|ko)"]}
                            component={HomeNewLayout}
                          />
                          <CustomRoute
                            exact
                            path={[
                              "/execute-agreement",
                              "/:lang(en|ko)/execute-agreement",
                            ]}
                            component={PaymentSuccess}
                          />
                          <CustomRoute
                            path={["/chat", "/:lang(en|ko)/chat"]}
                            component={AdminLayout}
                          />

                          <CustomRoute
                            path={["/maintenance", "/:lang(en|ko)/maintenance"]}
                            component={Maintaince}
                          />
                          {/* <CustomRoute
     path={['/chat-2', '/:lang(en|ko)/chat-2']}
     component={AdminLayout}
   /> */}
                          <CustomRoute
                            path={["/profile", "/:lang(en|ko)/profile"]}
                            component={AdminLayout}
                          />
                              <CustomRoute
                            path={["/rewards", "/:lang(en|ko)/rewards"]}
                            component={AdminLayout}
                          />
                          <CustomRoute
                            path={["/auth", "/:lang(en|ko)/auth"]}
                            component={AuthLayoutNew}
                          />
                          <CustomRoute
                            path={["/rtl", "/:lang(en|ko)/rtl"]}
                            component={RTLLayout}
                          />
                          <CustomRoute
                            path={["/recorder", "/:lang(en|ko)/recorder"]}
                            component={Recoder}
                          />
                          <CustomRoute
                            path={["/terms", "/:lang(en|ko)/terms"]}
                            component={Terms}
                          />
                          {/* <CustomRoute
       path={["/home-2", "/:lang(en)/home-2"]}
       component={Home2}
     /> */}
                          <CustomRoute
                            path={["/landing", "/:lang(en|ko)/landing"]}
                            component={LandingPage}
                          />
                          <CustomRoute
                            path={["/logout", "/:lang(en|ko)/logout"]}
                            component={LogoutPage}
                          />

                          <CustomRoute
                            path={["/ask-pdf", "/:lang(en|ko)/ask-pdf"]}
                            component={AskPdf}
                          />
                          <CustomRoute
                            path={["/masonary", "/:lang(en|ko)/masonary"]}
                            component={MauerGrid}
                          />
                          <CustomRoute
                            path={["/privacy", "/:lang(en|ko)/privacy"]}
                            component={Privacy}
                          />
                          {/* <CustomRoute
       path={["/pro-sign-up", "/:lang(en)/pro-sign-up"]}
       component={Pricing}
     /> */}
                          <CustomRoute
                            path={["/Blog", "/:lang(en|ko)/Blog"]}
                            component={Blog}
                          />
                          <CustomRoute
                            path={["/demo", "/:lang(en|ko)/demo"]}
                            component={Demo}
                          />
                        </Switch>
                      </Suspense>
                    </QueryClientProvider>

                    <ToastContainer
                      position="bottom-center"
                      autoClose={5000}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      theme="dark"
                    />
                  </React.StrictMode>



                </PagiProvider>
              </SessionListProvider>
            </ModalProvider>
          </AuthProvider>
        </ConfigProvider>
      </Router>
    </ChakraProvider>
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <App />
  </Elements>,
  document.getElementById("root")
);
