// Chakra Imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useContext } from "react";
// Assets
import { AuthContext } from "contexts/AuthContext";
import CustomNavLink from "langHoc/CustomNavLink";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import { MdNotificationsNone } from "react-icons/md";
import { ConfigContext } from "contexts/ConfigContext";

export default function HeaderLinks(props) {
  const { dispatch, authState } = useContext(AuthContext);
  const history = useCustomHistory();
  const { secondary } = props;

  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;

  // // console.log(authState, authState, "state");
  const { t } = useTranslation();

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";

  const modalMessage = () => {
    return (
      <MenuList
        boxShadow={
          "0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
        }
        px="32px"
        pt="63px"
        pb="57px"
        me={{ base: "30px", md: "unset" }}
        borderRadius="4px"
        // bg={menuBg}
        border="none"
        mt="0"
        minW={{ base: "unset" }}
        maxW={{ base: "250px", md: "250px" }}
        bg="radial-gradient(48.39% 48.39% at 49.8% 52.33%, #9672BB 0%, rgba(102, 51, 153, 0.98) 100%);"
      >
        <Text pb="30px" textAlign={"center"} color={"#fff"}>
          {t("BePart2")}
        </Text>
        {/* <Image src={navImage} borderRadius="16px" mb="28px" /> */}
        <Flex flexDirection="column">
          <CustomNavLink w="100%" to="/auth/sign-in">
            <Button
              w="100%"
              mb="0px"
              height={"50px"}
              borderRadius="4px"
              variant="brand"
              bg="#6CC2CA"
              fontSize={"12px"}
              py="9px"
              _hover={{
                bg: "#4FE8CB",
              }}
            >
              {t("JoinPro")}
            </Button>
          </CustomNavLink>
        </Flex>
      </MenuList>
    );
  };

  // // console.log(authData?.authState?.user?.creditBalance, 'authData')
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      borderRadius="30px"
    >
      {membership === "pro" && (
        <Text
          fontSize="14px"
          color={"#27272a"}
          mb="0px"
          fontWeight="bold"
          whiteSpace={"nowrap"}
        >
          {creditLabel}{" "}
          {authData?.authState?.user?.creditBalance?.toLocaleString()}
        </Text>
      )}

      {/* <Menu>
        <MenuButton display="flex" alignItems="center" p="0px">
          <SearchBar
            mb={secondary ? { base: "10px", md: "unset" } : "unset"}
            me="10px"
            borderRadius="30px"
          />
        </MenuButton>
        {modalMessage()}
      </Menu> */}

      {/* <IconButton
						bg='inherit'
						borderRadius='inherit'
						_hover='none'
						_active={{
							bg: 'inherit',
							transform: 'none',
							borderColor: 'transparent'
						}}
						_focus={{
							boxShadow: 'none'
						}}
						icon={<Icon as={FiSearch} color={'#495057'} w='25px' h='25px' />}
					/> */}

      {/* <SidebarResponsive routes={routes} /> */}
      <Menu>
        <MenuButton display="flex" alignItems="center" p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            // color={navbarIcon}
            color={"#495057"}
            w="25px"
            h="25px"
            mx={{ base: "0px", md: "10px" }}
          />
        </MenuButton>
        {modalMessage()}
      </Menu>

      {/* <Menu>
        <MenuButton p="0px">
          <Icon
            as={MdInfoOutline}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
            mt="6px"
          />
        </MenuButton>
        {modalMessage()}
      </Menu> */}

      {/* <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        h='18px'
        w='max-content'
        onClick={toggleColorMode}>
        <Icon
          me='10px'
          h='18px'
          w='18px'
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button> */}

      {authState.isAuthenticated && (
        <Menu>
          <MenuButton p="0px">
            <Box
              display={"flex"}
              alignItems="center"
              gap="10px"
              borderLeft={"1px solid #dee2e6"}
              pl="16px"
            >
              <Avatar
                _hover={{ cursor: "pointer" }}
                color="white"
                name={
                  authState.isAuthenticated
                    ? authState?.user?.firstName +
                      " " +
                      authState?.user?.lastName
                    : "USER"
                }
                bg="#343a40"
                size="sm"
                w="35px"
                h="35px"
                borderRadius={"5px"}
                fontFamily={"Nunito Sans, sans-serif"}
              />
              {/* {authState.isAuthenticated ? authState?.user.firstName + " " + authState?.user.lastName : ""} */}
            </Box>
          </MenuButton>
          <MenuList
            boxShadow={
              "0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
            }
            p="0px"
            mt="0px"
            borderRadius="4px"
            bg={"#fff"}
            border="none"
          >
            {/* {authState.isAuthenticated && (
              <Flex w="100%" mb="0px">
                <Text
                  ps="20px"
                  pt="16px"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="700"
                  color={textColor}
                >
                  👋&nbsp; Hey, {authState?.user?.firstName}
                </Text>
              </Flex>
            )} */}

            <Flex flexDirection="column" p="10px">
              {!authState.isAuthenticated && (
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  px="14px"
                  // padding={{base: '0 0 0 14px', '4sm': '0px 14px'}}
                  href="/auth/sign-in"
                >
                  <Link href="/auth/sign-in">
                    <Text fontSize="sm">{t("signin")}</Text>
                  </Link>
                </MenuItem>
              )}

              {authState.isAuthenticated && (
                <>
                  <MenuItem
                    _hover={{ background: "#e9ecef" }}
                    _focus={{ bg: "none" }}
                    // color='red.400'
                    borderRadius="4px"
                    py="6px"
                    onClick={() => {
                      history.push("/profile");
                    }}
                    color="#495057"
                    fontFamily={"Nunito Sans, sans-serif"}
                  >
                    <Text fontSize="sm">{t("ProfileSetting")} </Text>
                  </MenuItem>

                  <MenuItem
                    _hover={{ background: "#e9ecef" }}
                    _focus={{ bg: "none" }}
                    // color='red.400'
                    borderRadius="4px"
                    px="14px"
                    py="6px"
                    color="#495057"
                    fontFamily={"Nunito Sans, sans-serif"}
                    onClick={() => {
                      dispatch({
                        type: "LOGOUT",
                      });
                      history.push("/auth/sign-in");
                    }}
                  >
                    <Text fontSize="sm">{t("Logout")}</Text>
                  </MenuItem>
                </>
              )}
            </Flex>
          </MenuList>
        </Menu>
      )}
      {!authState.isAuthenticated && (
        <Button
          color="#495057"
          bg="transparent"
          size="sm"
          w={{ base: "auto", "4sm": "80px" }}
          h="34px"
          onClick={() => {
            dispatch({
              type: "LOGOUT",
            });
            history.push("/auth/sign-in");
          }}
          _hover={{
            cursor: "pointer",
          }}
          fontFamily={"Nunito Sans, sans-serif"}
          fontSize="16px"
          fontWeight={400}
          pb="10px"
          pt="12px"
          borderLeft={{ base: "none", "4sm": "1px solid #dee2e6" }}
          borderRight={{ base: "none", "4sm": "1px solid #dee2e6" }}
          borderRadius="0px"
          px={{ base: "10px", "4sm": "14px" }}
        >
          {t("Signin")}
        </Button>
      )}
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
