import { useContext, useEffect, useState, useMemo } from "react";
import StakingReport from "../profile/settings/components/StakingReport"
import TopUpCredit from "../profile/settings/components/TopUpCredit";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import { useLocation } from "react-router-dom";
import useCustomHistory from "langHoc/useCustomHistory";
import { toast } from "react-toastify";
import { userSuperPrivateRequest } from "config/axios.config";
import { userPrivateRequest } from "config/axios.config";
import Profile from "views/admin/profile/settings/components/Profile";
import banner from "assets/img/auth/banner.png";
const Rewards = ({

}) => {
    const authData = useContext(AuthContext);
    const configData = useContext(ConfigContext);
    function useQuery() {
        const { search } = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const [info, setInfo] = useState({});
    const config = configData.config || {};
    const [submitLoading, setSubmitLoading] = useState(false);
    const [data, setData] = useState({});
    const [selectedImage, setSelectedImage] = useState({
        file: null,
        previewUrl: "",
    });

    const query = useQuery();
    const customhistory = useCustomHistory();

    const applyExucation = async () => {
        try {
            const result = await userPrivateRequest.get(
                `/execute-agreement?token=${query.get("token")}`
            );
        } catch (err) { }

        customhistory.replace("/rewards");
    };

    useEffect(() => {
        if (query.get("token")) {
            applyExucation();
        }
    }, [query.get("token")]);

    useEffect(() => {
        userSuperPrivateRequest
            .get("/profile")
            .then((response) => {
                setInfo(response.data.user);

            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === "401"
                ) {
                    //  logout
                    localStorage.clear();
                    window.location = "/auth/sigh-in";
                }
            });
    }, []);




    useEffect(() => {
        console.log(info, 515151);
        setData({
            email: info?.email,
            firstName: info?.firstName,
            lastName: info?.lastName,
            username: info?.username,
            photo: info?.photo,
            metamaskAddress: info?.metamaskAddress,
        });
    }, [info]);
    return <>
        <Profile
            name={`${info?.username ?? ""}`}
            info={info}
            banner={banner}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
        />
        {info?.membership === "pro" &&
            configData?.config?.global?.WEB3_AUTHENTICATION?.value !==
            "YES" && <TopUpCredit />}
        {info?.membership === "pro" &&
            configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
            "YES" && <StakingReport data={info} />}
    </>
}

export default Rewards