// Chakra imports
import {
  Badge,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  FormControl,
  Input,
  Spinner,
} from '@chakra-ui/react'
// Custom components
import Footer from 'components/footer/FooterAdmin.js'
import Navbar from 'components/navbar/NavbarPricing'
import PricingLayout from 'layouts/auth/types/Pricing'
import { userPublicRequest } from 'config/axios.config'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import Pack from 'views/admin/main/others/pricing/components/Pack'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useCustomHistory from 'langHoc/useCustomHistory'
import PaymentPack from './components/PaymentPack'
import { AuthContext } from 'contexts/AuthContext'
import { ConfigContext } from 'contexts/ConfigContext'

function Pricing() {
  const { dispatch, authState } = useContext(AuthContext)
  const router = useCustomHistory()
  const brandStars = useColorModeValue('brand.500', 'brand.400')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [activeButton, setActiveButton] = useState({
    monthly: true,
    yearly: false,
  })

  let configs = useContext(ConfigContext);

  const styles = {
    backgroundColor: hovered ? '#00877a' : 'rgb(0,121,107)',
    color: '#ffffff',
  }

  const { t, i18n } = useTranslation()
  const submitHandler = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      const payload = {
        email,
      }
      const result = await userPublicRequest.post('/waitlist/submit', payload)
      // // console.log()
      toast.success('Account added successfully', {
        onClose: () => {
          setLoading(false)
          // history.push(`/auth/verify?email=${email}`)
        },
      })
    } catch (err) {
      // console.log(err)
      setLoading(false)
      toast.error(err.response.data.message)
    }
  }

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const history = useHistory()

  return (
    <Box>
      <Box position={'relative'}>
        {/* Video section  */}
        <video
          id={'w___video'}
          className="video-playback"
          autoPlay
          loop
          muted
          src={process.env.REACT_APP_AMZ_VID_URL}
          playsInline
          style={{
            position: 'fixed',
            left: '0',
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -1,
          }}
        />
        {/* Content Section */}
        <Box
          style={{
            position: 'absolute',
            left: '0',
            top: 0,
            width: '100%',
            height: 'auto',
            zIndex: 999,
          }}
        >
          <Box
            id="contnet_start"
            width={{ base: '100%', lg: '100%', xl: '100%' }}
            marginX={'auto'}
            // marginBottom={"50px"}
          >
            {/* LOGO Start */}
            <img
              src={configs?.logo}
              alt="logo"
              onClick={() => history.push(`/${i18n.language}`)}
              style={{
                width: '350px',
                cursor: 'pointer',
                margin: '50px auto 50px auto',
              }}
            />
            <Box className="">
              <Box id="faq-section" width={'100%'} bg="#fff"></Box>
              <Flex
                direction={{ base: 'row', lg: 'column' }}
                alignSelf="center"
                justifySelf="center"
                overflow="hidden"
              >
                <Flex
                  direction="column"
                  textAlign="center"
                  justifyContent="center"
                  align="center"
                >
                  <Text
                    fontSize={{ base: '28px', lg: '32px', xl: '44px' }}
                    color="white"
                    fontWeight="700"
                    maxW="650px"
                    lineHeight={{ base: '32px', lg: '42px', xl: '52px' }}
                    // marginBottom={{base: '10px', lg: '15px', xl:'32px'}}
                    mb={{ base: '0px', xl: '0px' }}
                  >
                    {t('PricingTitle')}
                  </Text>

                  <Box
                    display={'flex'}
                    alignItems={'flex-start'}
                    gap={'50px 20px'}
                    marginTop={{
                      base: '20px',
                      md: '50px',
                    }}
                    flexDirection={{ base: 'column', xl: 'row' }}
                    justifyContent={'space-between'}
                    width={{ base: '90%', lg: '95%', xl: '70%' }}
                    pb="50px"
                  >
                    <Pack
                      title={t('Free')}
                      desc={t('FreeDesc')}
                      button={t('CreateAccount')}
                      buttonOnClick={() => {
                        if (authState?.isAuthenticated) {
                          router.push('/profile')
                        } else {
                          router.push('/auth/sign-up')
                        }
                      }}
                      benefits={[
                        t('FreeFeature1'),
                        t('FreeFeature2'),
                        t('FreeFeature3'),
                        t('FreeFeature4'),
                        t('FreeFeature5'),
                        t('FreeFeature6'),
                        // t("FreeFeature7"),
                        // t("FreeFeature8"),
                        // t("FreeFeature9"),
                        // t("FreeFeature10"),
                        // t("FreeFeature11"),
                      ]}
                      mt={'30px'}
                    />

                    <PaymentPack />

                    <Pack
                      title={t('custom')}
                      desc={t('CustomDesc')}
                      button={t('CustomBtn')}
                      topPage
                      // price={
                      //   <Text color={textColor} fontSize='40px' fontWeight='bold'>
                      //     +1 982 66 88 99
                      //   </Text>
                      // }
                      // details='(Available in all countries)'
                      benefits={[
                        t('CustomFeature1'),
                        t('CustomFeature2'),
                        t('CustomFeature3'),
                        t('CustomFeature4'),
                        t('CustomFeature5'),
                      ]}
                      buttonOnClick={() => {
                        window.open(t('ContactUsLink'), '_self')
                      }}
                      mt={'30px'}
                    />
                  </Box>

                  <Box
                    id="faq-section"
                    width={{ md: '100%', base: '100%' }}
                    px={{ base: '5%', md: '0px' }}
                    pb="50px"
                    bg="#f8f8f9"
                  >
                    <Text
                      color={textColor}
                      fontWeight="bold"
                      fontSize="34px"
                      mt={{ base: '30px', xl: '30px' }}
                      mb="30px"
                    >
                      {t('FrequentlyAskedQuestions')}
                    </Text>
                    <SimpleGrid
                      columns={{ md: '1', lg: '2' }}
                      spacing="60px"
                      maxW="1170px"
                      mx="auto"
                    >
                      <Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ1')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA1') }}
                          ></Text>
                        </Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ2')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA2') }}
                          ></Text>
                        </Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ3')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA3') }}
                          ></Text>
                        </Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ4')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA4') }}
                          ></Text>
                        </Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ5')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA5') }}
                          ></Text>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ6')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA6') }}
                          ></Text>
                        </Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ7')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA7') }}
                          ></Text>
                        </Box>
                        <Box>
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="30px"
                          >
                            {t('FaqQ8')}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            mb="30px"
                            dangerouslySetInnerHTML={{ __html: t('FaqA8') }}
                          ></Text>
                        </Box>
                      </Box>
                    </SimpleGrid>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  )
}

export default Pricing
