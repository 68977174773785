import React, { useRef } from 'react';
import { Scrollbars } from 'rc-scrollbars';
import css from 'dom-css';

const ShadowScrollbars = (props) => {
  const shadowTopRef = useRef(null);
  const shadowBottomRef = useRef(null);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity = (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));

    // Adjusting css for the top shadow based on its opacity
    if (shadowTopOpacity > 0) {
        css(shadowTopRef.current, { opacity: shadowTopOpacity, display: 'block' });
    } else {
        css(shadowTopRef.current, { opacity: shadowTopOpacity, display: 'none' });
    }

    // Adjusting css for the bottom shadow based on its opacity
    if (shadowBottomOpacity > 0) {
        css(shadowBottomRef.current, { opacity: shadowBottomOpacity, display: 'block' });
    } else {
        css(shadowBottomRef.current, { opacity: shadowBottomOpacity, display: 'none' });
    }
};

  // const handleUpdate = (values) => {
  //   const { scrollTop, scrollHeight, clientHeight } = values;
  //   const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
  //   const bottomScrollTop = scrollHeight - clientHeight;
  //   const shadowBottomOpacity =
  //     (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));

  //   css(shadowTopRef.current, { opacity: shadowTopOpacity });
  //   css(shadowBottomRef.current, { opacity: shadowBottomOpacity });
  // };

  const { style, ...otherProps } = props;
  const containerStyle = {
    ...style,
    position: 'relative',
  };
  const shadowTopStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '19%',
    background: 'linear-gradient(to top, transparent, rgba(255, 255, 255, 0.8))',
  };
  const shadowBottomStyle = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '15%',
    background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.8))',
    // zIndex: '-1',
  };

  return (
    <div style={containerStyle}>
      <Scrollbars onUpdate={handleUpdate} {...otherProps} />
      <div ref={shadowTopRef} style={shadowTopStyle} />
      <div ref={shadowBottomRef} style={shadowBottomStyle} />
    </div>
  );
};

export default ShadowScrollbars;
