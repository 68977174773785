// Chakra imports
import {
  Avatar,

  Button,

  Flex,

  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import storyImage from "assets/img/profile/storyImage.png";
import React from "react";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { MdOutlineFavoriteBorder } from "react-icons/md";

export default function SeeStory(props) {
  const { username, sum, avatar, name, onClick, action, image, ...rest } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue(
    "white !important",
    "#111c44 !important"
  );
  return (

    <Button
      bg='transparent'
      variant='no-hover'
      fontWeight='700'
      display='flex'
      h='100px'
      w={{ base: "80px", sm: '120px', md: '140px' }}
      boxShadow='unset'
      onClick={onClick}
      {...rest}
      alignItems={'start'}

    >

      <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        w='100%'

      >
        <Avatar
          border='3px solid'
          borderColor={borderColor}
          h='50px'
          w='50px'
          src={avatar}
          bg="white"
          borderRadius={'0'}
        />
        {name ? (

          <Text
            color={textColor}
            fontSize={{ base: '12px', md: 'sm' }}
            fontWeight='500'
            wordBreak='break-word'
            whiteSpace={'pre-line'}
          >
            {name}
          </Text>

        ) : null}
      </Flex>

    </Button>

  );
}
