import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";

import Messages from "views/admin/main/others/NewMessage";

import { BsChatSquare } from "react-icons/bs";

const routes = [
  // --- Dashboards ---
  {
    name: "Home",
    path: "/",
    icon: <Icon as={MdHome} width="20px" height="20px" color="#767676" />,
    collapse: false,
    // items: [
    // 	{
    // 		name: 'Main Dashboard',
    // 		layout: '/admin',
    // 		path: '/dashboards/default',
    // 		component: DashboardsDefault
    // 	},
    // 	{
    // 		name: 'Car Interface',
    // 		layout: '/admin',
    // 		path: '/dashboards/car-interface',
    // 		component: DashboardsCarInterface
    // 	},
    // 	{
    // 		name: 'Smart Home',
    // 		layout: '/admin',
    // 		path: '/dashboards/smart-home',
    // 		component: DashboardsSmartHome
    // 	},
    // 	{
    // 		name: 'RTL',
    // 		layout: '/rtl',
    // 		path: '/dashboards/rtl',
    // 		component: DashboardsRTLDefault
    // 	}
    // ]
  },
  // --- NFTs ---
  // {
  // 	name: 'NFTs',
  // 	path: '/nfts',
  // 	icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
  // 	collapse: true,
  // 	items: [
  // 		{
  // 			name: 'Marketplace',
  // 			layout: '/admin',
  // 			path: '/nfts/marketplace',
  // 			component: NFTMarketplace,
  // 			secondary: true
  // 		},
  // 		{
  // 			name: 'Collection',
  // 			layout: '/admin',
  // 			path: '/nfts/collection',
  // 			component: NFTCollection,
  // 			secondary: true
  // 		},
  // 		{
  // 			name: 'NFT Page',
  // 			layout: '/admin',
  // 			path: '/nfts/page',
  // 			component: NFTPage,
  // 			secondary: true
  // 		},
  // 		{
  // 			name: 'Profile',
  // 			layout: '/admin',
  // 			path: '/nfts/profile',
  // 			component: NFTProfile,
  // 			secondary: true
  // 		}
  // 	]
  // },
  // --- Main pages ---
  {
    name: "Chat",
    path: "/chat",
    icon: <Icon as={BsChatSquare} width="20px" height="20px" color="#767676" />,
    collapse: false,
    component: Messages,
    layout: "/",
    // items: [
    // 	{
    // 		name: 'Account',
    // 		path: '/main/account',
    // 		collapse: true,
    // 		items: [
    // 			{
    // 				name: 'Billing',
    // 				layout: '/admin',
    // 				path: '/main/account/billing',
    // 				exact: false,
    // 				component: AccountBilling
    // 			},
    // 			{
    // 				name: 'Application',
    // 				layout: '/admin',
    // 				path: '/main/account/application',
    // 				exact: false,
    // 				component: AccountApplications
    // 			},
    // 			{
    // 				name: 'Invoice',
    // 				layout: '/admin',
    // 				path: '/main/account/invoice',
    // 				exact: false,
    // 				component: AccountInvoice
    // 			},
    // 			{
    // 				name: 'Settings',
    // 				layout: '/admin',
    // 				path: '/main/account/settings',
    // 				exact: false,
    // 				component: AccountSettings
    // 			},
    // 			{
    // 				name: 'All Courses',
    // 				layout: '/admin',
    // 				path: '/main/account/all-courses',
    // 				exact: false,
    // 				component: AccountAllCourses
    // 			},
    // 			{
    // 				name: 'Course Page',
    // 				layout: '/admin',
    // 				path: '/main/account/course-page',
    // 				exact: false,
    // 				component: AccountCoursePage
    // 			}
    // 		]
    // 	},
    // 	{
    // 		name: 'Ecommerce',
    // 		path: '/main/users',
    // 		collapse: true,
    // 		items: [
    // 			{
    // 				name: 'New Product',
    // 				layout: '/admin',
    // 				path: '/main/ecommerce/new-prodcut',
    // 				exact: false,
    // 				component: EcommerceNewProduct
    // 			},
    // 			{
    // 				name: 'Product Settings',
    // 				layout: '/admin',
    // 				path: '/main/ecommerce/settings',
    // 				exact: false,
    // 				component: EcommerceProductSettings
    // 			},
    // 			{
    // 				name: 'Product Page',
    // 				layout: '/admin',
    // 				path: '/main/ecommerce/page-example',
    // 				exact: false,
    // 				component: EcommerceProductPage
    // 			},
    // 			{
    // 				name: 'Order List',
    // 				layout: '/admin',
    // 				path: '/main/ecommerce/order-list',
    // 				exact: false,
    // 				component: EcommerceOrderList
    // 			},
    // 			{
    // 				name: 'Order Details',
    // 				layout: '/admin',
    // 				path: '/main/ecommerce/order-details',
    // 				exact: false,
    // 				component: EcommerceOrderDetails
    // 			},
    // 			{
    // 				name: 'Referrals',
    // 				layout: '/admin',
    // 				path: '/main/ecommerce/referrals',
    // 				exact: false,
    // 				component: EcommerceReferrals
    // 			}
    // 		]
    // 	},
    // 	{
    // 		name: 'Users',
    // 		path: '/main/users',
    // 		collapse: true,
    // 		items: [
    // 			{
    // 				name: 'New User',
    // 				layout: '/admin',
    // 				path: '/main/users/new-user',
    // 				exact: false,
    // 				component: UserNew
    // 			},
    // 			{
    // 				name: 'Users Overview',
    // 				layout: '/admin',
    // 				path: '/main/users/users-overview',
    // 				exact: false,
    // 				component: UsersOverview
    // 			},
    // 			{
    // 				name: 'Users Reports',
    // 				layout: '/admin',
    // 				path: '/main/users/users-reports',
    // 				exact: false,
    // 				component: UsersReports
    // 			}
    // 		]
    // 	},
    // 	{
    // 		name: 'Applications',
    // 		path: '/main/applications',
    // 		collapse: true,
    // 		items: [
    // 			{
    // 				name: 'Kanban',
    // 				layout: '/admin',
    // 				path: '/main/applications/kanban',
    // 				exact: false,
    // 				component: ApplicationsKanban
    // 			},
    // 			{
    // 				name: 'Data Tables',
    // 				layout: '/admin',
    // 				path: '/main/applications/data-tables',
    // 				exact: false,
    // 				component: ApplicationsDataTables
    // 			},
    // 			{
    // 				name: 'Calendar',
    // 				layout: '/admin',
    // 				path: '/main/applications/calendar',
    // 				exact: false,
    // 				component: ApplicationsCalendar
    // 			}
    // 		]
    // 	},
    // 	{
    // 		name: 'Profile',
    // 		path: '/main/profile',
    // 		collapse: true,
    // 		items: [
    // 			{
    // 				name: 'Profile Overview',
    // 				layout: '/admin',
    // 				path: '/main/profile/overview',
    // 				exact: false,
    // 				component: ProfileOverview
    // 			},
    // 			{
    // 				name: 'Profile Settings',
    // 				layout: '/admin',
    // 				path: '/main/profile/settings',
    // 				exact: false,
    // 				component: ProfileSettings
    // 			},
    // 			{
    // 				name: 'News Feed',
    // 				layout: '/admin',
    // 				path: '/main/profile/newsfeed',
    // 				exact: false,
    // 				component: ProfileNewsfeed
    // 			}
    // 		]
    // 	},
    // 	{
    // 		name: 'Others',
    // 		path: '/main/others',
    // 		collapse: true,
    // 		items: [
    // 			{
    // 				name: 'Notifications',
    // 				layout: '/admin',
    // 				path: '/main/others/notifications',
    // 				exact: false,
    // 				component: OthersNotifications
    // 			},
    // 			{
    // 				name: 'Pricing',
    // 				layout: '/auth',
    // 				path: '/main/others/pricing',
    // 				exact: false,
    // 				component: OthersPricing
    // 			},
    // 			{
    // 				name: '404',
    // 				layout: '/admin',
    // 				path: '/main/others/404',
    // 				exact: false,
    // 				component: OthersError
    // 			},
    // 			{
    // 				name: 'Messages',
    // 				layout: '/admin',
    // 				path: '/main/others/messages',
    // 				exact: false,
    // 				component: Messages
    // 			}
    // 		]
    // 	}
    // ]
  },
  // --- Authentication ---
  // {
  // 	name: 'Authentication',
  // 	path: '/auth',
  // 	icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  // 	collapse: true,
  // 	items: [
  // 		// --- Sign In ---
  // 		{
  // 			name: 'Sign In',
  // 			path: '/sign-in',
  // 			collapse: true,
  // 			items: [
  // 				{
  // 					name: 'Default',
  // 					layout: '/auth',
  // 					path: '/sign-in/default',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: SignInDefault
  // 				},
  // 				{
  // 					name: 'Centered',
  // 					layout: '/auth',
  // 					path: '/sign-in/centered',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: SignInCentered
  // 				}
  // 			]
  // 		},
  // 		// --- Sign Up ---
  // 		{
  // 			name: 'Sign Up',
  // 			path: '/sign-up',
  // 			collapse: true,
  // 			items: [
  // 				{
  // 					name: 'Default',
  // 					layout: '/auth',
  // 					path: '/sign-up/default',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: SignUpDefault
  // 				},
  // 				{
  // 					name: 'Centered',
  // 					layout: '/auth',
  // 					path: '/sign-up/centered',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: SignUpCentered
  // 				}
  // 			]
  // 		},
  // 		// --- Verification ---
  // 		{
  // 			name: 'Verification',
  // 			path: '/verify',
  // 			collapse: true,
  // 			items: [
  // 				{
  // 					name: 'Default',
  // 					layout: '/auth',
  // 					path: '/verify/default',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: VerificationDefault
  // 				},
  // 				{
  // 					name: 'Centered',
  // 					layout: '/auth',
  // 					path: '/verify/centered',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: VerificationCentered
  // 				}
  // 			]
  // 		},
  // 		// --- Lock ---
  // 		{
  // 			name: 'Lock',
  // 			path: '/lock',
  // 			collapse: true,
  // 			items: [
  // 				{
  // 					name: 'Default',
  // 					layout: '/auth',
  // 					path: '/lock/default',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: LockDefault
  // 				},
  // 				{
  // 					name: 'Centered',
  // 					layout: '/auth',
  // 					path: '/lock/centered',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: LockCentered
  // 				}
  // 			]
  // 		},
  // 		// --- Forgot Password ---
  // 		{
  // 			name: 'Forgot Password',
  // 			path: '/forgot',
  // 			collapse: true,
  // 			items: [
  // 				{
  // 					name: 'Default',
  // 					layout: '/auth',
  // 					path: '/forgot/default',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: ForgotPasswordDefault
  // 				},
  // 				{
  // 					name: 'Centered',
  // 					layout: '/auth',
  // 					path: '/forgot/centered',
  // 					icon: <Icon as={MdHome} width='16px' height='16px' color='inherit' />,
  // 					component: ForgotPasswordCentered
  // 				}
  // 			]
  // 		}
  // 	]
  // }
];

export default routes;
