import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { useRef } from 'react';
import { useLanguage } from 'useLanguage'
import { useTranslation } from "react-i18next";

const listStyle = {
  color: "white",
  fontSize: "22px",
  margin: "0px 0px 10px 0px"
}

const GetPro = ({onClose}) => {
  const finalRef = useRef(null)
  useLanguage()
  const { t } = useTranslation();
  return (
    <>
    <Modal finalFocusRef={finalRef} isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="rgba(0,0,0,0.7)"
        p="50px 30px"
      >
        <ModalHeader
          color="white"
          margin="0px 0px 50px 0px"
          textAlign="center"
          fontWeight="500"
          fontSize="24px"

        
        >{t("SignupForMoreFeatures")}</ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody
          
        >
          <Box
            margin="0px 0px 50px 0px"
          >
            <ul>
              <li style={listStyle}>{t("ContinueYourConversation")}</li>
              <li style={listStyle}>{t("MoreQueriesAndTokens")}</li>
              <li style={listStyle}>{t("SyncAndAddMoreMemories")}</li>
            </ul>
          </Box>

          <Box 
            margin="0px auto"
            textAlign="center"
          >
          <Button
            bg="#4fdabc"
            color="white"
            fontWeight="400"
            p="25px 55px"
            
            rounded="4px"
            
          >
            {t("Signup")}
          </Button>
          </Box>
         
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
  )

}


export default GetPro;
