import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Register from "./Register";
import VerificationCentered from "./VerificationCentered";

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}


const FirstModal = () => {
  const configData = useContext(ConfigContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let query = useQuery()

  useEffect(() => {
    onOpen(true);
  }, []);

  


  return (
    <div className="first-modal">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          onClick={() => console.log("hi bro")}
          // backdropFilter={"blur(30px) brightness(100%) contrast(60%) invert(100%) opacity(0.4)"}
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "500px",
            base: "calc(100% - 20px)",
          }}
          maxW={"100%"}
          borderRadius={"20px"}
          boxShadow={
            "0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f"
          }
          my={{
            base: "auto",
            lg: "auto",
          }}
        >
          <ModalHeader
            textAlign={"center"}
            fontSize={"20px"}
            width={{
              md: "100%",
              base: "80%",
            }}
            margin={"auto"}
            pt="25px"
            pb="20px"
          >
            Get started for free
          </ModalHeader>

          <ModalBody pt={0} pb={0} position={"relative"}>
            {query?.get('email') ? <> <VerificationCentered /> </> :  <Register />}
          </ModalBody>

        
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FirstModal;
