import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (result.error) {

    } else {
      // Send result.paymentMethod.id to your server
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ border: '1px solid #ccd0d2', padding: '10px 12px' }}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <button type="submit" disabled={!stripe}>
        Subscribe
      </button>
    </form>
  );
};

export default CardForm;
