/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-pascal-case */

import {
  Box,
  Button,
  Icon,
  IconButton,
  SkeletonText,
  Text,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
// Assets
import axios from "axios";
import GetPro from "components/modals/GetPro";
import ChatPagePrompt2 from "components/new/ChatPagePrompt2";
import { userPrivateRequest, userPublicRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import { SessionListContext } from "contexts/SessionListContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory, useLocation } from "react-router-dom";
import { uniq } from "underscore";
import urlParser from "url";
import { getGoogleData } from "utils/GoogleSearchEngine";
import { formatReferenceText } from "utils/MessageService";
import { sidebar_width } from "variables/charts";
import MemoryList_Two from "./MemoryList_Two";
import MessageRow from "./MessageRow";
import AssistantModal from "layouts/HomeNew/Search/AssistantModal";
import { Buffer } from "buffer";
import MicV2 from "components/audio/MicV2";
import ShareModal from "components/new/ShareModal";
import GetVideoConfirmation from "components/new/GetVideoConfirmation";
import {
  FiPhone,
  FiRefreshCw,
  FiSend,
  FiShare2,
  FiSquare,
  FiUpload,
  FiX,
} from "react-icons/fi";

// import { BiSolidCloudUpload } from 'react-icons/bi';
import { ImSpinner2 } from "react-icons/im";
import { PagiContext } from "contexts/PagiContext";
import FirstModal from "../../../../../../layouts/HomeNew/Search/FirstModal";
import PushNotification from "utils/PushNotification";
import { ModalContext } from "contexts/ModalContext";
import { CiFileOn } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";
import Cookies from "js-cookie";


const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

function FileUploadButton({ onFilesSelected }) {
  // Ref for the file input
  const fileInputRef = useRef(null);

  // Function to handle file input click
  const handleIconClick = (e) => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length) {
      onFilesSelected(files);
      // Reset the file input
      e.target.value = "";
    }
  };

  return (
    <>
      <Tooltip label="Upload" fontSize="md" borderRadius="8px">
        <Button
          bg={"transparent"}
          _hover={{
            bg: "transparent",
            backgroundColor: "rgb(230, 230, 230)",
            borderRadius: "5px",
            height: "40px",
          }}
          id="_ee"
          px="0px"
          ml="10px"
          mt="-1px"
          width="40px"
          height="40px"
          onClick={handleIconClick}
          type="button"
          cursor={"pointer"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FiUpload size={"20px"} />
        </Button>
      </Tooltip>

      {/* Invisible file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
}

const FileUploadItem = ({ file, onRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      key={file.name}
      d="flex"
      gap="10px"
      p="5px 10px"
      alignItems="center"
      bg="#e4e8ec"
      w="auto"
      fontSize={"13px"}
      borderRadius="5px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <IoCloseCircleOutline onClick={() => onRemove(file.id)} />
      ) : file.status === "loading" ? (
        <ImSpinner2 className="spinner" />
      ) : (
        <CiFileOn />
      )}
      - {file.name}
      {file.status === "error" && <span> - {file.message}</span>}
    </Box>
  );
};

export default function Messages2(props) {
  const { status, onOpen, isOpen, isTutorial, name, ...rest } = props;
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  function extractSessionParameter(url) {
    const regex = /[?&]sharedSession=([^&]+)/;
    const match = url.match(regex);

    if (match && match.length > 1) {
      return match[1];
    }

    return "";
  }

  const router = useCustomHistory();
  const router2 = useHistory();
  let sharedSession = router2?.location?.search;

  const resultRef = useRef();
  const { t } = useTranslation();
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [recording, setRecording] = useState(false);
  const [initialFetch, setInitialFetch] = useState(true);
  let [result, setResult] = useState("");
  let [isSearchShow, setIsSearchShow] = useState(true);
  let [criteria, setCriteria] = useState("text");
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [pagiTaskQueue, setPagiTaskQueue] = useState([]);
  const [isPagiCall, setPagiCall] = useState(false);
  const [executingshareReward, setExecutingshareReward] = useState(false);
  const [micModal, setMicModal] = useState(false);
  const [disableIcon, setDisableIcon] = useState(false);
  const [auto, setAuto] = useState(false);
  const [orientation, setOrientation] = useState("landscape");
  const [includeCaption, setIncludeCaption] = useState("no");
  const [language, setLanguage] = useState("English");
  const [voice, setVoice] = useState("alloy");
  const [homepageModalClose, setHomepageModalClose] = useState(false);
  const [isScrollLocked, setScrollLocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileUploads, setFileUploads] = useState([]);
  const [assitModal, setAssitModal] = useState(null);

  const responseProgressRef = useRef();
  const partiallyStoppedRef = useRef();
  const sessionIdRef = useRef(null);
  const isPagiExecuting = useRef(false);
  const timeoutExecuted = useRef(false);
  const history = useCustomHistory();
  const { search } = useLocation();

  const authData = useContext(AuthContext);
  const modalContextData = useContext(ModalContext);

  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const sessionListProvider = useContext(SessionListContext);

  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";
  const {
    selectedPrompt,
    setSelectedPrompt,
    currentSessionInfo,
    setCurrentSessionInfo,
  } = sessionListProvider;
  let sessionList = sessionListProvider.records || {};
  const maxQuestionChar = config[membership]?.maxQuestionChar || 100;
  const numberOfReferenceInPrompt =
    config[membership]?.numberOfReferenceInPrompt || 3;

  let currentLanguage = window.location.pathname.split("/")[1] || "en";
  let chatPagePlaceholder = isKor
    ? config?.global?.CHAT_PAGE_CONFIG?.value?.messageBoxPlaceholderKr
    : config?.global?.CHAT_PAGE_CONFIG?.value?.messageBoxPlaceholder;
  const VIDEO_CONFIGURATION =
    configData?.config?.global?.VIDEO_CONFIGURATION?.value || {};

  let queryDataV =
    urlParser.parse(window.location.href, { parseQueryString: true }).query ||
    {};
  let queryvalueV = queryDataV.mode || "";

  const handleMicModal = useCallback(() => {
    setMicModal(true);
    setAuto(true);
  }, []);

  const handleFilesSelected = (files) => {
    const newFileUploads = Array.from(files).map((file) => ({
      id: `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      name: file.name,
      status: "loading",
      message: "",
      response: null, // Initialize response as null
    }));
    setFileUploads((prev) => [...prev, ...newFileUploads]);

    const uploadPromises = Array.from(files).map((file) => uploadFile(file));
    Promise.all(uploadPromises).then((results) => {
      setFileUploads((prevUploads) =>
        prevUploads.map((upload) => {
          const result = results.find(
            (result) => result.file.name === upload.name
          );
          return result
            ? {
              ...upload,
              status: result.success ? "success" : "error",
              message: result.message,
              response: result.response, // Add the response object here
            }
            : upload;
        })
      );
    });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await userPrivateRequest.post(
        "/assistant/file/upload",
        formData
      );
      return {
        success: true,
        message: "Upload successful",
        file,
        response: response?.data?.response, // Return the response data
      };
    } catch (error) {
      return {
        success: false,
        message: error.message || "Upload failed",
        file,
        response: null, // Return null in case of error
      };
    }
  };

  const handleRemoveFile = (fileId) => {
    setFileUploads((prevUploads) =>
      prevUploads.filter((file) => file.id !== fileId)
    );
  };

  useEffect(() => {
    if (selectedPrompt?._id) {
      setInputPlaceholder(
        recording
          ? t("Recording")
          : selectedPrompt?.placeholder
            ? selectedPrompt?.placeholder
            : chatPagePlaceholder
      );
    } else {
      setInputPlaceholder(recording ? t("Recording") : chatPagePlaceholder);
    }
  }, [selectedPrompt, chatPagePlaceholder]);

  const handleClick = () => {
    sessionChanged(null);
  };

  const executePagi = async (string) => {
    // string = Buffer.from(string, "base64").toString("ascii");
    // // console.log(string, 'string string string')
    let pagiTask = JSON.parse(string);

    const promptInfo = await userPublicRequest
      .get(`/get-prompt/${pagiTask.task.id}`)
      .then((response) => {
        return response?.data?.prompt ?? null;
      })
      .catch((error) => {
        console.error(error.message);
        return [];
      });

    isPagiExecuting.current = true;
    setSelectedPrompt({ ...promptInfo, pagiTask });
    if (
      promptInfo?.allowVideoCreate === "BOTH" ||
      promptInfo?.allowVideoCreate === "SHORTGPT"
    ) {
      timeoutExecuted.current = true;
    }
  };

  useEffect(() => {
    if (
      Object.keys(selectedPrompt).length > 0 &&
      selectedPrompt?.pagiTask?.task?.prompt &&
      isPagiExecuting.current
    ) {
      let pagiTask = selectedPrompt?.pagiTask;
      sessionChanged(null);
      sessionIdRef.current = null;
      setQuestion(pagiTask?.task?.prompt);
      submitHandler(null, pagiTask?.task?.prompt, "text", true);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    let queryData =
      urlParser.parse(window.location.href, { parseQueryString: true }).query ||
      {};
    let queryvalue = queryData.query || "";
    let newSession = queryData.newSession || "";
    if (queryData?.reference) {
      fetchInfoBaseOnReference(queryData?.reference);
    }

    if (queryData?.sharedSession) {
      setShowModal(false);
      sessionChanged(queryData?.sharedSession);
      history.replace("/chat");
    }
    if (Object.keys(config).length > 0 && queryvalue) {
      if (newSession && newSession == "yes") {
        sessionChanged(null);
        sessionIdRef.current = null;
        setQuestion(queryvalue);
        submitHandler(null, queryvalue, "text", true);
        history.replace("/chat");
        queryvalue = null;
      } else {
        setQuestion(queryvalue);
        submitHandler(null, queryvalue);
        history.replace("/chat");

        queryvalue = null;
        // window.history.replaceState({}, document.title, '/chat')
      }
    } else if (
      Object.keys(config).length > 0 &&
      queryData?.agiExecution &&
      sessionListProvider.isFetched
    ) {
      executePagi(queryData?.agiExecution);
      setPagiCall(true);
      history.replace("/chat");
    }
  }, [config, search, sessionListProvider]);

  useEffect(() => {
    const cacheData = JSON.parse(localStorage.getItem("guestUserSession"));
    let currentTime = new Date();
    if (
      cacheData &&
      new Date(cacheData.expire).getTime() > currentTime.getTime()
    ) {
      sessionIdRef.current = cacheData.sessionId;
      fetchInfo(cacheData?.sessionId);
    } else {
      localStorage.removeItem("guestUserSession");
    }
  }, []);

  // Session Related Events
  const checkNewMemoryCompatibility = () => {
    let status = false;
    let limit = config[membership]?.maxMemoryLimit;

    let memoryCount = Object.keys(sessionList).length;
    if (limit > memoryCount) {
      status = true;
    }
    return status;
  };

  /**
   * PRO account modal
   * @param {*} _id
   * @author Emran
   * @date 30 March 2023
   */
  const [getPro, setGetPro] = useState(false);
  const closeProModal = () => {
    setGetPro(false);
  };

  const getMyIp = async () => {
    let ip = await axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        return response?.data?.ip || "";
      })
      .catch((err) => {
        console.error("Problem fetching my IP", err);
        return null;
      });
    return ip;
  };

  const executeShareReward = async (sessionId) => {
    if (executingshareReward) {
      return false;
    }
    setExecutingshareReward(true);
    let ip = await getMyIp();

    let payload = {
      ip,
      sessionId,
      source: "chat",
    };
    if (ip && sessionId) {
      let encodedString = new Buffer(JSON.stringify(payload)).toString(
        "base64"
      );
      await userPrivateRequest
        .post("/share/execute", {
          payload: encodedString,
        })
        .then((response) => {
          authData.profileFetch();
        })
        .catch((err) => { });
    } else {
      // // console.log('Reward data not updated')
      // // console.log(payload)
    }
  };

  const sessionChanged = (_id) => {
    if (!_id && !checkNewMemoryCompatibility()) {
      setTimeout(() => {
        router.push("/auth/sign-in");
      }, 1000);
      return;
    }
    if (queryvalueV === "voice") {
      history.push(`/chat?sharedSession=${_id}`);
      return;
    }
    sessionIdRef.current = _id;
    fetchInfo(_id);
  };

  const fetchInfo = async (sessionId) => {
    setInitialFetch(false);
    if (!sessionId) {
      setMessages([]);
      setInitialFetch(true);
      return;
    }
    let queryData =
      urlParser.parse(window.location.href, { parseQueryString: true }).query ||
      {};
    if (queryData?.sharedSession) {
      executeShareReward(queryData?.sharedSession);
    }

    try {
      const chatHistory = await userPrivateRequest.get(
        `/guest-chat/history?sessionId=${sessionId}`
      );
      setCurrentSessionInfo(chatHistory.data.session);
      if (chatHistory?.data?.session?.selectedPrompt) {
        setSelectedPrompt(chatHistory?.data?.session?.selectedPrompt);
      } else {
        setSelectedPrompt({});
      }
      let userMessages = chatHistory.data.records.map((record) => {
        return {
          id: record._id,
          criteria: record.criteria,
          human: {
            text: record.prompt,
            time: record.createdAt,
          },
          ai: {
            text: record.answer,
            time: record.updatedAt,
            fullLoaded: true,
            video: record?.video,
            files: record.files,
          },
          searchResult: {
            ...record?.searchEngineData,
            organic: record?.searchEngineData?.organic
              ? record.searchEngineData.organic
              : [],
            images: record?.searchEngineData?.images
              ? record?.searchEngineData?.images
              : [],
            loading: false,
          },
          user: record.user,
          includeGoogleData: record.includeGoogleData,
        };
      });
      setMessages(userMessages);
      setInitialFetch(true);
      sessionListProvider.fetchSession();
      // // console.log("userMessages", userMessages);
    } catch (err) { }
  };

  const fetchInfoBaseOnReference = async (sessionId) => {
    setInitialFetch(false);
    try {
      const chatHistory = await userPublicRequest.get(
        `/guest-chat/information/${sessionId}`
      );
      const record = chatHistory?.data?.chat;

      let userMessages = [
        {
          id: record._id,
          criteria: record.criteria,
          human: {
            text: record.prompt,
            time: record.createdAt,
          },
          ai: {
            text: record.answer,
            time: record.updatedAt,
            fullLoaded: true,
            video: record?.video,
          },
          searchResult: {
            ...record.searchEngineData,
            organic: record.searchEngineData.organic
              ? record.searchEngineData.organic
              : [],
            images: record.searchEngineData.images
              ? record.searchEngineData.images
              : [],
            loading: false,
          },
          includeGoogleData: record.includeGoogleData,
          user: record.user,
        },
      ];
      setMessages(userMessages);
      storeChatToBackend(
        record.prompt,
        record.answer,
        record?.searchEngineData,
        "text"
      );
      setInitialFetch(true);
      history.replace("/chat");
    } catch (err) {
      setInitialFetch(true);
      history.replace("/chat");
    }
  };



  function removeHTMLTagsAndReferences(str) {
    // Remove HTML tags
    let result = str?.replace(/<\/?[^>]+(>|$)/g, "");

    // Remove markdown syntax
    result = result?.replace(/([_*~`])/g, "");

    // Remove square brackets with numbers
    result = result?.replace(/\[\d+\]/g, "");

    return result;
  }
  const generateOneClickVideo = async (
    orientation,
    language,
    voice,
    messagesU,
    sessionId
  ) => {
    const uniqueId = generateUniqueId();
    let userMessages = [...messages];

    if (messagesU) {
      userMessages = [...messagesU];
    }
    const withoutVideo = userMessages.filter((um) => um?.criteria !== "video");
    const lastMessage = withoutVideo[withoutVideo.length - 1];
    userMessages.push({
      id: uniqueId,
      criteria: "video",
      human: {
        text: `Video of ${lastMessage?.human?.text}`,
        time: Date.now(),
      },
      ai: {
        text: "",
        load: false,
        fullLoaded: true,
        video: {
          _id: uniqueId,
        },
      },
      searchResult: {
        loading: true,
        data: [],
      },
      includeGoogleData: "no",
    });
    setMessages(userMessages);
    let videoContent = lastMessage?.ai?.text;
    let plainString = removeHTMLTagsAndReferences(videoContent);
    let first30Chars = plainString.substring(
      0,
      orientation
        ? Number(VIDEO_CONFIGURATION?.shortGptCharacterLimitInVideoCreation)
        : Number(
          config?.global?.ELAI_GENERAL_CONFIG?.value?.speechCharacterLimit
        )
    );
    let video = {};

    if (!orientation) {
      video = await userPrivateRequest.post("/create-video", {
        videoName: lastMessage?.human?.text.substring(0, 30),
        speech: first30Chars,
      });
    }
    let videoShortGpt = {};
    if (orientation) {
      videoShortGpt = await userPrivateRequest.post(
        "/text_to_video/generate_video",
        {
          includeCaption,
          orientation,
          script: first30Chars,
          language,
          voice,
          voice_module: "elevenLabs",
        }
      );
    }

    let findMessage = userMessages.find((um) => um?.id === uniqueId);
    let messageI = userMessages.findIndex((um) => um?.id === uniqueId);
    findMessage = {
      ...findMessage,
      ai: {
        text: "",
        load: false,
        fullLoaded: true,
        video: orientation
          ? { ...videoShortGpt?.data?.data, type: "shortGpt" }
          : { ...video?.data?.createVideo, type: "elai" },
      },
    };
    userMessages[messageI] = findMessage;
    setMessages(userMessages);

    storeChatToBackend(
      lastMessage?.human?.text,
      "",
      {},
      "video",
      "no",
      "",
      orientation
        ? { ...videoShortGpt?.data?.data, type: "shortGpt" }
        : { ...video?.data?.createVideo, type: "elai" },
      "yes",
      sessionId ? sessionId : null
    ).then((chat) => {
      findMessage.id = chat._id;
      const userMessagess = [...userMessages];
      userMessagess[messageI] = findMessage;
      setMessages(userMessagess);
      // setCreateAutoVideo(false);
      timeoutExecuted.current = false;
    });
  };

  const continueMessage = async () => {
    setQuestion("Continue");
    submitHandler(null, "Continue");
  };

  const promptGeneration = useCallback(
    (prompt) => {
      if (prompt?._id === selectedPrompt?._id) {
        setSelectedPrompt({});
        return;
      }
      setSelectedPrompt(prompt);
    },
    [selectedPrompt]
  );

  const deleteChatToBackend = async (_id) => {
    return userPublicRequest
      .post("/guest-chat/delete/" + _id, {})
      .then((response) => {
        return response.data;
      });
  };

  const storeChatToBackend = async (
    prompt,
    answer,
    searchEngineData,
    criteria,
    includeGoogleData = "yes",
    costableString = "",
    video = {},
    isDiscovered = "yes",
    sessionId = null,
    openAiThreadId = ""
  ) => {
    return userPrivateRequest
      .post("/guest-chat/store", {
        prompt,
        answer,
        searchEngineData,
        ...(sessionIdRef.current && { session: sessionIdRef.current }),
        ...(criteria && { criteria }),
        ...(includeGoogleData && { includeGoogleData }),
        ...(video && { video }),
        costableString,
        isDiscovered,
        ...(selectedPrompt && { selectedPrompt }),
        ...(sessionId && { session: sessionId }),
        ...(openAiThreadId && { openAiThreadId: openAiThreadId }),
      })
      .then((response) => {
        let derivedSessionId = response.data.chat.session._id || null;
        let chatId = response.data.chat._id || null;
        // Store and modify session id
        if (!sessionIdRef.current && derivedSessionId) {
          sessionListProvider.addNewSession(
            derivedSessionId,
            prompt,
            isDiscovered
          );
          sessionIdRef.current = derivedSessionId;
          setCurrentSessionInfo(response.data.chat.session);
        }
        authData.profileFetch();
        return response.data.chat;
      });
  };

  useEffect(() => {
    let interval;

    if (isScrollLocked) {
      interval = setInterval(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup on component unmount or when the effect runs again
  }, [isScrollLocked]);

  function parseContent(content) {
    try {
      // Try to parse the content as JSON
      const parsed = JSON.parse(content);
      // console.log(parsed, 'Parsed as JSON');
      return true;
    } catch (e) {
      // If parsing fails, it's likely a normal string
      console.log(e.message, "Content is a normal string", 830);
      if (e.message.includes("Unterminated string in JSON")) {
        return "UNPARSED";
      }
      return false;
    }
  }
  let handleSubmitPromptBtnClicked = async (
    prompt,
    { userMessagess, findMessage, findIndex, text, chatId, responseType },
    finalMessage,
    googleResponse,
    isNotStore = false
  ) => {
    console.log(prompt, findMessage?.human?.text, "901901901");
    let model = config[membership].model;
    let temperature = config[membership].aiTemperature;
    let top_p = config[membership].topP;
    let max_tokens = config[membership].maxToken;
    let frequency_penalty = config[membership].frequencyPenalty;
    let presence_penalty = config[membership].presencePenalty;

    if (selectedPrompt) {
      if (selectedPrompt?.model) {
        model = selectedPrompt?.model;
      }
      if (selectedPrompt?.aiTemperature !== undefined) {
        temperature = selectedPrompt?.aiTemperature;
      }
      if (selectedPrompt?.topP) {
        top_p = selectedPrompt?.topP;
      }
      if (selectedPrompt?.maxToken) {
        max_tokens = selectedPrompt?.maxToken;
      }
      if (selectedPrompt?.frequencyPenalty !== undefined) {
        frequency_penalty = selectedPrompt.frequencyPenalty;
      }
      if (selectedPrompt?.presencePenalty !== undefined) {
        presence_penalty = selectedPrompt.presencePenalty;
      }
    }

    let promptContentInText = prompt
      .map((i) => {
        return i.content;
      })
      .join(" ");

    let references = [];
    let uSelectedPropmt = {
      ...selectedPrompt,
    };
    if (!uSelectedPropmt._id) {
      uSelectedPropmt = {
        ...assitModal,
      };
    }
    if (
      prompt !== "" &&
      uSelectedPropmt?.assistant?.assistantId &&
      uSelectedPropmt?.promptType === "assistant"
    ) {
      const fileIds = fileUploads.map((fu) => {
        return fu?.response?.id;
      });

      console.log(currentSessionInfo, 'currentSessionInfo')
      const payload = {
        thread_id: currentSessionInfo?.openAiThreadId
          ? currentSessionInfo?.openAiThreadId
          : "",
        assistant_id: uSelectedPropmt?.assistant?.assistantId,
        message: findMessage?.human?.text,
        file_ids: fileIds.length > 0 ? fileIds : [],
        selectedPrompt: uSelectedPropmt,
        // includeTts: "yes",
        ...(assitModal?._id && { includeTts: "yes" }),
        ...(isNotStore && { ignoreSessionCreation: "yes" }),
      };

      setFileUploads([]);

      let url =
        process.env.REACT_APP_API_URL + `/assistant/message/add-run/streaming`;
      const token = Cookies.get("token");
      let fullObj = [];
      // Initiating the stream with a POST request
      // console.log(token, url, currentSessionInfo, 'Assistant Api')
      let unParsedString=""
      fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(async ({ done, value }) => {
                  // console.log("Assistant Api backend one", done, value);
                  if (done) {
                    controller.close();
                    // console.log("Assistant Api backend done")
                    if (partiallyStoppedRef.current) {
                      findMessage.includeGoogleData = "no";
                    }
                    findMessage.ai.fullLoaded = true;
                    const userMessages = [...userMessagess];
                    userMessages[findIndex] = findMessage;
                    setMessages(userMessages);
                    let costableString = "";
                    setssLoading(false);
                    setDisableIcon(false);
                    setScrollLocked(false);
                    const parseValue = JSON.parse(resultRef.current);
                    // console.log("Assistant Api backend done 2", parseValue)
                    const sessionRefr = parseValue.find(
                      (pv) => pv?.type === "final_response"
                    )?.session;
                    if (!sessionIdRef.current && !isNotStore) {
                      sessionListProvider.addNewSession(
                        sessionRefr?._id,
                        findMessage?.human?.text,
                        false
                      );
                      sessionIdRef.current = sessionRefr?._id;
                      setCurrentSessionInfo(sessionRefr);
                    }
                    console.log(
                      findMessage,
                      "findMessagefindMessagefindMessage"
                    );
                    setCurrentMessage(findMessage);

                    if (!isNotStore) {
                      await storeChatToBackend(
                        finalMessage,
                        resultRef.current,
                        { ...googleResponse, usedReferences: references },
                        responseType,
                        findMessage.includeGoogleData,
                        costableString,
                        {},
                        isPagiExecuting.current && pagiTaskQueue.length != 1
                          ? "no"
                          : "yes",
                        sessionIdRef.current,
                        parseValue?.thread_id
                      ).then((chat) => {
                        findMessage.id = chat._id;
                        const userMessages = [...userMessagess];
                        userMessages[findIndex] = findMessage;
                        setMessages(userMessages);
                        if (timeoutExecuted.current) {
                          generateOneClickVideo(
                            orientation,
                            language,
                            voice,
                            userMessages,
                            chat?.session?._id
                          );
                        }
                        responseProgressRef.current = false;
                      });
                    }

                    if (isPagiExecuting.current) {
                      let task = selectedPrompt?.pagiTask?.task ?? {};
                      let executionId = selectedPrompt?.pagiTask?.id ?? null;

                      PushNotification(
                        executionId,
                        `PagiUpdate-${executionId}`,
                        {
                          id: executionId,
                          data: { ...task, status: "confirmed" },
                        }
                      );

                      isPagiExecuting.current = false;
                      sessionIdRef.current = null;
                    }
                    return;
                  } else {
                    try {
                      controller.enqueue(value);

                      let res = new TextDecoder().decode(value);
                      let pRes = {};

                      if (res && parseContent(res) == "UNPARSED") {
                        unParsedString = res;
                      } else if (
                        res &&
                        parseContent(unParsedString.concat(res))
                      ) {
                        console.log(
                          "Combined feed",
                          unParsedString.concat(res)
                        );
                        pRes = JSON.parse(unParsedString.concat(res));
                        unParsedString = "";

                        console.log("pRes: ", pRes);

                        // if (res && parseContent(res)) {
                        // pRes = JSON.parse(res);

                        if (responseProgressRef.current) {
                          const fRef = fullObj.find(
                            (fo) => fo?.refId === pRes?.refId
                          );
                          const fiRef = fullObj.findIndex(
                            (fo) => fo?.refId === pRes?.refId
                          );
                          if (fRef) {
                            fullObj[fiRef] = pRes;
                          } else {
                            fullObj.push(pRes);
                          }

                          resultRef.current = JSON.stringify(fullObj) || "";
                          // // console.log("Assistant Api", JSON.parse(resultRef.current));
                          findMessage.ai.text = resultRef.current;
                          findMessage.ai.load = false;
                          findMessage.ai.time = Date.now();
                          findMessage.ai.chatId = chatId;
                          // findMessage.searchResult.usedReferences = references;
                          const userMessages = [...userMessagess];
                          userMessages[findIndex] = findMessage;
                          setMessages(userMessages);
                        }
                      }

                      // if(!res || parseContent(res)) {
                      //   return
                      // }
                      // console.log("Assistant Api backend else three", pRes);
                    } catch (err) {
                      // console.log("Assistant Api backend err", err);
                    }

                    return pump();
                  }
                });
              }
            },
          });
        })
        .catch((err) => console.error("Stream failed:", err));
      return;
    }

    if (prompt !== "") {
      setResult("");
      // console.log(prompt, 'userMessages')
      let url = `${process.env.REACT_APP_API_URL}/openai/completion`;
      let data = {
        model,
        messages: prompt,
        temperature,
        top_p,
        max_tokens,
        stream: true,
        n: 1,
        frequency_penalty,
        presence_penalty,
      };

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify(data),
      }).then(async (response) => {
        // setSelectedPrompt({});
        setScrollLocked(true);
        const r = response.body;
        if (!r) {
          return;
        }
        const d = new TextDecoder("utf8");
        const reader = await r.getReader();
        let fullText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            if (partiallyStoppedRef.current) {
              findMessage.includeGoogleData = "no";
            }
            findMessage.ai.fullLoaded = true;
            const userMessages = [...userMessagess];
            userMessages[findIndex] = findMessage;
            setMessages(userMessages);
            let costableString = promptContentInText + fullText;
            setssLoading(false);
            setDisableIcon(false);
            setScrollLocked(false);
            setCurrentMessage(findMessage);
            if (!isNotStore) {
              await storeChatToBackend(
                finalMessage,
                resultRef.current,
                { ...googleResponse, usedReferences: references },
                responseType,
                findMessage.includeGoogleData,
                costableString,
                {},
                isPagiExecuting.current && pagiTaskQueue.length != 1
                  ? "no"
                  : "yes"
              ).then((chat) => {
                findMessage.id = chat._id;
                const userMessages = [...userMessagess];
                userMessages[findIndex] = findMessage;
                setMessages(userMessages);
                if (timeoutExecuted.current) {
                  generateOneClickVideo(
                    orientation,
                    language,
                    voice,
                    userMessages,
                    chat?.session?._id
                  );
                }
              });
            }

            if (isPagiExecuting.current) {
              let task = selectedPrompt?.pagiTask?.task ?? {};
              let executionId = selectedPrompt?.pagiTask?.id ?? null;

              PushNotification(executionId, `PagiUpdate-${executionId}`, {
                id: executionId,
                data: { ...task, status: "confirmed" },
              });

              isPagiExecuting.current = false;
              sessionIdRef.current = null;
            }
            break;
          } else {
            const decodedString = d.decode(value);
            // console.log(" assistant value: " + decodedString);

            fullText += decodedString;

            if (responseProgressRef.current) {
              let formatReference = formatReferenceText(
                fullText,
                googleResponse.organic,
                responseType
              );
              let existing = findMessage.searchResult.usedReferences || [];
              references = uniq([
                ...uniq(existing),
                ...formatReference.references,
              ]);
              resultRef.current = formatReference.text || "";
              findMessage.ai.text = resultRef.current;
              findMessage.ai.load = false;
              findMessage.ai.time = Date.now();
              findMessage.ai.chatId = chatId;
              findMessage.searchResult.usedReferences = references;
              const userMessages = [...userMessagess];
              userMessages[findIndex] = findMessage;
              setMessages(userMessages);
            }
          }
        }
      });
    } else {
      alert("Please insert a prompt!");
    }
  };

  function generateUniqueId() {
    const timestamp = Date.now();
    const randomNumber = Math.random();
    const hexadecimalString = randomNumber.toString(16);

    return `id-${timestamp}-${hexadecimalString}`;
  }

  const searchGoogle = async (query, userMessages) => {
    let translationLanguage = config.translationLanguage || "en";
    let languageName = "English";
    if (translationLanguage === "ko") {
      languageName = "Korean";
    }
    if (userMessages.length > 1) {
      let historyPromptData = [];
      historyPromptData.push({
        role: "system",
        content: `The user will provide a set of google search keywords. As the user give you more things to search,  Simplify the google search results based on the context. You have to give a good streamlined google search terms that will express the core idea of the user's request. Add the search terms together and come up with a good google search terms that would best express the intent of the user. Always use ONLY JSON format as a response. DO NOT DEVIATE FROM THE TOPIC. Include the original context in every new search term generated.\n"""\nExample:\nUser: Tell me about the origins of the bible.\n User: Tell me more about this.\nAssistant: {"search_terms": "details about the origins of the bible}\nUser: hmm....I need to know about the history. \nAssistant: {"search_terms": "details about the history of the bible}\n """\n Always RESPOND in ${languageName} Language.`,
      });

      userMessages.forEach((i, index) => {
        historyPromptData.push({ role: "user", content: i.human.text });
        if (i.searchResult?.googleSearchQuery && index > 0) {
          historyPromptData.push({
            role: "assistant",
            content: JSON.stringify({
              search_terms: i.searchResult?.googleSearchQuery,
            }),
          });
        }
      });
      query = await userPrivateRequest
        .post("openai/completion", {
          model: "gpt-3.5-turbo",
          messages: historyPromptData,
          temperature: 0.2,
        })
        .then((response) => {
          // // console.log("search response AI",response.data)
          let simplified = response.data.choices[0]?.message?.content ?? "";

          if (simplified) {
            simplified = JSON.parse(simplified)?.search_terms;
          }
          return simplified;
        })
        .catch((error) => {
          // console.log("search error", error.message);
          return query;
        });
    }
    let limit = config[membership].searchEngineResultLimit || 10;
    let response = await getGoogleData(query, limit);
    return { ...response, googleSearchQuery: query };
  };

  const [ssLoading, setssLoading] = useState(false);

  const submitHandler = async (
    e,
    messaget,
    responseType = "text",
    isNew = false,
    includeGoogleData = "yes",
    isNotStore = false
  ) => {
    setssLoading(true);
    setCriteria(responseType);
    let isAuthenticated = authData.authState.isAuthenticated || false;
    if (e) {
      e.preventDefault();
      if (!isAuthenticated) {
        router.push("/auth/sign-in");
      }
    }

    if (!messaget && !question) return;
    responseProgressRef.current = true;
    partiallyStoppedRef.current = false;

    let queryLimit = config[membership]?.queriesInMemory;

    if (!sessionIdRef.current && !checkNewMemoryCompatibility()) {
      // history.push('/auth/sign-up')

      // router.push("/auth/sign-in");
      setTimeout(() => {
        router.push("/auth/sign-in");
      }, 1000);

      // setGetPro(true);
      // toast.error('Sign up for PRO to unlock more memories.')
      return;
    } else if (sessionIdRef.current && queryLimit <= messages.length) {
      // setGetPro(true);
      setTimeout(() => {
        router.push("/auth/sign-in");
      }, 1000);
      // toast.error('Sign up for PRO to extend query limit in chat.')
      return;
    }
    let finalMessage = question;
    if (messaget) {
      finalMessage = messaget;
    }

    if (selectedPrompt?.assistant?.assistantId && fileUploads.length > 0) {
      finalMessage = JSON.stringify({
        message: finalMessage,
        files: fileUploads,
      });
    }

    if (selectedPrompt?.includeWebSearchInPrompt == "no") {
      includeGoogleData = "no";
    } else if (currentSessionInfo?.includeGoogleData == "no") {
      includeGoogleData = "no";
    } else if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage?.includeGoogleData == "no") {
        includeGoogleData = "no";
      }
    }

    const creditBalance = authData.authState.user.creditBalance;
    // // console.log(creditBalance, 'creditBalance')
    if (membership == "pro" && creditBalance <= 0) {
      toast.error("Insufficient " + creditLabel);
      router.push("/profile");

      return;
    }

    const uniqueId = generateUniqueId();
    let userMessages = messages;
    if (isNew) {
      userMessages = [
        {
          id: uniqueId,
          criteria: responseType,
          human: {
            text: finalMessage,
            time: Date.now(),
          },
          ai: {
            text: "",
            load: true,
            fullLoaded: false,
          },
          searchResult: {
            loading: true,
            data: [],
          },
          includeGoogleData,
          user: authData.authState.user,
        },
      ];
    } else {
      userMessages.push({
        id: uniqueId,
        criteria: responseType,
        human: {
          text: finalMessage,
          time: Date.now(),
        },
        ai: {
          text: "",
          load: true,
          fullLoaded: false,
        },
        searchResult: {
          loading: true,
          data: [],
        },
        includeGoogleData,
      });
    }
    setQuestion("");
    setMessages(userMessages);

    const findIndex = userMessages.length - 1;
    const findMessage = userMessages[findIndex];
    findMessage.ai.load = true;
    userMessages[findIndex] = findMessage;
    console.log(userMessages, findMessage, 'userMessagesuserMessages')
    let translationLanguage = config.translationLanguage || "en";
    let languageName = "English";
    if (translationLanguage === "ko") {
      languageName = "Korean";
    }

    // // console.log('currentSessionInfo', currentSessionInfo)

    try {
      let defaultInitialPrompt = config[membership].initialPrompt;
      if (currentSessionInfo?.flag == "definedPrompt") {
        defaultInitialPrompt = currentSessionInfo?.initialPrompt;
      }

      let finalPrompt = config[membership]?.finalPrompt || "";
      if (currentSessionInfo?.flag == "definedPrompt") {
        finalPrompt = currentSessionInfo?.finalPrompt;
      }

      // use defined first and final prompt for admin generated prompts
      if (Object.keys(selectedPrompt).length) {
        defaultInitialPrompt = selectedPrompt.initialPrompt || "";
        finalPrompt = selectedPrompt.finalPrompt || "";
      }

      // if(selectedPrompt?.assistant?.assistantId) {

      //   excuteAssistant()
      //   return
      // }

      let googlePromptData = "";
      let googleResponse = {};
      if (includeGoogleData === "yes") {
        googleResponse = await searchGoogle(finalMessage, userMessages);
        let searchEngineResultLimit =
          config[membership].searchEngineResultLimit || 10;
        const searchData = googleResponse.organic.slice(
          0,
          searchEngineResultLimit
        );
        findMessage.searchResult = {
          ...googleResponse,
          includeGoogleData,
          loading: false,
        };
        userMessages[findIndex] = findMessage;
        setMessages(userMessages);

        let arrayData = searchData
          .slice(0, numberOfReferenceInPrompt)
          .map((i, index) => {
            return `{web reference number:${index + 1},content: "${i.snippet
              }",link:"${i.link}"}`;
            // return `^^${index + 1}^^: ${i.title} \n ${i.link} \n ${i.snippet}`;
          });
        googlePromptData = arrayData.join(" ");
      }

      defaultInitialPrompt = defaultInitialPrompt
        .replaceAll("[TARGET_LANGUAGE]", languageName)
        .replaceAll("[WEB_SEARCH_RESULT]", googlePromptData);
      // .replaceAll("\n", "");

      finalPrompt = finalPrompt
        .replaceAll("[TARGET_LANGUAGE]", languageName)
        .replaceAll("[WEB_SEARCH_RESULT]", googlePromptData);
      // .replaceAll("\n", "");

      // Building array of messages

      let history = [];
      if (defaultInitialPrompt.replace(/[^\p{L}]/gu, "")) {
        history.push({
          role: "system",
          content: defaultInitialPrompt,
        });
      }
      let firstQuestionInPrompt = parseInt(
        config[membership].firstQuestionInPrompt
      );
      let latestQuestionInPrompt = parseInt(
        config[membership].latestQuestionInPrompt
      );
      let firstResponseInPrompt = parseInt(
        config[membership].firstResponseInPrompt
      );
      let latestResponseInPrompt = parseInt(
        config[membership].latestResponseInPrompt
      );
      let arraySize = userMessages.length;
      console.log(finalPrompt, "userMessages");
      // return;
      console.log("Before Question Text", finalPrompt);
      userMessages.map((i, index) => {
        // Question block
        let questionText = i.human.text;
        if (index == arraySize - 1) {
          // Question block
          // let inputText = i.human.text;
          if (index === arraySize - 1) {
            if (selectedPrompt?.prompt) {
              let inputText = selectedPrompt?.prompt;
              console.log(inputText, "userMessages");
              questionText = inputText
                .replaceAll("[TOPIC]", i.human.text)
                .replaceAll("[TARGET_LANGUAGE]", languageName)
                .replaceAll("[WEB_SEARCH_RESULT]", googlePromptData);
              console.log(questionText, "userMessages");
              // .replaceAll("\n", "");

              // if (finalPrompt.replace(/[^\p{L}]/gu, "")) {
              //   questionText += `${inputText} \n ULTIMATE INSTRUCTIONS: ${finalPrompt}`;
              // }
            } else {

              if (finalPrompt.includes("[TOPIC]")) {
                questionText = `ULTIMATE INSTRUCTIONS: ${finalPrompt.replaceAll(
                  "[TOPIC]",
                  questionText
                )}`;
              } else {
                questionText = `${questionText}. ULTIMATE INSTRUCTIONS: ${finalPrompt}`;
              }
              // if (finalPrompt.replace(/[^\p{L}]/gu, "")) {
              //   questionText = `ULTIMATE INSTRUCTIONS: ${finalPrompt}`;
              // } else {
              //   console.log("End Question Block", finalPrompt, questionText);
              //   questionText = questionText.replaceAll("[TOPIC]", questionText);
              // }
              // .replaceAll("\n", "");
            }
          }
        }

        if (firstQuestionInPrompt > index) {
          history.push({ role: "user", content: questionText });
        } else if (userMessages.length - latestQuestionInPrompt - 2 < index) {
          history.push({ role: "user", content: questionText });
        }
        // End Question Block

        // Answer Block
        if (i.ai.text) {
          let formattedAnswer = i.ai.text
            .replace(/<[^>]+>/g, "")
            .replace(/\[(.*?)\]/g, "");
          // .slice(0, 100);

          if (firstResponseInPrompt > index) {
            history.push({ role: "assistant", content: formattedAnswer });
          } else if (userMessages.length - latestResponseInPrompt - 2 < index) {
            history.push({ role: "assistant", content: formattedAnswer });
          }
        }
        // end Answer block
        return null;
      });
      // return;
      console.log(
        history,
        {
          userMessagess: userMessages,
          findMessage: findMessage,
          findIndex: findIndex,
          text: "parsedData",
          chatId: uniqueId,
          responseType,
        },
        finalMessage,
        googleResponse,
        "userMessages"
      );
      handleSubmitPromptBtnClicked(
        history,
        {
          userMessagess: userMessages,
          findMessage: findMessage,
          findIndex: findIndex,
          text: "parsedData",
          chatId: uniqueId,
          responseType,
        },
        finalMessage,
        googleResponse,
        isNotStore
      );
    } catch (err) {
      findMessage.ai.text = isKor
        ? config.global.EXCEPTION_MESSAGE_KR.value
        : config.global.EXCEPTION_MESSAGE.value || "";
      findMessage.ai.load = false;
      findMessage.ai.time = Date.now();
      userMessages[findIndex] = findMessage;

      setMessages([]);
      setMessages(userMessages);
    }
    return;
  };
  const handleStopResponse = () => {
    setssLoading(false);
    setDisableIcon(true);
    responseProgressRef.current = false;
    partiallyStoppedRef.current = true;
  };

  const handleShare = () => {
    if (messages.length <= 0) {
      toast("No message found.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    onShareModalOpen();
  };

  const handleRegenerate = () => {
    let newData = messages;
    if (messages.length <= 0) {
      toast("No message found.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    let index = messages.length - 1;
    let lastMessage = messages[index];
    let removed = newData.splice(-1);
    setMessages(newData);
    let queryvalue = lastMessage.human.text || "";
    deleteChatToBackend(lastMessage.id);
    setQuestion(queryvalue);
    submitHandler(
      null,
      queryvalue,
      lastMessage.criteria,
      false,
      lastMessage.includeGoogleData
    );
    // alert(lastMessage.criteria)
  };
  const audioTextBack = (text) => {
    submitHandler(null, text);
  };

  const updateMessage = async (id, video) => {
    let userMessages = [...messages];
    let findMessage = userMessages.find((um) => um?.id === id);
    let messageI = userMessages.findIndex((um) => um?.id === id);
    findMessage = {
      ...findMessage,
      ai: {
        text: "",
        load: false,
        fullLoaded: true,
        video: video,
      },
    };
    userMessages[messageI] = findMessage;
    setMessages(userMessages);
    userPublicRequest.post("/guest-chat/update", {
      _id: id,
      video,
    });
  };

  const CommonForm = (question) => {
    //* Multiline Opener
    const [isMultilined, setIsMultilined] = useState(false);
    const [textareaHeight, setTextAreaHeight] = useState("");
    const textareaEnterHandler = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        checkIfPagi(e);
      }
    };

    //* Find status of fullLoaded of Last answer of a Conversation
    const checkIsLast = () => {
      if (messages?.length) {
        return messages[messages?.length - 1]?.ai?.fullLoaded;
      } else {
        return false;
      }
    };

    const textareaRef = useRef(null);

    useEffect(() => {
      textareaRef.current.style.height = "46px";
      let scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight === 0) {
        scrollHeight = 46;
      }
      console.log(scrollHeight, "scrollHeight");
      textareaRef.current.style.height = scrollHeight + "px";
    }, [question, messages]);


    const checkIfPagi = (e) => {
      if (Object.keys(selectedPrompt).length > 0 || sessionIdRef.current) {
        submitHandler(e);
      } else {
        if (membership === "free") {

          modalContextData.setCurrentModalName("moduleEligibilityException");
          return;
        }
        setMicModal(true);
        setRecording(false);
      }
    };
    const isObjectEmpty = (obj) => obj && Object.keys(obj).length === 0;

    useEffect(() => {
      let queryData =
        urlParser.parse(window.location.href, { parseQueryString: true })
          .query || {};
      if (messages.length > 0 || isTutorial) {
        setShowModal(false);
      }
      if (messages.length < 1 && !isTutorial) {
        console.log(!isPagiCall && !queryData.sharedSession, "SHOWMODAL");
        if (!isPagiCall && !queryData.sharedSession && !sessionIdRef.current) {
          setShowModal(true);
        } else {
          setShowModal(false);
        }
      }
    }, [messages, isTutorial, isPagiCall]);

    console.log(showModal, "SHOWMODAL");

    const handleSend = (e) => {
      if (question.length <= 0 || !question) {
        toast("No message found.", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      checkIfPagi(e);
    };

    let renderMessageBar = false;
    if (config?.global?.PLAY_GROUND?.value === "YES" || messages.length > 0) {
      renderMessageBar = true;
    }
    if (assitModal) {
      renderMessageBar = false;
    }
    console.log(
      config?.global?.PLAY_GROUND?.value === "YES",
      messages.length,
      renderMessageBar,
      "renderMessageBar"
    );
    return (
      <>
        <Box
          id="Footer-form-2"
          position={{ base: "inherit", md: "fixed" }}
          left={{ base: "0px", md: sidebar_width }}
          bottom={"0px"}
          zIndex={9}
          padding="20px 15px 19px"
          bg="transparent"
          width={{ base: "100%", md: `calc(100% - ${sidebar_width})` }}
          height={{
            base: "120px",
            md: "auto",
          }}
          filter={"none"}
          backgroundPosition="center"
          backgroundSize="cover"
          display={renderMessageBar ? "block" : "none"}
        // background={'#fff'}
        >
          {getPro && <GetPro onClose={closeProModal} />}

          <Box
            display={"flex"}
            width="70%"
            justifyContent="center"
            margin={"auto"}
          >
            <div
              // onSubmit={submitHandler}
              backdropFilter={"blur(12px)"}
              backgroundSize="cover"
              style={{
                // width: '50%',
                height: "auto",
                width: "100%",
                height: isMultilined ? "auto" : "auto",
                backdropFilter: "blur(12px)",
                borderRadius: "16px",
                position: "relative",
                // border: '1px solid #495057',
                boxShadow: "3px 3px 10px 3px #ddd",
                background: "#fff",
              }}
            >
              <Box position="relative">
                <textarea
                  ref={textareaRef}
                  style={{
                    width: "100%",
                    height: "46px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    fontSize: "16px",
                    lineHeight: "28px",
                    color: "#495057",
                    border: "none",
                    fontWeight: "400",
                    resize: "none",
                    outline: "0px",
                    // border: "0px",
                    background: "#fff",
                    fontFamily: "Nunito Sans, sans-serif",

                    padding: "10px 290px 5px 20px",
                    borderRadius: "16px",
                    display: "flex",
                  }}
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  onKeyDown={textareaEnterHandler}
                  placeholder={inputPlaceholder}
                  className="search-input-message"
                ></textarea>

                {fileUploads.length > 0 && (
                  <Box
                    pl="20px"
                    pb="20px"
                    d="flex"
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    gap="5px"
                    alignItems={"flex-start"}
                  >
                    {fileUploads.map((file) => (
                      <FileUploadItem file={file} onRemove={handleRemoveFile} />
                    ))}
                  </Box>
                )}

                {/* <Button position='absolute'> File Upload </Button> */}
                {isMultilined && (
                  <Box
                    marginRight={"px"}
                    position={"absolute"}
                    marginTop={"0px"}
                    top={isMultilined ? `-${textareaHeight - 50}px` : "13px"}
                    right={isMultilined ? "10px" : "206px"}
                    zIndex={9}
                    display="flex"
                    alignItems="center"
                  >
                    {question && (
                      <Text mt="2px" fontSize={"11px"} color={"#707eae"}>
                        {question.length}/{maxQuestionChar}
                      </Text>
                    )}

                    <IconButton
                      h="max-content"
                      w="max-content"
                      mt={{ base: "-2px", lg: "-1px" }}
                      bg="inherit"
                      borderRadius="inherit"
                      onClick={() => setQuestion("")}
                      _hover="none"
                      // mr={{ base: '110px', md: '50px' }}
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      color="#495057"
                      icon={
                        <Icon
                          as={FiX}
                          color={"#495057"}
                          w={question ? "17px" : "0"}
                          h={question ? "17px" : "0"}
                        />
                      }
                    />
                  </Box>
                )}
              </Box>

              <Box
                width="auto"
                display="flex"
                gap="10px"
                paddingTop={"2px"}
                paddingBottom={"2px"}
                alignItems="center"
                justifyContent="end"
                position={"absolute"}
                bottom={"2px"}
                right={"20px"}
              >
                {!responseProgressRef.current && (
                  <Tooltip label="Send" fontSize="md" borderRadius="8px">
                    <Button
                      bg={"transparent"}
                      _hover={{
                        bg: "transparent",
                        backgroundColor: "rgb(230, 230, 230)",
                        borderRadius: "5px",
                        height: "40px",
                      }}
                      id="_ee"
                      px="0px"
                      // ml="10px"
                      mt="-1px"
                      width="40px"
                      height="40px"
                      onClick={(e) => {
                        handleSend(e);
                      }}
                      type="buton"
                      cursor={"pointer"}
                      // width="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <FiSend size={"20px"} />
                    </Button>
                  </Tooltip>
                )}

                {selectedPrompt?.assistant?.assistantId && (
                  <FileUploadButton onFilesSelected={handleFilesSelected} />
                )}
                {/* {config?.global?.VOICE_MODE_SWITCH?.value}
{selectedPrompt?.toVoiceMode ? 'true' : 'false'} */}

                <Box
                  display="flex"
                  mt="-1px"
                  alignItems="center"
                  justifyContent="center"
                  width="40px"
                  height="40px"
                >
                  {!selectedPrompt?.toVoiceMode && (
                    <MicV2
                      audioTextBack={audioTextBack}
                      recording={recording}
                      setRecording={setRecording}
                      setMicModal={setMicModal}
                      micModal={micModal}
                      // hideIcon={true}
                      question={question}
                      setQuestion={setQuestion}
                      auto={auto}
                      setAuto={setAuto}
                      isSetQuestion={
                        !(
                          isObjectEmpty(currentSessionInfo) &&
                          isObjectEmpty(selectedPrompt)
                        )
                      }
                    />
                  )}

                  {selectedPrompt?.toVoiceMode && (
                    <>
                      {config?.global?.VOICE_MODE_SWITCH?.value === "YES" ? (
                        <Button
                          fontSize={"14px"}
                          width="40px"
                          height="40px"
                          mt="-1px"
                          bg="transparent"
                          p="0"
                          _hover={{
                            bg: "transparent",
                            backgroundColor: "rgb(230, 230, 230)",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            // history.replace('/chat?mode=voice')
                            setAssitModal(selectedPrompt);
                          }}
                          _active={{}}
                        >
                          <FiPhone size={19} />
                        </Button>
                      ) : (
                        <>
                          <MicV2
                            audioTextBack={audioTextBack}
                            recording={recording}
                            setRecording={setRecording}
                            setMicModal={setMicModal}
                            micModal={micModal}
                            // hideIcon={true}
                            question={question}
                            setQuestion={setQuestion}
                            auto={auto}
                            setAuto={setAuto}
                            isSetQuestion={
                              !(
                                isObjectEmpty(currentSessionInfo) &&
                                isObjectEmpty(selectedPrompt)
                              )
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                </Box>

                {!checkIsLast() && ssLoading ? (
                  <>
                    {ssLoading && (
                      <Button
                        fontSize={"14px"}
                        width="40px"
                        height="40px"
                        mt="-1px"
                        bg="transparent"
                        p="0"
                        _hover={{
                          bg: "transparent",
                          backgroundColor: "rgb(230, 230, 230)",
                          borderRadius: "5px",
                        }}
                        onClick={handleStopResponse}
                        _active={{}}
                      >
                        <FiSquare size={19} />
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Tooltip
                      label="Regenerate"
                      borderRadius="8px"
                      fontSize="md"
                    >
                      <Button
                        padding="0px"
                        bg="transparent"
                        width="40px"
                        height="40px"
                        d="flex"
                        mt="-1px"
                        _hover={{
                          bg: "transparent",
                          backgroundColor: "rgb(230, 230, 230)",
                          borderRadius: "5px",
                        }}
                        _active={{
                          bg: "transparent",
                        }}
                        onClick={handleRegenerate}
                        disabled={disableIcon}
                      >
                        <FiRefreshCw size={19} />
                      </Button>
                    </Tooltip>
                  </>
                )}
                <Tooltip label="Share" fontSize="md" borderRadius="8px">
                  <Button
                    padding="0px"
                    d="flex"
                    mt="-.8px"
                    bg="transparent"
                    width="40px"
                    height="40px"
                    _hover={{
                      bg: "transparent",
                      backgroundColor: "rgb(230, 230, 230)",
                      borderRadius: "5px",
                      height: "38px",
                    }}
                    _active={{
                      bg: "transparent",
                    }}
                    // width={'20px'}
                    onClick={handleShare}
                  >
                    <FiShare2 size={19} />
                  </Button>
                </Tooltip>

                {/* <Menu>
                  {({ isOpen, onClose }) => (
                    <>
                      <Tooltip label="1-Click" fontSize="md" borderRadius="8px">
                        <MenuButton
                          padding="8px 0px 8px 8px"
                          bg="transparent"
                          width="40px"
                          height="40px"
                          minWidth="25px"
                          position="relative"
                          top="-.5px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          isActive={isOpen}
                          as={Button}
                          _hover={{
                            bg: "transparent",
                            backgroundColor: "rgb(230, 230, 230)",
                            borderRadius: "5px",
                            // height: "38px",
                          }}
                          _active={{
                            bg: "transparent",
                          }}
                        >
                          <FiMenu size={25} />
                        </MenuButton>
                      </Tooltip>

                      <MenuList
                        borderRadius={"10px"}
                        display={isOpen ? "block" : "none"}
                        padding={2}
                        onClick={() => {
                          onClose();
                          // handleClick();
                        }}
                      >
                        <Box
                          _hover={{
                            bg: "transparent",
                            backgroundColor: "rgb(230, 230, 230)",
                            borderRadius: "5px",
                          }}
                          cursor="pointer"
                          padding="7px 13px"
                          fontWeight="bold"
                          fontSize="18px"
                        >
                        </Box>
                        <MenuItem
                          _hover={{
                            bg: "transparent",
                            backgroundColor: "rgb(230, 230, 230)",
                            borderRadius: "5px",
                          }}
                          onClick={generateOneClickSurvey}
                        >
                          {t("ServeyPost")}
                        </MenuItem>

                        <MenuItem
                          _hover={{
                            bg: "transparent",
                            backgroundColor: "rgb(230, 230, 230)",
                            borderRadius: "5px",
                          }}
                          onClick={generateOneClickBlog}
                        >
                          {t("BlogPost")}
                        </MenuItem>

                        <MenuItem
                          _hover={{
                            bg: "transparent",
                            backgroundColor: "rgb(230, 230, 230)",
                            borderRadius: "5px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            // console.log(1694);
                            generateOneClickNews();
                          }}
                        >
                          {t("VideoScript")}
                        </MenuItem>
                        
                        <MenuItem
                          color="#a3b0c2"
                          _hover={{
                            bg: "transparent",
                          }}
                        >
                          {t("BlogPostPro")}{" "}
                          <Badge
                            variant="solid"
                            colorScheme="#FFF"
                            bg={"rgb(150,114,187)"}
                            borderRadius="4px"
                            lineHeight={"1.2"}
                            height={"21px"}
                            fontSize={"10px"}
                            padding={"0.25rem 0.4rem"}
                            marginLeft={"5px"}
                          >
                            {t("Pro")}
                          </Badge>
                        </MenuItem>

                        <MenuItem
                          color="#a3b0c2"
                          _hover={{
                            bg: "transparent",
                          }}
                        >
                          {t("VideoScriptPro")}{" "}
                          <Badge
                            variant="solid"
                            colorScheme="#FFF"
                            bg={"rgb(150,114,187)"}
                            borderRadius="4px"
                            lineHeight={"1.2"}
                            height={"21px"}
                            fontSize={"10px"}
                            padding={"0.25rem 0.4rem"}
                            marginLeft={"5px"}
                          >
                            {t("Pro")}
                          </Badge>
                        </MenuItem>
                        <MenuItem
                          color="#a3b0c2"
                          _hover={{
                            bg: "transparent",
                          }}
                        >
                          {t("DeepStadies")}
                          <Badge
                            variant="solid"
                            colorScheme="#FFF"
                            bg={"rgb(150,114,187)"}
                            borderRadius="4px"
                            lineHeight={"1.2"}
                            height={"21px"}
                            fontSize={"10px"}
                            padding={"0.25rem 0.4rem"}
                            marginLeft={"5px"}
                          >
                            {t("Pro")}
                          </Badge>
                        </MenuItem>

                        <MenuItem
                          color="#a3b0c2"
                          _hover={{
                            bg: "transparent",
                          }}
                          onClick={() => {
                            history.push("/auth/sign-in");
                          }}
                        >
                          {t("TextMode")}
                          <Badge
                            variant="solid"
                            colorScheme="#FFF"
                            bg={"rgb(150,114,187)"}
                            borderRadius="4px"
                            lineHeight={"1.2"}
                            height={"21px"}
                            fontSize={"10px"}
                            padding={"0.25rem 0.4rem"}
                            marginLeft={"5px"}
                          >
                            {t("Pro")}
                          </Badge>
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu> */}
              </Box>
            </div>
            {/* </div> */}
          </Box>
        </Box>
      </>
    );
  };

  const {
    isOpen: isShareModal,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const {
    isOpen: isVideoModal,
    onOpen: onVideoModalOpen,
    onClose: onVideoModalClose,
  } = useDisclosure();

  const setVoiceModule = useCallback(
    (data) => {
      console.log(assitModal, "assitModal");
      setAssitModal(data);
      setCurrentSessionInfo({})
    },
    [assitModal, setAssitModal]
  );
  return (
    <>
      {isVideoModal && (
        <GetVideoConfirmation
          onClose={onVideoModalClose}
          generateOneClickVideo={generateOneClickVideo}
          selectedPrompt={selectedPrompt}
          orientation={orientation}
          setOrientation={setOrientation}
          includeCaption={includeCaption}
          setIncludeCaption={setIncludeCaption}
          language={language}
          setLanguage={setLanguage}
          voice={voice}
          setVoice={setVoice}
        />
      )}
      {isShareModal && (
        <ShareModal
          lan={currentLanguage}
          sessionId={sessionIdRef.current}
          onShareModalClose={onShareModalClose}
          memoryType={"chat"}
        />
      )}
      {!authData?.authState?.isAuthenticated && !isOpen && (
        <Box
          id="_jamela"
          onClick={onOpen}
          bg="transparent"
          position="fixed"
          left={0}
          top={0}
          w="100%"
          h="100%"
          zIndex="99999"
        >
          {" "}
        </Box>
      )}

      <>
        <MemoryList_Two
          sessionChanged={sessionChanged}
          sessionId={sessionIdRef.current}
          config={config}
          handleClick={handleClick}
        />

        <Box
          id="header-content-footer-wrapper"
          width={{ base: "100%", md: `calc(100% - ${sidebar_width})` }}
          ml={{ base: "0px", md: sidebar_width }}
          // height={`calc(${localStorage.getItem('vh')}px - 0px)`}
          position={"relative"}
          display={"flex"}
          minH="100vh"
          // overview="hidden"
          bg="#f9fafb"
          flexWrap="wrap"
        >
          {/* <HeaderBanner /> */}
          <Box
            width={"100%"}
            paddingY={{
              md: "15px",
            }}
            id="Content"
            minHeight={"calc(100vh - 90px)"}
            bg="#f9fafb"
            marginBottom="90px"
          >
            <Box
              mx={"15px"}
              borderRadius={"20px"}
              // paddingBottom="20px"
              height={"100%"}
            >
              {/* <ChatPagePrompt /> */}
              {(!initialFetch || !Object.keys(config).length > 0) && (
                <div
                  className="loading-area message-loading"
                // style={{paddingTop: '15px'}}
                >
                  <Box>
                    <SkeletonText
                      mt="0"
                      noOfLines={1}
                      spacing="5"
                      skeletonHeight="10"
                    />
                    <SkeletonText
                      mt="4"
                      noOfLines={5}
                      spacing="5"
                      skeletonHeight="3"
                    />
                  </Box>
                </div>
              )}

              {initialFetch && Object.keys(config).length > 0 ? (
                <>
                  {messages?.length > 0 &&
                    queryvalueV !== "voice" &&
                    !assitModal ? (
                    <>
                      {messages.length > 0 ? (
                        <>
                          {messages.map((message, index) => {
                            console.log(message, index, 'messageindex')
                            return (
                              <MessageRow
                                refid={sessionIdRef?.current}
                                message={message}
                                config={config}
                                isSearchShow={isSearchShow}
                                iteration={index + 1}
                                isLast={index === messages.length - 1}
                                handleRegenerate={handleRegenerate}
                                handleStopResponse={handleStopResponse}
                                updateMessage={updateMessage}
                                generateOneClickVideo={onVideoModalOpen}
                                continueMessage={continueMessage}
                                onShareModalOpen={onShareModalOpen}
                                selectedPrompt={selectedPrompt}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {messages.slice(0, 3).map((message, index) => {
                            console.log(message, index, 'messageindex 2')
                            return (
                              <MessageRow
                                refid={sessionIdRef?.current}
                                //TODO: Check here if shared link is wrong
                                message={message}
                                config={config}
                                isSearchShow={isSearchShow}
                                iteration={index + 1}
                                isLast={
                                  index === messages.slice(0, 3).length - 1
                                }
                                handleRegenerate={handleRegenerate}
                                handleStopResponse={handleStopResponse}
                                updateMessage={updateMessage}
                                generateOneClickVideo={onVideoModalOpen}
                                continueMessage={continueMessage}
                                selectedPrompt={selectedPrompt}
                              />
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!assitModal &&
                        config?.global?.PLAY_GROUND?.value === "YES" && (
                          <ChatPagePrompt2
                            promptGeneration={promptGeneration}
                            selectedPrompt={selectedPrompt}
                            // config={config}
                            setMicModal={handleMicModal}
                            // setMicModal={() => {
                            //   setMicModal(true)
                            //   setAuto(true)
                            // }}
                            // submitHandler={submitHandler}
                            setVoiceModule={setVoiceModule}
                            setSelectedPrompt={setSelectedPrompt}
                            homepageModalClose={homepageModalClose}
                          />
                        )}
                    </>
                  )}

                  {/* {messages.length === 0 && (
                    <>
                      <ChatPagePrompt2
                        promptGeneration={promptGeneration}
                        selectedPrompt={selectedPrompt}
                      />
                    </>
                  )} */}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          {/* Footer */}
          {queryvalueV !== "voice" && <>{CommonForm(question)}</>}
        </Box>
        {showModal &&
          queryvalueV !== "voice" &&
          config?.global?.PLAY_GROUND?.value === "YES" && (
            <FirstModal setHomepageModalClose={setHomepageModalClose} />
          )}

        {assitModal?._id && (
          <AssistantModal
            modalData={assitModal}
            modalCloseHandler={() => setAssitModal(null)}
            submitHandler={submitHandler}
            messages={messages}
            responseProgressRef={responseProgressRef.current}
            currentMessage={currentMessage}
            setCurrentMessage={setCurrentMessage}
            currentSessionInfo={currentSessionInfo}
            sessionChanged={sessionChanged}
            setCurrentSessionInfo={setCurrentSessionInfo}
            sessionIdRef={sessionIdRef}
          // closeModal={() => setModal(false)}
          />
        )}
      </>
    </>
  );
}
