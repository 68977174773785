import {
  Box,
  Button,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import { MdOutlineCallEnd } from "react-icons/md";
import { IoMdPlay, IoIosMic } from "react-icons/io";
import { BsChatSquareTextFill } from "react-icons/bs";
import { FiMessageCircle, FiMessageSquare, FiMic } from "react-icons/fi";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";
import { userPrivateRequest } from "config/axios.config";
import { useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioVisualizer, LiveAudioVisualizer } from "react-audio-visualize";
import { ImSpinner2 } from "react-icons/im";
import { useRef } from "react";
import { toast } from "react-toastify";
import laodingAudio from "../../../assets/loading-audio.wav";
import { ConfigContext } from "contexts/ConfigContext";
import WaveSurfer from "wavesurfer.js";
import urlParser from "url";
import { FaUserCircle } from "react-icons/fa";
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const RealTimeAudioVisualizer = ({ mediaRecorder }) => {
  const canvasRef = useRef(null);
  const animationFrameId = useRef(null);

  const drawVisualization = (analyser, canvasCtx, WIDTH, HEIGHT) => {
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    analyser.getByteFrequencyData(dataArray);

    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    // Set the spacing and bar width as in the fake visualizer
    const barSpacing = 5;
    const barWidth = 2;

    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      // Calculate the bar height and scale it
      const barHeight = dataArray[i] * (HEIGHT / 255);
      canvasCtx.fillStyle = "#ddd"; // White color for bars

      // Draw each bar
      canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

      // Move to the next bar position
      x += barSpacing;
    }

    // Request next frame of animation
    animationFrameId.current = requestAnimationFrame(() =>
      drawVisualization(analyser, canvasCtx, WIDTH, HEIGHT)
    );
  };

  useEffect(() => {
    if (mediaRecorder && mediaRecorder.stream && canvasRef.current) {
      const canvasCtx = canvasRef.current.getContext("2d");
      const WIDTH = canvasRef.current.width;
      const HEIGHT = canvasRef.current.height;

      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioCtx.createMediaStreamSource(mediaRecorder.stream);
      const analyser = audioCtx.createAnalyser();
      source.connect(analyser);
      analyser.fftSize = 2048;

      drawVisualization(analyser, canvasCtx, WIDTH, HEIGHT);
    }

    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [mediaRecorder]);

  return <canvas ref={canvasRef} width="200" height="40" />;
};
const AudioVisualizerr = ({ audioUrl, onAudioEnd, setLoading }) => {
  const canvasRef = useRef(null);
  const animationFrameId = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const audioRef = useRef(null);

  const draw = () => {
    if (!canvasRef.current) return;

    const canvasCtx = canvasRef.current.getContext("2d");
    const WIDTH = canvasRef.current.width;
    const HEIGHT = canvasRef.current.height;

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    analyserRef.current.getByteFrequencyData(dataArray);

    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    // Set the spacing and bar width as in the RealTimeAudioVisualizer
    const barSpacing = 5;
    const barWidth = 2;

    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      // Calculate the bar height and scale it as in the RealTimeAudioVisualizer
      const barHeight = dataArray[i] * (HEIGHT / 255);
      canvasCtx.fillStyle = "#ddd"; // Use the same white color for bars

      // Draw each bar with the updated bar height calculation
      canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

      // Move to the next bar position
      x += barSpacing;
    }

    animationFrameId.current = requestAnimationFrame(draw);
  };

  // Function to start playing audio
  const playAudio = () => {
    if (audioRef.current) {
      const playPromise = audioRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started
            console.log("Playback started");
          })
          .catch((error) => {
            // Auto-play was prevented
            console.log("Playback failed", error);
          });
      }
    }
  };

  useEffect(() => {
    // Initialize the audio context and analyser
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    analyserRef.current = audioContextRef.current.createAnalyser();

    // Set up the audio element
    audioRef.current = new Audio(audioUrl);
    audioRef.current.crossOrigin = "anonymous"; // CORS settings

    // Create and connect the media element source if not already set up
    if (!sourceRef.current) {
      sourceRef.current = audioContextRef.current.createMediaElementSource(
        audioRef.current
      );
      sourceRef.current.connect(analyserRef.current);
      analyserRef.current.connect(audioContextRef.current.destination);
    }

    // Start drawing the visualization
    draw();

    // Event listeners for audio playback
    audioRef.current.addEventListener("canplaythrough", playAudio);
    audioRef.current.addEventListener("ended", onAudioEnd);

    // Cleanup function to run when the component unmounts
    return () => {
      // Remove event listeners
      audioRef.current.removeEventListener("canplaythrough", playAudio);
      audioRef.current.removeEventListener("ended", onAudioEnd);

      // Pause and clean up audio if playing
      audioRef.current.pause();
      audioRef.current.src = ""; // Reset the source to release the audio file

      // Disconnect the audio nodes
      if (sourceRef.current) {
        sourceRef.current.disconnect();
      }
      if (analyserRef.current) {
        analyserRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }

      // Cancel any animation frame requests
      cancelAnimationFrame(animationFrameId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]); // Re-run the effect only if the audioUrl changes

  return <canvas ref={canvasRef} width="200" height="40" />;
};

// const AudioVisualizerr = ({ audioUrl, onAudioEnd }) => {
//   const canvasRef = useRef(null);
//   const animationFrameId = useRef(null);
//   const audioContextRef = useRef(null);
//   const analyserRef = useRef(null);
//   const sourceRef = useRef(null);
//   const audioRef = useRef(new Audio(audioUrl)); // Create a ref for the Audio object

//   const draw = () => {
//     if (!canvasRef.current) return;

//     const canvasCtx = canvasRef.current.getContext("2d");
//     const WIDTH = canvasRef.current.width;
//     const HEIGHT = canvasRef.current.height;

//     const bufferLength = analyserRef.current.frequencyBinCount;
//     const dataArray = new Uint8Array(bufferLength);

//     analyserRef.current.getByteFrequencyData(dataArray);

//     canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

//     const barWidth = (WIDTH / bufferLength) * 2.5;
//     let barHeight;
//     let x = 0;

//     for (let i = 0; i < bufferLength; i++) {
//       barHeight = dataArray[i];
//       canvasCtx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
//       canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);
//       x += barWidth + 1;
//     }

//     animationFrameId.current = requestAnimationFrame(draw);
//   };

//   useEffect(() => {
//     audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
//     analyserRef.current = audioContextRef.current.createAnalyser();

//     // Do not recreate the MediaElementSource if it's already created
//     if (!sourceRef.current) {
//       audioRef.current.crossOrigin = "anonymous";
//       sourceRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);
//       sourceRef.current.connect(analyserRef.current);
//       analyserRef.current.connect(audioContextRef.current.destination);
//     }

//     // Start drawing the visualization
//     draw();

//     // Start playing the audio
//     audioRef.current.play();

//     // Add an event listener to handle audio end
//     audioRef.current.addEventListener('ended', onAudioEnd);

//     return () => {
//       cancelAnimationFrame(animationFrameId.current);
//       audioRef.current.pause();
//       sourceRef.current.disconnect();
//       analyserRef.current.disconnect();
//       audioContextRef.current.close(); // Close the audio context to release resources
//       audioRef.current.removeEventListener('ended', onAudioEnd);
//     };
//   }, [audioUrl, onAudioEnd]); // Ensure onAudioEnd is included in the dependencies array

//   return <canvas ref={canvasRef} width="200" height="40" />;
// };

const PromptModal = ({
  modalData,
  modalCloseHandler,
  closeModal,
  setCurrentMessage,
  submitHandler,
  messages,
  responseProgressRef,
  currentMessage,
  currentSessionInfo,
  sessionChanged,
  setCurrentSessionInfo,
  sessionIdRef,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaRecorderExample, setMediaRecorderExample] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [transcript, setTranscript] = useState("");
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState("");
  const [processing, setProcessing] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [audioData, setAudioData] = useState();
  const [translateText, setTranslateText] = useState("");
  const configData = useContext(ConfigContext);
  const config = configData.config || {};

  let queryDataV =
    urlParser.parse(window.location.href, { parseQueryString: true }).query ||
    {};
  let queryvalueV = queryDataV.mode || "";
  // useEffect(() => {
  //   console.log("TTS testing");

  //   let mediaSource = new MediaSource();
  //   let audio = new Audio();
  //   audio.src = URL.createObjectURL(mediaSource);
  //   audio.muted = true; // Mute the audio to satisfy autoplay policies

  //   mediaSource.addEventListener('sourceopen', () => {
  //     console.log("MediaSource opened");
  //     let sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg'); // Use the correct MIME type

  //     sourceBuffer.addEventListener("updateend", () => {
  //       console.log("SourceBuffer update ended, ready to append more data");
  //     });

  //     let url = "http://localhost:8000/openai/ttsStreaming";
  //     fetch(url, {
  //             method: "POST",
  //           body: JSON.stringify({
  //             model: "tts-1",
  //             input:
  //               "Ill try boss, Streaming response is a bit challenging on mobile cause it is not same on web browsers. Looking for 3rd packages that could help us. I tried react native event source for now. Ill try boss, Streaming response is a bit challenging on mobile cause it is not same on web browsers. Looking for 3rd packages that could help us. I tried react native event source for now.",
  //             voice: "nova",
  //           }),
  //           headers: {
  //             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTcwMDQwY2ZjOGU0ODNmNDYxYzE5MWEiLCJpYXQiOjE3MDE5MjQ1ODF9.hyV6AFh7KWPSRa4b3O1e4EUfBTzQrvh9jhuOh33bv8Y`,
  //           }
  //     })
  //     .then(response => {
  //       console.log("Received response from server");
  //       const reader = response.body.getReader();
  //       reader.read().then(function process({ done, value }) {
  //         if (done) {
  //           console.log("Stream finished. Ending MediaSource stream.");
  //           mediaSource.endOfStream();
  //           audio.muted = false;
  //           audio.play().catch((e) => console.error("Playback failed:", e));
  //           return;
  //         }
  //         if (!sourceBuffer.updating && value) {
  //           console.log("Appending buffer");
  //           sourceBuffer.appendBuffer(value);
  //         } else {
  //           console.log("SourceBuffer is busy, data will be appended on updateend");
  //         }
  //         reader.read().then(process);
  //       });
  //     })
  //     .catch((err) => console.error("TTS Stream failed:", err));
  //   });

  //   audio.addEventListener("error", (e) => console.error("Audio error", e));

  //   // Cleanup
  //   return () => {
  //     console.log("Cleanup: Pausing audio and ending MediaSource stream.");
  //     audio.pause();
  //     if (mediaSource.readyState === "open") {
  //       mediaSource.endOfStream();
  //     }
  //   };
  // }, []);

  function parseContent(content) {
    try {
      // Try to parse the content as JSON
      const parsed = JSON.parse(content);

      return parsed;
    } catch (e) {
      // If parsing fails, it's likely a normal string
      return content;
    }
  }
  const [audio, setAudio] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const audioRefLoading = useRef(new Audio(laodingAudio)); // Replace with the actual path

  useEffect(() => {
    let repeatTimeout;

    const playAudioLoading = () => {
      if (audioRefLoading.current) {
        audioRefLoading.current.loop = false; // Set loop to false
        audioRefLoading.current.play();
        // Set a timeout to replay the audio after 4-5 seconds
        repeatTimeout = setTimeout(() => {
          if (audioRefLoading.current && loading === "Generating...") {
            audioRefLoading.current.currentTime = 0;
            playAudioLoading();
          }
        }, 4500); // Adjust time as needed
      }
    };

    const stopAudioLoading = () => {
      if (audioRefLoading.current) {
        clearTimeout(repeatTimeout); // Clear the timeout when stopping
        audioRefLoading.current.pause();
        audioRefLoading.current.currentTime = 0;
      }
    };

    if (loading === "Generating...") {
      playAudioLoading();
    } else {
      stopAudioLoading();
    }

    // Cleanup
    return () => {
      stopAudioLoading();
    };
  }, [loading]);

  const audioRef = useRef(null);
  const [currentAudio, setCurrentAudio] = useState("");
  const canvasRef = useRef(null);
  // Add this function outside your component
  // function drawFakeVisualization(canvasRef) {
  //   if (!canvasRef.current) return;

  //   const canvasCtx = canvasRef.current.getContext("2d");
  //   const WIDTH = canvasRef.current.width;
  //   const HEIGHT = canvasRef.current.height;
  //   canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

  //   // Set the spacing between bars
  //   const barSpacing = 5; // You can adjust this value for different spacing
  //   const barWidth = 2; // Set bar width to 2px

  //   for (let i = 0; i < WIDTH; i += barSpacing) {
  //     const barHeight = Math.random() * HEIGHT;
  //     canvasCtx.fillStyle = "#ddd"; // Set the bar color to white
  //     // Draw each bar with a width of 2px
  //     canvasCtx.fillRect(i, HEIGHT - barHeight, barWidth, barHeight);
  //   }

  //   // Continue the loop with a delay to slow down the animation
  //   setTimeout(() => {
  //     requestAnimationFrame(() => drawFakeVisualization(canvasRef));
  //   }, 100); // Adjust the delay here to control the speed
  // }

  // useEffect(() => {
  //   let animationFrameId;

  //   // Start the fake visualization when loading === "Speaking"
  //   if (loading === "Speaking") {
  //     animationFrameId = requestAnimationFrame(() => drawFakeVisualization(canvasRef));
  //   }

  //   // Cleanup function to stop the animation when loading is not "Speaking"
  //   return () => {
  //     if (animationFrameId) cancelAnimationFrame(animationFrameId);
  //   };
  // }, [loading]);

  const onCanPlayThrough = () => {
    console.log("Can play through - audioCHECK", audioRef.current);
    setLoading("Speaking");
    // audioRef?.current?.play();
  };

  const onEnded = () => {
    console.log("Audio ended - audioCHECK", audioRef.current);
    setCurrentMessage(null);
    setLoading("finished");
  };
  // useEffect(() => {
  //   return () => {
  //     if (audioContext) {
  //       audioContext.close();
  //     }
  //   };
  // }, []);
  console.log(modalData, "modalData");

  useEffect(() => {
    // Only initialize audio if modal is open and currentMessage has loaded
    console.log(currentMessage, "AUDIO PLAYING");
    if (
      isOpen &&
      modalData &&
      currentMessage?.ai?.fullLoaded &&
      modalData?.promptType === "assistant"
    ) {
      // ... other code ...
      const aiText = parseContent(currentMessage?.ai?.text);
      const finalRes = aiText?.find((ait) => ait?.type === "final_response");
      const ttsFile = finalRes?.ttsFile;
      if (ttsFile && (!audioRef.current || audioRef.current.src !== ttsFile)) {
        setCurrentAudio(ttsFile);
        const newAudio = new Audio(ttsFile);
        audioRef.current = newAudio;
        setLoading("Speaking");
        // newAudio.addEventListener("canplaythrough", onCanPlayThrough);
        // newAudio.addEventListener("ended", onEnded);
      }
    }

    if (
      isOpen &&
      modalData &&
      currentMessage?.ai?.fullLoaded &&
      modalData?.promptType === "chingu"
    ) {
      console.log(currentMessage?.ai?.text, "modalData");
      const payload = {
        model: data?.assistantVoiceModel ? data?.assistantVoiceModel : "tts-1",
        input: currentMessage?.ai?.text,
        voice: data?.assistantVoiceCharacter
          ? data?.assistantVoiceCharacter
          : "nova",
      };
      userPrivateRequest
        .post("/openai/tts", payload)
        .then((res) => {
          console.log(res);
          const ttsFile = res?.data?.file?.Location;
          if (
            ttsFile &&
            (!audioRef.current || audioRef.current.src !== ttsFile)
          ) {
            const newAudio = new Audio(ttsFile);
            audioRef.current = newAudio;
            setCurrentAudio(ttsFile);
            setLoading("Speaking");
            // newAudio.addEventListener("canplaythrough", onCanPlayThrough);
            // newAudio.addEventListener("ended", onEnded);
          }
        })
        .catch((err) => {
          toast("Failed to generate response", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading("finished");
        });
    }

    // Cleanup function
    return () => {
      // if (audioRef.current) {
      //   audioRef.current.pause();
      //   audioRef.current.currentTime = 0;
      //   audioRef.current.removeEventListener(
      //     "canplaythrough",
      //     onCanPlayThrough
      //   );
      //   audioRef.current.removeEventListener("ended", onEnded);
      //   audioRef.current = null;
      // }
    };
  }, [isOpen, currentMessage, modalData]);

  const authData = useContext(AuthContext);
  const { setChatListe } = authData;
  const lang = useLocation();
  const isKo = lang?.pathname.includes("/ko");
  const data = { ...modalData };
  console.log(data, "audioCHECK");
  const { t } = useTranslation();

  const formData = new FormData();

  function startRecording() {
    console.log(loading, "anything");
    if (loading === "Speaking") {
      console.log(loading, "anything");
      // audioRef.current.loop = false; // Disable looping
      // audioRef.current.pause();
      // audioRef.current.currentTime = 0; // Reset audio to start
      onEnded();
    }
    makeStateFalseForResult();
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      recorder.addEventListener("dataavailable", handleDataAvailable);
      recorder.start();
      console.log(recorder, "mediaRecorder 350");
      setMediaRecorder(recorder);
      setMediaRecorderExample(recorder);
      setRecording(true);
      SpeechRecognition.startListening();
    });
  }

  console.log(mediaRecorder, "mediaRecorder 350");

  const makeStateFalseForResult = () => {};

  function handleDataAvailable(event) {
    if (!event.data) {
      console.log("no data found");
      return;
    }
    setProcessing(true);

    const audioBlob = new Blob([event.data], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioURL(audioUrl);
    formData.append("file", audioBlob, "recorded_audio.mp3");
    formData.append("model", "whisper-1");
    formData.append("response_format", "text");
    setAudioData(audioBlob);
    transcribeFunc();
  }
  {
  }
  function stopRecording() {
    setLoading("Generating...");
    if (mediaRecorder && recording) {
      setLoading("Generating...");
      mediaRecorder.addEventListener("stop", () => {
        setRecording(false);
        requestAnimationFrame(() => {
          const tracks = mediaRecorder.stream.getTracks();
          tracks.forEach((track) => track.stop());
        });
      });
      console.log(mediaRecorder, "mediaRecorder 317");
      setMediaRecorder(null);
      if (mediaRecorder.state == "recording") {
        mediaRecorder.stop();
        SpeechRecognition.stopListening();
      }
    }
  }

  const transcribeFunc = () => {
    const headers = {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "multipart/form-data",
    };
    setLoading("Generating...");
    userPrivateRequest
      .post("/openai/transcription", formData, { headers })
      .then((response) => {
        let transcribeText = response.data?.data;

        setTranslateText(transcribeText);
        submitHandler(null, transcribeText);
        // audioTextBack(response.data);
      })
      .catch((error) => {
        setLoading("finished");
        toast("Failed to read audio", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  useEffect(() => {
    console.log(sessionIdRef, "sessionIdRef");
    if (!sessionIdRef.current) {
      setLoading("Initializing...");
      submitHandler(
        null,
        modalData?.firstVoiceModeMessage
          ? modalData?.firstVoiceModeMessage
          : "Hi",
        "text",
        false,
        "yes",
        true
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      if (mediaRecorder && mediaRecorder.state === "recording") {
        mediaRecorder.stop();
      }
      // Release the media stream
      audioChunks.length > 0 && URL.revokeObjectURL(audioChunks[0]);
    };
  }, [mediaRecorder, audioChunks]);

  const onCloseHandler = () => {
    console.log("Modal close handler called - audioCHECK", audioRef.current);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
    }

    if (queryvalueV === "voice") {
      sessionIdRef.current = null;
    }
    setCurrentMessage(null);
    onClose();
    modalCloseHandler(modalData);
    setCurrentSessionInfo(null);
  };

  useEffect(() => {
    onOpen(true);
  }, []);

  console.log(currentSessionInfo, "currentSessionInfo");

  // if (isLoading) return 'Loading...'
  console.log(loading, sessionIdRef, "sessionIdRef");

  const instructionMessage = isKo
    ? config?.global?.VOICE_CONFIGURATION?.value?.instructionMessageKr
    : config?.global?.VOICE_CONFIGURATION?.value?.instructionMessage;
  const loadingMessageStep2 = isKo
    ? config?.global?.VOICE_CONFIGURATION?.value?.loadingMessageStep2Kr
    : config?.global?.VOICE_CONFIGURATION?.value?.loadingMessageStep2;

  const loadingMessageStep3 = isKo
    ? config?.global?.VOICE_CONFIGURATION?.value?.loadingMessageStep3Kr
    : config?.global?.VOICE_CONFIGURATION?.value?.loadingMessageStep3;

  const loadingMessageStep1 = isKo
    ? config?.global?.VOICE_CONFIGURATION?.value?.loadingMessageStep1Kr
    : config?.global?.VOICE_CONFIGURATION?.value?.loadingMessageStep1;

  console.log(currentSessionInfo, "currentSessionInfo");
  console.log(audioRef, currentAudio, "audioRef");
  return (
    <div className="first-modal" id="HOME-MODL">
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "350px",
            base: "calc(100% - 20px)",
          }}
          h="650px"
          maxW={"100%"}
          borderRadius={"30px"}
          display={"block"}
          my={{
            base: "auto",
            lg: "auto",
          }}
        >
          <Box height={"100%"}>
            <Box height={"calc(100% - 100px)"} position={"relative"}>
              {/* <div ref={waveformRef} id="waveform"></div> */}
              {data?.portraitImage && (
                <img
                  src={data?.portraitImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "calc(100% - 25px)",
                    objectFit: "cover",
                    borderRadius: "30px 30px 0px 0px",
                  }}
                />
              )}
              {!data?.portraitImage && (
                <img
                  src={
                    "https://chingu.s3.ap-northeast-2.amazonaws.com//modal/slider/f4c75469-e5d9-458e-82e6-51de93a6582a.png"
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "calc(100% - 25px)",
                    objectFit: "cover",
                    borderRadius: "30px 30px 0px 0px",
                  }}
                />
              )}

              {mediaRecorder && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                  position={"absolute"}
                  top="0"
                  left={"0"}
                  borderRadius={"30px 30px 0px 0px"}
                  height={"calc(100% - 25px)"}
                  w="100%"
                  bg={"rgba(0, 0, 0, .50)"}
                  pb="7px"
                >
                  {/* <LiveAudioVisualizer
                    mediaRecorder={mediaRecorder}
                    width={200}
                    height={75}
                  /> */}
                  <RealTimeAudioVisualizer mediaRecorder={mediaRecorder} />
                </Box>
              )}

              {loading === "Speaking" && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                  position={"absolute"}
                  top="0"
                  left={"0"}
                  borderRadius={"30px 30px 0px 0px"}
                  height={"calc(100% - 25px)"}
                  w="100%"
                  bg={"rgba(0, 0, 0, .50)"}
                  pb="7px"
                >
                  {/* <canvas ref={canvasRef} width="200" height="40" /> */}

                  <AudioVisualizerr
                    audioUrl={currentAudio}
                    onAudioEnd={() => {
                      onEnded();
                    }}
                    setLoading={setLoading}
                  />
                </Box>
              )}

              {(loading === "Generating..." ||
                loading === "Speaking" ||
                loading === "Initializing...") && (
                <Box
                  textAlign={"center"}
                  lineHeight={"25px"}
                  bg="#A483DB"
                  color={"#fff"}
                  fontSize={"14px"}
                  position={"relative"}
                  style={{
                    background: 'rgba(106,85,142,0.7)',
                    WebkitBackdropFilter: 'blur(10px)',
                    backdropFilter: 'blur(10px)',
                  }}
                >
                  {loading === "Generating..." && loadingMessageStep2}
                  {loading === "Speaking" && loadingMessageStep3}
                  {loading === "Initializing..." && loadingMessageStep1}
                  <span className="loading-dots"></span>
                  {/* {loading} */}
                </Box>
              )}

              {loading !== "Generating..." &&
                loading !== "Speaking" &&
                loading !== "Initializing..." && (
                  <Box
                    textAlign={"center"}
                    lineHeight={"25px"}
                    bg="#A483DB"
                    color={"#fff"}
                    fontSize={"14px"}
                    style={{
                      background: 'rgba(106,85,142,0.7)',
                      WebkitBackdropFilter: 'blur(10px)',
                      backdropFilter: 'blur(10px)',
                    }}
                  >
                    {instructionMessage}
                  </Box>
                )}

              {/* {(loading || responseProgressRef) && <Text>Loading</Text>} */}

              {authData?.authState?.user?.photo && (
                <Image
                  src={authData?.authState?.user?.photo}
                  width={"88px"}
                  height={"88px"}
                  borderRadius={"100%"}
                  objectFit={"cover"}
                  position={"absolute"}
                  bottom="75px"
                  left="50%"
                  style={{
                    transform: 'translateX(-50%)'
                  }}
                />
              )}

              {!authData?.authState?.user?.photo && (
                <Box
                  // src={authData?.authState?.user?.photo}
                  width={"88px"}
                  height={"88px"}
                  borderRadius={"12px"}
                  // objectFit={"cover"}
                  position={"absolute"}
                  bottom="75px"
                  left="50%"
                  // bg="blue"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  style={{
                    background: 'rgba(255,255,255,.5)',
                    WebkitBackdropFilter: 'blur(10px)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255,255,255,0.1)',
                    transform: 'translateX(-50%)'
                  }}
                >
                     <FaUserCircle color="#121212" size={'60px'} />
                  {/* <Text
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    color="#fff"
                    textTransform={"uppercase"}
                  >
                    {authData?.authState?.user?.firstName?.[0]}
                    {authData?.authState?.user?.lastName?.[0]}
                  </Text> */}
                </Box>
              )}
            </Box>

            <Box
              bg="#6A558E"
              height={"100px"}
              borderRadius={"0px 0px 22px 22px"}
            >
              <Box display={"flex"} justifyContent={"space-between"} p="25px">
                <Box w={"40px"}>
                  {/* {currentSessionInfo?._id && (
                    <Button
                      bg="#fff"
                      w="48px"
                      h="48px"
                      onClick={() => {
                        onCloseHandler();
                        sessionChanged(currentSessionInfo?._id);
                        // setChatListe(false);
                      }}
                    >

<FiMessageCircle color="#000" size={30} />
                    </Button>
                  )} */}

                  <Button
                    bg="#fff"
                    w="48px"
                    h="48px"
                    onClick={() => {
                      sessionChanged(sessionIdRef.current);
                      onCloseHandler();

                      // setChatListe(false);
                    }}
                    disabled={!sessionIdRef.current}
                    borderRadius={'100%'}
                  >
                    <FiMessageCircle color="#000" size={30} />
                    {/* <FiMessageSquare color="#000" size={30} /> */}
                  </Button>
                </Box>

                <Button
                  bg="#fff"
                  w="48px"
                  h="48px"
                  onMouseDown={startRecording} // Starts recording when the mouse button is pressed down
                  onMouseUp={stopRecording} // Stops recording when the mouse button is released
                  onTouchStart={startRecording} // For touch devices, start recording on touch
                  onTouchEnd={stopRecording} // For touch devices, stop recording when touch ends
                  disabled={
                    loading === "Generating..." || loading === "Initializing..."
                  }
                  borderRadius={'100%'}
                >
                  {recording ? (
                    <FiMic color="red" size={30} />
                  ) : (
                    <FiMic color="#000" size={30} />
                  )}
                </Button>

                <Button
                  bg="#ff4e4e"
                  w="48px"
                  h="48px"
                  onClick={onCloseHandler}
                  _hover={{
                    background: "#ff4e4e",
                  }}
                  borderRadius={'100%'}
                >
                  <MdOutlineCallEnd color="#fff" size={30} />
                </Button>
              </Box>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
      <style jsx>{`
        #media-box {
        }
        #media-box h3 p {
          margin-bottom: 6px;
        }
        #media-box ul li p {
          color: 111827;
          font-size: 14px;
        }
        #media-box ul li {
          display: flex;
          align-items: center;
          gap: 0px 10px;
          margin-bottom: 7px;
        }
        #media-box ul li .icion {
          width: 14px;
        }
        .slick-dots {
          bottom: 1px !important;
          display: block;
          text-align: center;
          width: 100%;
        }

        .slick-dots li {
          position: relative;
          display: inline-block;
          height: 14px;
          width: 14px;
          margin: 0 5px;
          padding: 0;
        }

        .slick-dots li button {
          border: 0;
          background: rgb(96, 165, 250);
          display: block;
          height: 10px;
          width: 10px;
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 5px;
          cursor: pointer;
          border-radius: 50%;
          margin-right: 0px;
        }

        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 16px;
          height: 16px;
          font-family: "slick";
          font-size: 6px;
          line-height: 16px;
          text-align: center;
          color: black;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .slick-dots li.slick-active button {
          background: rgb(29 64 175);
        }
      `}</style>
    </div>
  );
};

export default PromptModal;
