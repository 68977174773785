import {
  Box,
  Flex,
  List,
  ListItem,
  Text,
  useColorMode,
  useColorModeValue,
  Image
} from "@chakra-ui/react"
import CustomNavLink from "langHoc/CustomNavLink"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Facebook from '../../assets/socmed-icons/facebook.svg'
import Twitter from '../../assets/socmed-icons/twitter.svg'
import Instagram from '../../assets/socmed-icons/instagram.svg'
import Youtube from '../../assets/socmed-icons/youtube.svg'
import Tiktok from '../../assets/socmed-icons/tiktok.svg'
import Telegram from '../../assets/socmed-icons/telegram.svg'
import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Footer() {

  const textColor = useColorModeValue("#5f6368", "white");
  const { toggleColorMode } = useColorMode();
  const { t } = useTranslation();
  const history = useHistory()
  const shouldRemoveFooter = history?.location?.pathname?.includes('/auth/');
  const signUpPro = history?.location?.pathname?.includes('/ex-pro-sign-up-');
  const configData = useContext(ConfigContext);
  const config = configData?.config?.global
  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')
  const leftFooterText = config?.LEFT_FOOTER_TEXTS?.value
  const rightFooterLinks = config?.RIGHT_FOOTER_LINKS?.value
  const middleFooterSocialLinks = config?.MIDDLE_FOOTER_SOCIAL_LINKS?.value


  return (
    <div>
      <Flex
        zIndex="3"
        flexDirection={{
          base: "column",
          lg: "row",
          xl: 'row'
        }}
        alignItems={{
          base: "center",
          lg: "start",
        }}
        px={{ base: "25px", }}

        bg={signUpPro ? '#f8f8f9' : 'transparent'}

      >
        <Text
          color={textColor}
          textAlign={{
            base: "center",
            lg: "start",
          }}
          mb='0px'
          width={{ base: "100%", md: '100%', lg: '100%' }}
          display={{
            // md: shouldRemoveFooter ? 'none' : 'block',
            // base: shouldRemoveFooter ? 'none' : 'block'
          }}
          order={{ base: 2, md: 2, lg: 0 }}
          fontSize={{ base: "12px", lg: "12px" }}

        >
          {leftFooterText?.privacyPolicy && (

            <> {isKor ? leftFooterText?.privacyPolicyKr : leftFooterText?.privacyPolicy} </>
          )}

        </Text>

        {/* <Text></Text> */}

        <Box
          width={{ base: "100%", md: "100%" }}
          mb={{ base: "10px", lg: "0px" }}
          display={{
            md: shouldRemoveFooter ? 'none' : 'block',
            base: shouldRemoveFooter ? 'none' : 'block'
          }}
          order={{ base: 0, md: 0, lg: 1 }}

        >
          <ul
            style={{
              display: "flex",
              gap: "0px 20px",
              listStyle: "none",
              width: "100%",
              justifyContent: "center",
            }}

          >
            {middleFooterSocialLinks?.facebook && <li>
              <a href={middleFooterSocialLinks?.facebook} target="_blank">
                <Image src={Facebook} alt="Facebook" boxSize="12px" />


              </a>
            </li>}
            {middleFooterSocialLinks?.instagram && <li>
              <a href={middleFooterSocialLinks?.instagram} target="_blank">
                <Image src={Instagram} alt="Instagram" boxSize="12px" />
              </a>
            </li>}
            {middleFooterSocialLinks?.tiktok && <li>
              <a href={middleFooterSocialLinks?.tiktok} target="_blank">
                <Image src={Tiktok} alt="Tiktok" boxSize="12px" />
              </a>
            </li>}
            {middleFooterSocialLinks?.twitter && <li>
              <a href={middleFooterSocialLinks?.twitter} target="_blank">
                <Image src={Twitter} alt="Twitter" boxSize="12px" />
              </a>
            </li>}
            {middleFooterSocialLinks?.youtube && <li>
              <a href={middleFooterSocialLinks?.youtube} target="_blank">
                <Image src={Youtube} alt="Youtube" boxSize="12px" />
              </a>
            </li>}
            {middleFooterSocialLinks?.telegram && <li>
              <a href={middleFooterSocialLinks?.telegram} target="_blank">
                <Image src={Telegram} alt="Telegram" boxSize="12px" />
              </a>
            </li>}
          </ul>
        </Box>


        <List
          width={{ base: "100%", md: '100%', lg: '100%' }}
          display="flex"
          justifyContent={{ base: "center", md: "center", lg: 'flex-end' }}
          gap={{ base: "0px 10px", lg: "0px 20px" }}
          // mt={{ base: "15px", lg: "0px" }}
          className="linkks"
          order={{ base: 1, md: 1, lg: 2 }}
          mb={{ base: '8px', lg: "0px" }}

        >
          {rightFooterLinks?.privacyPolicy && <ListItem color={textColor} fontSize={{ base: "12px", lg: "12px" }}>
            <a href={rightFooterLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">{t("PrivacyPolicy")}</a>
          </ListItem>}
          {rightFooterLinks?.terms && <ListItem color={textColor} fontSize={{ base: "12px", lg: "12px" }}>
            <a href={rightFooterLinks?.terms} target="_blank">{t("Terms")}</a>
          </ListItem>}
          {rightFooterLinks?.support && <ListItem fontSize={{ base: "12px", lg: "12px" }}>
            <a
              href={rightFooterLinks?.support}
              style={{
                color: "#5f6368",
              }}
              target="_blank" rel="noreferrer"
            >
              {t('Support')}
            </a>
          </ListItem>}
          {rightFooterLinks?.feedback && <ListItem fontSize={{ base: "12px", lg: "12px" }}>
            <a
              href={rightFooterLinks?.feedback}
              style={{
                color: "#5f6368",
              }}
              target="_blank" rel="noreferrer"
            >
              {t('Feedback')}
            </a>
          </ListItem>}
        </List>
      </Flex>
      <style jsx>{`
      @media (max-width: 820px){
        .linkks li a{
          white-space: nowrap;
          display: block;
        }
      }
    `}</style>
    </div >
  );
}
