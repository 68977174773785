import React, { useContext } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  Spinner,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
// Assets

// Custom components
import CenteredAuth from "layouts/auth/types/CenteredNew";
import { userPublicRequest } from "config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "contexts/AuthContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import Footer from "components/footer/FooterAdmin.js";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight2";
import logoImage from "assets/img/logo.png";
import CustomNavLink from "langHoc/CustomNavLink";
const lang = window.location.pathname.split("/")[1] || "en";
function SignIn() {
  const { dispatch, profileFetch } = useContext(AuthContext);
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const handleClick = () => setShow(!show);
  const history = useCustomHistory();
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    // // console.log("formData:", formData);
    setLoading(true);

    userPublicRequest
      .post("/auth/reset-password/request", {...formData,lang})
      .then((res) => {
        // let payload = res.data.results;

        history.push(`/auth/reset?email=${formData.email}`);
      })
      .catch((error) => {
        // // console.log(error.response.data);
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const changeForm = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const vh = useViewportHeight();
  const breakpoint = useBreakpointValue({ base: "base", lg: "lg" });

  const minH = {
    base: `calc(${vh}px - 40px)`,
    lg: `calc(${vh}px - 50px)`,
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: "10px", lg: "0px" }}
      pb={{ base: "0", lg: "0px" }}
    >
      <Box
        // minH={
        //   { base: "calc(100vh - 120px)", lg: "calc(100vh - 50px)" }
        //   }
        minH={minH[breakpoint]}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        py="50px"
        flexDirection="column"
      >
        <CustomNavLink to="/">
          <Image
            src={logoImage}
            alt="logo"
            width={{ base: "300px", lg: "300px" }}
            marginBottom="40px"
            marginX="auto"
          />
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p={{ base: "20px", md: "20px" }}
            pt={{ base: "20px", md: "20px" }}
          >

            <Box>
              <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                justifyContent="center"
                px={{ base: "0", md: "0px" }}
                flexDirection="column"
              >
                <Box me="auto" mb="20px">
                  <Heading
                    color={textColor}
                    fontSize={{ base: "30px", md: "30px" }}
                    mb="20px"
                    lineHeight={1}
                  >
                    {t("ForgotPassword")}
                  </Heading>
                  <Text
                    color={textColorSecondary}
                    fontSize="md"
                    w={{ base: "100%", lg: "456px" }}
                    maxW="100%"
                  >
                    {t("forgetPasswordContent")}
                  </Text>
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", lg: "100%" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="15px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0", md: "auto" }}
                  align="start"
                >
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      width: "100%",
                    }}
                  >
                    <FormControl>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="20px"
                        lineHeight={1}
                      >
                        {t("EmailAddress")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        type="email"
                        placeholder="email@example.com"
                        mb="20px"
                        size="lg"
                        name="email"
                        onChange={changeForm}
                        required
                      />
                      <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        // mb="24px"
                        type="submit"
                        bg="rgb(0,121,107)"
                        _hover={{
                          bg: "#00877a",
                        }}
                        _active={{
                          bg: "#00877a",
                        }}
                        _focus={{
                          bg: "#00877a",
                        }}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("EmailPasswordResetOTP")
                        )}
                      </Button>
                    </FormControl>
                  </form>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>

      <Footer />
    </Flex>
  );
}

export default SignIn;
