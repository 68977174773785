// Chakra imports
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { userPrivateRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAws, FaStar } from "react-icons/fa";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

export default function StakingReport({ data }) {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [rewards, setRewards] = useState([]);
  const [stakeInformation, setStakeInformation] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [active, setActive] = useState("");
  const [buttonColor, setButtonColor] = useState("rgb(0,121,107)");
  const [buttonHoverColor, setButtonHoverColor] = useState("#00877a");

  let configData = useContext(ConfigContext);
  const config = configData?.config;
  const authData = useContext(AuthContext);
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("rgb(0,121,107)");
      setButtonHoverColor("#00877a");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }

    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#0060ff");
    }
  }, []);
  const { setModal } = authData;

  const onClose = () => {
    setActive("");
  };

  const handleUnstakeClick = () => {
    // onOpen();
  };
  let creditLabel =
    config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ?? "CHI";
  const SI = config?.global?.STAKING_INFORMATION?.value;
  const rewardHistory = isKor ? SI?.rewardHistoryKr : SI?.rewardHistory;
  const staked = isKor ? SI?.stakedKr : SI?.staked;
  const daysRemaining = isKor ? SI?.daysRemainingKr : SI?.daysRemaining;
  const withdraw = isKor ? SI?.unstakeKr : SI?.unstake;

  const fetchRewardHistories = async () => {
    const result = await userPrivateRequest
      .get("/staking/rewards?limit=-1")
      .then((response) => {
        setRewards(response?.data?.data?.docs ?? []);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const fetchStakeInformation = async () => {
    const result = await userPrivateRequest
      .get("/staking/stakingOverview")
      .then((response) => {
        setStakeInformation(response?.data?.data ?? {});
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    fetchRewardHistories();
    fetchStakeInformation();
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      <Card mt={"20px"} >
        <Text
          fontSize="xl"
          color={textColorPrimary}
          mb="20px"
          ms="10px"
          fontWeight="bold"
        >
          {rewardHistory}
        </Text>
        <>
          <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={"#1b2559"}
            fontWeight='bold'
            _hover={{ cursor: "pointer" }}>
            System Wallet

          </FormLabel>
          <InputGroup size="md" mb="25px">

            <Input
              pr="4.5rem"
              pt="20px"
              pb="20px"
              type="text"
              fontWeight='500'
              variant='main'
              placeholder="Your Wallet Address"
              value={data.walletAddress}
              isReadOnly
              _readOnly={{
                borderColor: '#E0E5F2',
                borderRadius: "xl",
              }}

            />

          </InputGroup>
          <Flex justifyContent="space-between" mb="25px" p="4px">
            <Flex direction="column">
              <FormLabel
                display='flex'
                fontSize='md'
                color={"#1b2559"}
                fontWeight='bold'
                _hover={{ cursor: "pointer" }}

              >
                Deposited
              </FormLabel>
              <FormLabel
                display='flex'
                fontSize='lg'
                color={"#1b2559"}
                fontWeight='bold'
                _hover={{ cursor: "pointer" }}


              >
                {Number(stakeInformation?.totalStaked ?? 0)}{" "}
                {process.env.REACT_APP_PLATHFORM === "Questron" ? "ACE" : "DAPPX"}
              </FormLabel>
            </Flex>


            <Flex direction="column">
              <FormLabel
                display='flex'
                fontSize='md'
                color={"#1b2559"}
                fontWeight='bold'
                _hover={{ cursor: "pointer" }}
              >
                {daysRemaining}
              </FormLabel>
              <FormLabel
                display='flex'
                fontSize='lg'
                color={"#1b2559"}
                fontWeight='bold'
                _hover={{ cursor: "pointer" }}

              >
                {Number(stakeInformation?.unStakingAvailableInDays)}
              </FormLabel>
            </Flex>
            <Flex
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                variant="brand"
                w="183px"
                fontSize="sm"
                fontWeight="500"
                onClick={() => {
                  handleUnstakeClick();
                }}
                bg={"#121212"}
                _hover={{
                  bg: Number(stakeInformation?.unStakingAvailableInDays ?? 0) > 0 ? "#ececec" : "#303030",
                }}
                _active={{
                  bg: Number(stakeInformation?.unStakingAvailableInDays ?? 0) > 0 ? "#ececec" : "#303030",
                }}
                _disabled={{
                  bg: '#ececec',
                  cursor: 'not-allowed'
                }}
                disabled={
                  Number(stakeInformation?.unStakingAvailableInDays ?? 0) > 0
                }
              >
                {submitLoading ? <Spinner /> : withdraw}
              </Button>

            </Flex>
          </Flex>
          {/* Table for Staking History */}
          <Flex direction="column" mb="25px">
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="center" style={{ textTransform: 'none' }} fontSize='md'>{capitalizeFirstLetter(t("Date"))}</Th>
                    <Th textAlign="center" style={{ textTransform: 'none' }} fontSize='md'>{capitalizeFirstLetter(t("Type"))}</Th>
                    <Th textAlign="center" style={{ textTransform: 'none' }} fontSize='md'>{capitalizeFirstLetter(t("Currency"))}</Th>
                    <Th textAlign="center" style={{ textTransform: 'none' }} fontSize='md'>{capitalizeFirstLetter(t("Amount"))}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {rewards?.map((reward, index) => (
                    <Tr key={index}>
                      <Td textAlign="center" style={{ fontSize: 'small' }}>
                        {moment(reward.createdAt).format("MMMM D, YYYY")}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: 'small' }}>
                        {reward?.source === "proSubscription" ? "Deposit" : "Airdrop"}
                      </Td>
                      <Td textAlign="center" style={{ fontSize: 'small' }}>CHI</Td>
                      <Td textAlign="center" style={{ fontSize: 'small' }}>
                        {Number(reward?.amount).toLocaleString()}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </>
      </Card >
    </>
  );
}
