import { userPublicRequest } from "config/axios.config";
import { createContext, useEffect, useReducer, useState } from "react";
import ConfigReducer from "./reducers/ConfigReducer";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

import logoImage from "assets/img/logo-a.png";
import uLogoImage from "assets/img/u-logo-a.png";
import dLogoImage from "assets/img/dappx-logo-2.png";
import useCustomHistory from "langHoc/useCustomHistory";
export const ConfigContext = createContext();

const initialState = {};

const ConfigProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(ConfigReducer, initialState);
  const { i18n } = useTranslation();
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [logo, setLogo] = useState(logoImage);
  const [appName, setAppName] = useState(logoImage);

  // const history = useCustomHistory()
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setLogo(logoImage);
      setAppName(process.env.REACT_APP_PLATHFORM);
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setLogo(uLogoImage);
      setAppName(process.env.REACT_APP_PLATHFORM);
    }
    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setLogo(dLogoImage);
      setAppName("Dappx AI");
      // history.push(isKor ? '/ko/chat' : '/en/chat')
    }
  }, []);
  const fetchConfig = async () => {
    try {
      const result = await userPublicRequest.get("/config/fetch");

      if (result?.data?.data?.global?.MAINTENANCE_MODE?.value === "YES") {
        history.push(isKor ? "/ko/maintenance" : "/en/maintenance");
        return;
      }

      let currentLanguage = window.location.pathname.split("/")[1] || "en";

      let values = result.data.data || {};
      let translationLanguage =
        values.global.TRANSLATE_CONFIG?.value[currentLanguage]
          ?.defaultLanguage || currentLanguage;

      dispatch({ ...values, translationLanguage });
      setConfigLoading(false);
    } catch (err) {
      console.log(err, 595959);
      setConfigLoading(false);
      // console.log(err);
      dispatch({});
    }
  };

  const setTranslationLanguage = (lang = "en") => {
    dispatch({ ...state, translationLanguage: lang });
  };

  const fetchPromptModule = async () => {
    try {
      setLoading(true);
      let queryString = "&toShortcut=true";
      if (isKor) {
        queryString = "&toShortcutKo=true";
      }
      let page = 1;
      const offSet = 100;
      // if (sort) {
      //   queryString += `&sortBy=createdAt&sortOrder=${sort?.value}`;
      // }
      // if (query) {
      //   queryString += `&query=${query}`;
      // }
      const results = await userPublicRequest.get(
        `/get-prompts?page=${page}&limit=${offSet}&chatpage=true&source=admin${queryString}`
      );

      setPrompts(results?.data?.data?.docs);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
    fetchPromptModule();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        config: state,
        dispatch,
        setTranslationLanguage,
        loading,
        prompts,
        configLoading,
        logo,
        appName,
      }}
    >
      {!configLoading && children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
