import { userPublicRequest } from "config/axios.config";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import Pusher from "pusher-js";
import { AuthContext } from "./AuthContext";
import { ConfigContext } from "./ConfigContext";
import axios from "axios";
import { v4 } from "uuid";
import { Buffer } from "buffer";
import { indexBy, uniq, where } from "underscore";
import { useTranslation } from "react-i18next";
import { SessionListContext } from "contexts/SessionListContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { userPrivateRequest } from "config/axios.config";
export const PagiContext = createContext();
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const PagiProvider = ({ children }) => {
  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const [modules, setModules] = useState([]);
  const [modulesResponse, setModulesResponse] = useState([]);
  const [agiRef, setAgiRef] = useState(null);
  const [modal, setModal] = useState(false);
  const pagiRef = useRef(null);
  const pendingQueue = useRef([]);
  const currentTaskIndex = useRef(0);
  const { i18n } = useTranslation();
  const sessionListProvider = useContext(SessionListContext);
  const { selectedPrompt, pagi, currentSessionInfo, setCurrentSessionInfo } =
    sessionListProvider;

  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  // console.log("membership", membership);

  const fetchModules = async () => {
    let queryString = "";
    if ((membership === "free" || membership === "guest") && !isKor) {
      queryString = "&toFree=true";
    }
    if ((membership === "free" || membership === "guest") && isKor) {
      queryString = "&toFreeKo=true";
    }
    if (membership === "pro" && !isKor) {
      queryString = "&toPro=true";
    }
    if (membership === "pro" && isKor) {
      queryString = "&toProKo=true";
    }

    const results = await userPublicRequest.get(
      `/get-prompts?limit=-1&source=admin${queryString}`
    );

    setModules(results?.data?.data?.docs);
  };

  const handle__pagi__call = async (query) => {
    currentTaskIndex.current = 0;
    // let initialPrompt = config.global?.PAGI_CONFIGURATION?.value?.prompt || "";
    let initialPrompt = pagi?.initialPrompt || "";
    let structuredModules = modules.map((i) => {
      if (i?.name && i.description && i.isPagi == "yes") {
        return {
          id: i._id,
          name: i.name,
          purpose: i?.description,
          // platform: i.sourceBadge ? i.sourceBadge : "search",
        };
      }
      return null;
    });
    structuredModules = structuredModules.filter((i) => {
      return i;
    });
    let prompt = [
      {
        role: "system",
        content: initialPrompt
          .replaceAll("[AVAILABLE_MODULES]", JSON.stringify(structuredModules))
          .replaceAll("[QUERY]", ""),
      },
      {
        role: "user",
        content: query,
      },
    ];

    let model = config[membership]?.model;
    let temperature = config[membership]?.aiTemperature;
    let top_p = config[membership]?.topP;
    let max_tokens = config[membership]?.maxToken;
    let frequency_penalty = config[membership]?.frequencyPenalty;
    let presence_penalty = config[membership]?.presencePenalty;

    if (pagi) {
      if (pagi?.model) {
        model = pagi?.model;
      }
      if (pagi?.aiTemperature !== undefined) {
        temperature = pagi?.aiTemperature;
      }
      if (pagi?.topP) {
        top_p = pagi?.topP;
      }
      if (pagi?.maxToken) {
        max_tokens = pagi?.maxToken;
      }
      if (pagi?.frequencyPenalty !== undefined) {
        frequency_penalty = pagi.frequencyPenalty;
      }
      if (pagi?.presencePenalty !== undefined) {
        presence_penalty = pagi.presencePenalty;
      }
    }

    let data = {
      model,
      messages: prompt,
      temperature,
      top_p,
      max_tokens,
      n: 1,
      frequency_penalty,
      presence_penalty,
    };

    let derivedModules = await userPrivateRequest
        .post(
          "openai/completion", data)
      .then((response) => {
        let returnText = response.data.choices[0]?.message?.content ?? "[]";
        let firstBracket = returnText.indexOf("[");
        let lastBracket = returnText.lastIndexOf("]");

        if (firstBracket === -1 || lastBracket === -1) {
          // // console.log("No brackets found in the returnTexting");
        } else {
          returnText = returnText.substring(firstBracket, lastBracket + 1);
          // // console.log(returnText);
        }

        let modules = JSON.parse(returnText);
        // // console.log("modules: ", modules);
        return modules;
      })
      .catch((error) => {
        console.error(error.message);
        return [];
      });

    derivedModules = derivedModules.map((i) => {
      return { ...i, status: "pending" };
    });
    setModal((currentModalValue) => {
      // // console.log(currentModalValue, 'current modal modal value inside handle__pagi__call');

      if (!currentModalValue) {
        derivedModules = [];
      }
      // If you want to update the modal value based on the current value, you can do so here:
      // For example, to toggle the modal value:
      // return !currentModalValue;

      // If you don't want to change the modal value, just return the current value:
      return currentModalValue;
    });

    // console.log(derivedModules, "derivedModules");
    // setModulesResponse(derivedModules);

    let id = v4();
    setAgiRef(id);
    pagiRef.current = id;

    const selectedModuleInstance = await userPublicRequest
      .get(
        `/get-prompts?limit=-1&source=admin&ids=${derivedModules
          .map((i) => {
            return i.id;
          })
          .join(",")}`
      )
      .then((response) => {
        return response?.data?.data?.docs ?? [];
      })
      .catch((error) => {
        console.error(error.message);
        return [];
      });
    let indexedModules = indexBy(selectedModuleInstance, "_id");
    let tasks = derivedModules.map((task) => {
      let promptInfo = indexedModules[task.id];
      return { ...task, moduleInfo: promptInfo };
    });
    setModulesResponse(tasks);
    pendingQueue.current = tasks;
    return;
  };

  useEffect(() => {
    if (pendingQueue.current.length > currentTaskIndex.current) {
      let executable = pendingQueue.current[currentTaskIndex.current];

      currentTaskIndex.current += 1;

      let promptInfo = executable?.moduleInfo;
      if (promptInfo?.promptType == "apps" && promptInfo?.redirectionUrl) {
        window.open(
          `${promptInfo?.redirectionUrl}/${i18n.language}?prompt=${executable.prompt}`
        );
        let foundConfirmed = false;
        let temp = modulesResponse.map((task, i) => {
          if (task.id === executable.id) {
            foundConfirmed = true;
            return { ...task, status: "confirmed" };
          } else if (!foundConfirmed) {
            return { ...task, status: "confirmed" };
          }
          return task;
        });

        setModulesResponse(temp);
      } else {
        delete executable.moduleInfo;
        let taskData = JSON.stringify({
          id: pagiRef.current,
          task: executable,
        });
        // let base64Data = Buffer.from(JSON.stringify(taskData)).toString(
        //   "base64"
        // );
        setTimeout(() => {
          window.open("/en/chat?agiExecution=" + taskData);
        }, 3000);
      }
    }
  }, [modulesResponse]);

  useEffect(() => {
    if (agiRef && modulesResponse.length > 0) {
      let listeningEvent = `PagiUpdate-${agiRef}`;
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      var channel = pusher.subscribe(agiRef);
      channel.bind(listeningEvent, function (data) {
        let temp = modulesResponse.map((iteration, i) => {
          if (iteration.id === data?.data.id) {
            return { ...iteration, status: "confirmed" };
          }
          return iteration;
        });

        setModulesResponse(temp);
      });
      // Cleanup
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [agiRef, modulesResponse]);

  useEffect(() => {
    if (authData.isFetched) {
      fetchModules();
    }
  }, [authData.isFetched]);

  const makeEmpty = () => {
    pendingQueue.current = [];
    currentTaskIndex.current = 0;
  };

  return (
    <PagiContext.Provider
      value={{
        socket: null,
        handle__pagi__call,
        modules: modulesResponse,
        setModulesResponse,
        modal,
        setModal,
        agiRef,
        setAgiRef,
        makeEmpty,
      }}
    >
      {children}
    </PagiContext.Provider>
  );
};

export default PagiProvider;
